import React, { useState } from "react";
import WorkspaceDetail from "features/workspace/WorkspaceDetail";
import styles from "./Workspace.module.scss";
// import { Button } from "react-bootstrap";
// import { MESSAGE_NO_WORKSPACES } from "services/constants";
// import { HambugurMenuContext } from "services/contexts";

const NoWorkspace = () => {
  // const onItemSelected = useContext(HambugurMenuContext);
  const [show, setShow] = useState(false);

  return (
    <>
      {/*<div className={styles.workspaceHeader}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center"></div>
          <div className="d-flex align-items-center">
            <Button
              variant="primary"
              className="mx-4"
              onClick={() => onItemSelected("New Workspace")}
            >
              New Workspace
            </Button>
          </div>
        </div>
      </div>{" "}
      */}
      <div className="container content-wrapper">
        <div className="card">
          <div className="mx-2 py-4 card-body text-center">
            Welcome! Please{" "}
            <span
              onClick={() => {
                setShow(true);
                document.body.classList.remove("overflow-auto");
                document.body.classList.add("overflow-hidden");
              }}
              className={styles.openNewWorkspace}
            >
              click here
            </span>{" "}
            to add your first Workspace
            {/* {MESSAGE_NO_WORKSPACES} */}
          </div>
        </div>
      </div>
      {show && <WorkspaceDetail onClose={() => setShow(false)} id={null} />}
    </>
  );
};

export default NoWorkspace;
