import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const DotsSpinner = () => {
  return(
    <div className="d-flex justify-content-center">
      <Spinner animation="grow" variant="dark" size="sm" className="me-2" />
      <Spinner animation="grow" variant="dark" size="sm" className="me-2" />
      <Spinner animation="grow" variant="dark" size="sm" />
    </div>
  )
}

export default DotsSpinner;