import React from "react";

const LoadingTimeline = () => {
  return (
    <div>
      <div className="animated-background mx-2 my-3"></div>
      <div className="animated-background mx-2 my-3"></div>
      <div className="animated-background mx-2 my-3"></div>
    </div>
  );
};

export default LoadingTimeline;
