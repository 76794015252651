import React, {  } from 'react';
import PropTypes from "prop-types";
import { Dropdown } from 'react-bootstrap';

const TaskStatusDropdown = ({ taskClosed, isDropMenu, onChangeTaskStatus }) => {

  return (
    <Dropdown
      align={{ lg: 'start' }}
    >
      <Dropdown.Toggle variant="light" name="status" className="border border-secondary item-menu-btn" style={{padding:0, margin: 0, lineHeight: '1em'}}>
        <input 
          type="checkbox"
          name="status"
          checked={taskClosed ==="1"}
          onChange={()=>{}}
        />
      </Dropdown.Toggle>
      {isDropMenu
      && <Dropdown.Menu>
        <Dropdown.Item 
          as="button"
          name="status"
          onClick={onChangeTaskStatus}
        >
          {taskClosed ==="1" ? 'Mark as not complete' : 'Mark as complete'}
        </Dropdown.Item>
      </Dropdown.Menu>}
    </Dropdown>
  )
}
TaskStatusDropdown.propTypes = {
  taskClosed: PropTypes.string,
  onChangeTaskStatus: PropTypes.func,
  isDropMenu: PropTypes.bool
};
export default TaskStatusDropdown;