import React from "react";

const LoadingParticipants = () => {
  return (
    <div className="d-flex flex-column mx-2" style={{width:'330px'}} >
      <div className="animated-background" style={{ width: "100%" }}></div>
      <div className="d-flex mt-2">
        <div
          className="animated-background me-2"
          style={{ width: "50%" }}
        ></div>
        <div
          className="animated-background ms-2"
          style={{ width: "50%" }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingParticipants;
