import React from "react";
import PropTypes from "prop-types";

const Linkify = ({children})=> {
  const isUrl = word => {
    // eslint-disable-next-line no-useless-escape
    const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return word.match(urlPattern)
  }

  const addMarkup = word => {
    return isUrl(word) ? 
      `<a href="${word}" target="_blank"  rel="noreferrer">${word}</a>`:  
      word
  }

  const words = children.split(' ')
  const formatedWords = words.map((w) => addMarkup(w))
  const html = formatedWords.join(' ')
  return (<span dangerouslySetInnerHTML={{__html: html}} />)
}

Linkify.propTypes = {
  children: PropTypes.node
};
export default Linkify