import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { HambugurMenuContext } from "services/contexts";
import { HiDotsHorizontal } from "react-icons/hi";
import useMobileWindow from "hooks/useMobileWindow";
import { BsThreeDotsVertical } from "react-icons/bs";

const HambugerDropdown = ({
  dropdownAlign,
  menuItems,
  itemId,
  parentId,
  isExistedElements,
  onAction,
}) => {
  const onItemSelected = useContext(HambugurMenuContext);
  const isMobile = useMobileWindow();

  return (
    <Dropdown align={dropdownAlign ? dropdownAlign : "start"}>
      {isMobile ? (
        <Dropdown.Toggle
        variant="light"
        className="p-0"
        >
          <BsThreeDotsVertical />
        </Dropdown.Toggle>
      ) : (
        <Dropdown.Toggle
          variant="light"
          className="item-menu-btn"
        >
          <HiDotsHorizontal style={{ fontSize: "20px", backgroundColor:'transparent', border:'none' }} />
        </Dropdown.Toggle>
      )}
      <Dropdown.Menu>
        {menuItems.map((item, i) => (
          <Dropdown.Item
            key={i}
            as="button"
            onClick={() => {
              onItemSelected(
                item.label,
                itemId,
                parentId,
                isExistedElements,
                onAction
              );
            }}
            className={`${item.label.includes("Delete") ? "text-danger" : ""}`}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
HambugerDropdown.propTypes = {
  dropdownAlign: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  itemId: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  isExistedElements: PropTypes.bool,
  onAction: PropTypes.func,
  onClick: PropTypes.func,
};
export default HambugerDropdown;
