import axios from './axios';

export const getProject = (projectId, completed=null) => {

  const params = new URLSearchParams();
  params.append("project_id", projectId);
  if (completed !== null && completed !== undefined) {
    params.append("completed", completed);
  }

  return axios.get('app/get_project', {
    params
  })
};

export const addProject = payload => axios.post('app/add_project', payload);

export const editProject = payload => axios.patch('app/edit_project', payload);

export const deleteProject = payload => axios.delete('app/delete_project', { data: payload });

