import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskTags: [],
  isShowTaskDetail: false,
  isShowSubTaskDetail: false,
  openTaskId: null,
  openSubTaskId: null,
  openTaskLoading:false
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    fetchTaskTags(state, action) {
      state.taskTags = action.payload.taskTags;
    },
    dispatchIsShowTaskDetail(state, action) {
      state.isShowTaskDetail = action.payload;
    },
    dispatchOpenTaskId(state, action) {
      state.openTaskId = action.payload;
    },
    dispatchIsShowSubTaskDetail(state, action) {
      state.isShowSubTaskDetail = action.payload;
    },
    dispatchOpenSubTaskId(state, action) {
      state.openSubTaskId = action.payload;
    },
    dispatcOpenTaskLoading(state, action) {
      state.openTaskLoading = action.payload;
    },
  },
});

export const {
  fetchTaskTags,
  dispatchIsShowTaskDetail,
  dispatchOpenTaskId,
  dispatchIsShowSubTaskDetail,
  dispatchOpenSubTaskId,
  dispatcOpenTaskLoading
} = taskSlice.actions;

export default taskSlice.reducer;
