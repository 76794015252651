import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
  isAllReports: false,
  isUpdateAllReports: true
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setViewReports(state, action) {
      state.reports = action.payload.reports;
    },
    setIsAllReports(state, action) {
      state.isAllReports = action.payload;
    },
    setIsUpdateAllReports(state, action){
      state.isUpdateAllReports = action.payload
    }
  },
});

export const { setViewReports, setIsAllReports, setIsUpdateAllReports } = reportsSlice.actions;

export default reportsSlice.reducer;
