import React, { useState, useEffect } from "react";
import styles from "./Fields.module.scss";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PlusIconButton, XIconButton } from "./IconButton";
import { setError } from "redux/workspaceSlice";
import LoadingFields from "./LoadingFields";
import { getFields, addField, editField, deleteField } from "API/fields";
import { isJSONRegExp, deepEqualObj } from "services/helpers";
import MultiSelect from "components/MultiSelect";

const FieldsSettings = ({ disabled, workspaceId, projectId }) => {
  const dispatch = useDispatch();
  const types = useSelector((state) => state.appsetting.field_types);
  const visibleTo = useSelector((state) => state.appsetting.field_visible_to);
  const editors = useSelector((state) => state.appsetting.field_editors);

  const typesOptions = types.map((type, i) => (
    <option key={i} value={type}>
      {type}
    </option>
  ));

  const initFields = {
    _id: null,
    name: "",
    type: "",
    visible_to: [],
    editors: [],
  };
  const initObjData = [
    {
      name: "",
      type: "",
      visible_to: [],
      editors: [],
    },
  ];
  const [fields, setFields] = useState([initFields]);
  const [loading, setLoading] = useState(false);
  const [formerrors, setFormErrors] = useState({});
  const [afterAddFields, setAfterAddFields] = useState([]);
  const [initFieldsData, setInitFieldsData] = useState(initObjData);

  useEffect(() => {
    if (workspaceId || projectId) {
      setInitData();
    }
  }, [workspaceId, projectId]);

  // Update data if the save was performed by clicking on the PlusIcon - OnNewClicked().
  // In this case, we cannot update the data correctly only with onBlur()
  useEffect(() => {
    if (afterAddFields.length !== 0) {
      const updatedFields = fields.map((field, index) => {
        if (field._id === null) {
          if (index < afterAddFields.length) {
            return {
              ...field,
              _id: afterAddFields[index]._id,
            };
          } else {
            return field;
          }
        } else {
          return field;
        }
      });
      setFields(updatedFields);
      setAfterAddFields([]);
    }
  }, [afterAddFields]);

  const setInitData = async () => {
    try {
      setLoading(true);
      const response = await getFields(workspaceId, projectId);
      // console.log(response, 'response-getFields');
      if (response.data.success === 1) {
        const fieldList = response.data.list.reverse();
        if (fieldList.length > 0) {
          let initFieldsList = fieldList.map((field) => {
            return {
              _id: field._id,
              name: field.name,
              type: field.type,
              visible_to: isJSONRegExp(field.visible_to)
                ? JSON.parse(field.visible_to)
                : [field.visible_to],
              editors: isJSONRegExp(field.editors)
                ? JSON.parse(field.editors)
                : [field.editors],
            };
          });
          setFields(initFieldsList);
          const initData = initFieldsList.map((item) => {
            return {
              name: item.name,
              type: item.type,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          setInitFieldsData(initData);
        }
      } else if (response?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
      setLoading(false);
    } catch (err) {
      if (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.toString(),
          })
        );
        setLoading(false);
      }
    }
  };

  const onNewClicked = () => {
    setFields([...fields, initFields]);
  };

  const onNameChanged = (e, index) => {
    const newFields = fields.map((field, i) => {
      if (i === index)
        return Object.assign({}, field, { name: e.target.value });
      else return field;
    });
    setFields(newFields);
  };

  const onTypeChanged = (e, index) => {
    const newFields = fields.map((field, i) => {
      if (i === index)
        return Object.assign({}, field, { type: e.target.value });
      else return field;
    });
    setFields(newFields);
  };

  const onVisibleToChanged = (data, index) => {
    const valuesArray = data.map((el) => el.value);
    const newFields = fields.map((field, i) => {
      if (i === index)
        return Object.assign({}, field, { visible_to: valuesArray });
      else return field;
    });
    setFields(newFields);
  };

  const onEditorsChanged = (data, index) => {
    const valuesArray = data.map((el) => el.value);
    const newFields = fields.map((field, i) => {
      if (i === index)
        return Object.assign({}, field, { editors: valuesArray });
      else return field;
    });
    setFields(newFields);
  };

  const onBlur = async (index) => {
    const field = fields.find((f, i) => i === index);
    console.log(field, "field in the Add-Edit");
    validate();

    if (
      !field.name ||
      !field.type ||
      !field.visible_to.length ||
      !field.editors.length
    )
      return;

    const formData = {
      name: field.name,
      type: field.type,
      visible_to: JSON.stringify(field.visible_to),
      editors: JSON.stringify(field.editors),
      workspace_id: workspaceId === undefined ? "" : workspaceId,
      project_id: projectId === undefined ? "" : projectId,
    };
    const initFieldObj = initFieldsData.find((p, i) => i === index);
    const editedFormObj = {
      name: field.name,
      type: field.type,
      visible_to: field.visible_to,
      editors: field.editors,
    };

    const isFormDataChanged = field._id
      ? !deepEqualObj(initFieldObj, editedFormObj)
      : null;
    try {
      const response =
        field._id === null
          ? await addField(formData)
          : isFormDataChanged
          ? await editField({ ...formData, field_id: field._id })
          : null;

      if (response && response?.data?.success === 1) {
        if (field._id === null) {
          const newFields = fields.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                _id: response?.data?.new_field_id.toString(),
              };
            }
            return item;
          });
          setAfterAddFields(newFields);
          const initData = newFields.map((item) => {
            return {
              name: item.name,
              type: item.type,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          setInitFieldsData(initData);
        } else {
          console.log(fields, "edited-onBlur");
          setAfterAddFields(fields);
          const initData = fields.map((item) => {
            return {
              name: item.name,
              type: item.type,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          console.log(initData, "initData in onBlur-edited");
          setInitFieldsData(initData);
        }
      } else if (response?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const validate = () => {
    let errors = [];
    fields.forEach((field) => {
      let error = {
        name: "",
        type: "",
        visible_to: "",
        editors: "",
      };

      if (!field.name) {
        error.name = "This field is required";
      }

      if (!field.type) {
        error.type = "This field is required";
      }

      if (!field.visible_to.length) {
        error.visible_to = "This field is required";
      }

      if (!field.editors.length) {
        error.editors = "This field is required";
      }
      errors.push(error);
    });
    // console.log(errors, "errors in Fields");
    setFormErrors(errors);
  };

  const onDeleteClicked = async (field_index) => {
    const targetField = fields.find((field, i) => i === field_index);
    const targetId = targetField._id;
    if (targetId !== null) {
      // formData.append("api_method", "delete_field");
      // formData.append("field_id", targetId);
      try {
        const response = await deleteField({ field_id: targetId });
        if (response.data.success === 1) {
          console.log(response, "deleted-Field");
          // const newFields = fields.filter((field, i) => i !== field_index);
          const newFields = fields.filter((field) => field._id !== targetId);
          console.log(newFields, "newFields");
          if (newFields.length !== 0) {
            setFields(newFields);
            const initData = newFields.map((item) => {
              return {
                name: item.name,
                type: item.type,
                visible_to: item.visible_to,
                editors: item.editors,
              };
            });
            setInitFieldsData(initData);
          } else {
            setFields([initFields]);
            setInitFieldsData(initObjData);
          }
        } else if (response.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: response.data.error,
            })
          );
        }
      } catch (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.toString(),
          })
        );
      }
    } else if (fields.length > 1) {
      const newFields = fields.filter((field, i) => i !== field_index);
      setFields(newFields);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingFields />
      ) : (
        <>
          {fields?.map((field, i) => (
            <div key={i} className={`mb-2 ${styles.fieldWrapper}`}>
              <div className="d-flex align-items-end w-100">
                {i === 0 ? (
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      marginRight: "8px",
                    }}
                    className=""
                  >
                    <PlusIconButton
                      onClick={onNewClicked}
                      disabled={disabled}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "38px",
                      height: "36px",
                      marginRight: "6px",
                    }}
                  ></div>
                )}
                <div className={styles.nameTypeBlock}>
                  <div
                    className="form-field w-50"
                    style={{ marginRight: "4px" }}
                  >
                    {i === 0 && <Form.Label>Field Name</Form.Label>}
                    <Form.Control
                      className={
                        formerrors[i] && formerrors[i].name
                          ? "border-danger"
                          : ""
                      }
                      type="text"
                      disabled={disabled}
                      placeholder="Field name"
                      value={field.name}
                      onChange={(e) => onNameChanged(e, i)}
                      onBlur={() => onBlur(i)}
                    />
                  </div>
                  <div
                    className="d-flex align-items-end w-50"
                    style={{ marginLeft: "4px" }}
                  >
                    <div className={`form-field ${styles.fieldType}`}>
                      {i === 0 && <Form.Label>Type</Form.Label>}
                      <Form.Select
                        className={
                          formerrors[i] && formerrors[i].type
                            ? "me-2 border-danger"
                            : "me-2"
                        }
                        value={field.type}
                        disabled={disabled}
                        onChange={(e) => onTypeChanged(e, i)}
                        onBlur={() => onBlur(i)}
                        required
                      >
                        <option value="" disabled hidden>
                          Type
                        </option>
                        {typesOptions}
                      </Form.Select>
                    </div>
                    <div
                      style={{
                        width: "36px",
                        height: "36px",
                        marginLeft: "8px",
                      }}
                    >
                      <XIconButton
                        onClick={() => onDeleteClicked(i)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-field d-flex justify-content-end w-100 ms-2 mt-2">
                <div
                  style={{
                    width: "calc(50% - 6px)",
                    marginLeft: "36px",
                    marginRight: "5px",
                  }}
                >
                  {i === 0 && (
                    <Form.Label className="ps-2">Visible to</Form.Label>
                  )}
                  <MultiSelect
                    onChange={(data) => onVisibleToChanged(data, i)}
                    options={visibleTo}
                    selected={field.visible_to}
                    placeholder="Visible to"
                    disabled={disabled}
                    onBlur={() => onBlur(i)}
                    required
                    style={{ height: "36px" }}
                    className={
                      formerrors[i] && formerrors[i]?.visible_to
                        ? "border-danger"
                        : ""
                    }
                  />
                </div>
                <div style={{ width: "50%", marginLeft: "4px" }}>
                  {i === 0 && <Form.Label className="ms-4">Editor</Form.Label>}
                  <MultiSelect
                    multiple
                    onChange={(data) => onEditorsChanged(data, i)}
                    options={editors}
                    selected={field.editors}
                    placeholder="Editors"
                    disabled={disabled}
                    onBlur={() => onBlur(i)}
                    required
                    style={{ height: "36px" }}
                    className={
                      formerrors[i] && formerrors[i]?.editors
                        ? "border-danger me-2"
                        : "me-2"
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
FieldsSettings.propTypes = {
  disabled: PropTypes.bool,
  workspaceId: PropTypes.string,
  projectId: PropTypes.string,
};
export default FieldsSettings;
