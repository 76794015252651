import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import authReducer from './authSlice';
import workspaceSlice from './workspaceSlice';
import appsettingSlice from './appsettingSlice';
import projectSlice from './projectSlice';
import reportsSlice from './reportsSlice';
import taskSlice from './taskSlice';

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    auth: authReducer,
    workspaces: workspaceSlice,
    appsetting: appsettingSlice,
    project: projectSlice,
    reports: reportsSlice,
    tasks: taskSlice
  },
});
