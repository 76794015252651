import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import styles from "features/members/styles.module.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { setIsUpdateProject } from "redux/projectSlice";
import {
  dispatchIsShowTaskDetail,
  dispatchOpenTaskId,
  dispatchIsShowSubTaskDetail,
  dispatchOpenSubTaskId,
  dispatcOpenTaskLoading,
} from "redux/taskSlice";

const ActionerTodayBlock = ({ actionerTodayList }) => {
  const error = useSelector((state) => state.workspaces.error);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { onUpdateSettingData, settingsUploaded } = useUpdateSettingData();
  const { fetchWorkspaceData } = useGetWorkspaceData();
  const [taskId, setTaskId] = useState(null);
  const [subTaskId, setSubTaskId] = useState(null);

  useEffect(() => {
    if (settingsUploaded) {
      //this error is received from app/settings
      if (error?.message !== "No project found for that project_id.") {
        navigate("/");
        dispatch(setIsUpdateProject(true));
        if (taskId) {
          dispatch(dispatchOpenTaskId(taskId));
          dispatch(dispatchOpenSubTaskId(subTaskId));
          subTaskId
            ? dispatch(dispatchIsShowSubTaskDetail(true))
            : dispatch(dispatchIsShowTaskDetail(true));
            setTaskId(null);
        }
      } else {
        dispatch(dispatcOpenTaskLoading(false));
      }
    }
  }, [settingsUploaded, taskId, subTaskId]);

  return (
    <>
      <div className="project-card card actionerTodayBlock">
        <div className="d-flex project-card-header">
          <div className="project-card-title" style={{ marginLeft: "14px" }}>
            Actioner
          </div>
          <div className="project-card-title" style={{ marginLeft: "150px" }}>
            Today's timeline
          </div>
        </div>
        {actionerTodayList.length > 0 &&
          actionerTodayList.map((item) => (
            <div className={styles.memberTasks} key={item.actioner.account_id}>
              <div className="d-flex justify-content-between">
                <div className="project-card-body-wrapper">
                  {item?.actioner?.first_name + " " + item?.actioner?.last_name}
                </div>
                <div className="grid-container">
                  {Object.values(item.tasks).map((task) => (
                    <div className="grid-item" key={task._id}>
                      <span
                        className="ellipsis fw-600 hover-grey"
                        title={task.project_name}
                        onClick={async () => {
                          try {
                            await onUpdateSettingData(
                              task.workspaceISbb_t925_workspacesID,
                              task.projectISbb_t925_projectsID
                            );
                            await fetchWorkspaceData(
                              task.workspaceISbb_t925_workspacesID
                            );
                            setTaskId(null);
                            setSubTaskId(null);
                          } catch (error) {
                            console.error(
                              "Error fetching project data:",
                              error
                            );
                          }
                        }}
                      >
                        {task.project_name}
                      </span>
                      <span
                        title={task.name}
                        className="hover-grey"
                        onClick={async () => {
                          try {
                            setTaskId(
                              task.parent_task_id
                                ? task.parent_task_id
                                : task._id
                            );
                            setSubTaskId(task.parent_task_id ? task._id : null);
                            dispatch(dispatcOpenTaskLoading(true));
                            await onUpdateSettingData(
                              task.workspaceISbb_t925_workspacesID,
                              task.projectISbb_t925_projectsID
                            );
                            await fetchWorkspaceData(
                              task.workspaceISbb_t925_workspacesID
                            )
                            setTaskId(
                              task.parent_task_id
                                ? task.parent_task_id
                                : task._id
                            );
                          } catch (error) {
                            console.error(
                              "Error fetching project data:",
                              error
                            );
                          }
                        }}
                      >
                        TK({task._id})
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

ActionerTodayBlock.propTypes = {
  actionerTodayList: PropTypes.array.isRequired,
};
export default ActionerTodayBlock;
