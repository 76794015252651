import { createSlice } from "@reduxjs/toolkit";
import {sortArrayAlphabetically} from "services/helpers";

const initialState = {
  projectsList:[],
  project: {},
  isUpdateProject: true
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {        
    setProjectsList(state, action) {
      state.projectsList = action.payload ? sortArrayAlphabetically(action.payload) : [];
    }, 
    setProject(state, action) {
      state.project = action.payload
    },
    setIsUpdateProject(state, action){
      state.isUpdateProject = action.payload
    }    
  }
})

export const { setProjectsList, setProject, setIsUpdateProject } = projectSlice.actions

export default projectSlice.reducer