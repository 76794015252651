import axios from './axios';

export const getDocs = (projectId, taskId) => {

  const params = new URLSearchParams();
  if (projectId) params.append("project_id", projectId);
  if (taskId) params.append("task_id", taskId);

  return axios.get('app/get_docs', {
    params
  })
};

export const addDoc = payload => axios.post('app/add_doc', payload);

export const deleteDoc = payload => axios.delete('app/delete_doc', {data: payload});