import React from "react";

const LoadingDocuments = () => {
  return (
    <div className="d-flex flex-column w-100">
      <div className="animated-background w-100"></div>

      <div className="d-flex mt-2">
        <div
          className="animated-background me-2 w-50"
          // style={{ width: "50%" }}
        ></div>
        <div
          className="animated-background ms-2 w-50"
          // style={{ width: "50%" }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingDocuments;
