import React, { useState, useEffect } from "react";
import "./filter-list.css";
import "./Reports.css";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/workspaceSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  getListReportFilter,
  getViewReport,
  deleteReportFilter,
} from "API/reports";
import {
  setViewReports,
  // setIsAllReports,
  // setIsUpdateAllReports,
} from "redux/reportsSlice";
import { FaEdit, FaTrash } from "react-icons/fa";
import ConfirmModal from "components/ConfirmModal";
import useModal from "hooks/useModal";
import Modal from "components/ModalWindow";
import ReportFilterForm from "layouts/Navbar/ReportFilterForm";
import { toast } from "react-toastify";
import LoadingScreen from "layouts/LoadingScreen";

const FiltersList = () => {
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const projectsList = useSelector((state) => state.project.projectsList);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const [isShow, openModal, closeModal] = useModal();

  const [reportFilters, setReportFilters] = useState([]);
  const [reportFilter, setReportFilter] = useState(null);
  const [showDeleteReportFilter, setShowDeleteReportFilter] = useState(false);
  const [deleteReportFilterId, setDeleteReportFilterId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getReportFilters();
  }, []);

  const getReportFilters = async () => {
    // formData.append("api_method", "list_report_filters");
    try {
    setIsLoading(true);
      const response = await getListReportFilter();
      if (response.data.success === 1) {
        setReportFilters(response.data.list);
        console.log(response.data.list, "getReportFilters-list");
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }finally {
      setIsLoading(false);
    }
  }

  const handleReportFilter = async (name) => {
    // formData.append("api_method", "view_report");
    try {
      // const response = await getViewReport({
      //   report_filterISbb_t925_report_filtersID: id,
      // });
      const response = await getViewReport({ name });
      if (response.data.success === 1) {
        console.log(response.data.list, "response.data.list-Viewreports");
        dispatch(setViewReports({ reports: response.data.list }));
        // dispatch(setIsAllReports(false));
        // setActiveReporFiltertId(id);
        navigate("/filtered-tasks");
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const openConfirmDeleteFilter = async (id) => {
    setShowDeleteReportFilter(true);
    setDeleteReportFilterId(id);
  };
  const deleteTaskFilter = async () => {
    // formData.append("api_method", "delete_report_filter");
    // formData.append("report_filter_id", id);

    try {
      const response = await deleteReportFilter({
        report_filter_id: deleteReportFilterId,
      });
      if (response.data.success === 1) {
        toast.success("Report filter was deleted", {
          theme: "colored",
        });
        setReportFilters(response.data.list);
        setShowDeleteReportFilter(false);
        setDeleteReportFilterId("");
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };
  console.log(reportFilters, "reportFilters");
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className="container content-wrapper">
        {reportFilters.length > 0 ? (
          <div className="reports-wrapper card">
            <div className="reports-innerWrapper">
              <div className="report-header">
                <div className="filtersHeaderRow">
                  <div className="filterName fs-14 fw-600">Filter Name</div>
                  <div className="py-2 fs-14 fw-600 filterWorkspace">
                    Workspace
                  </div>
                  <div className="py-2 fs-14 fw-600 filterProject">Project</div>
                  <div className="action-buttons-wrapper"></div>
                </div>
              </div>
              <div className="task-hr" style={{ margin: "10px 0px" }}></div>

              {reportFilters.map((report) => (
                <div key={report._id}>
                  <div
                    className="filtersRow"
                    onClick={() => handleReportFilter(report.name)}
                  >
                    <span className="filterName">{report.name}</span>
                    <span className="filterWorkspace">
                      {
                        workspaces.find(
                          (item) =>
                            item._id === report.workspaceISbb_t925_workspacesID
                        ).name
                      }
                    </span>
                    <span className="filterProject">
                      {
                        projectsList.find(
                          (item) =>
                            item._id === report?.projectISbb_t925_projectsID
                        )?.name
                      }
                    </span>
                    <div className="action-buttons-wrapper">
                      <FaEdit
                        className="editIcon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setReportFilter(report);
                          openModal();
                        }}
                      />
                      <FaTrash
                        className="trashIcon ms-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          openConfirmDeleteFilter(report._id);
                        }}
                      />
                    </div>
                  </div>
                  <div className="task-hr" style={{ margin: "10px 0px" }}></div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="reports-wrapper card">
            <div className="reports-innerWrapper">No filters</div>
          </div>
        )}
      </div>
      <Modal
        isShow={isShow}
        modalHeader="Edit Report Filter"
        handleClose={closeModal}
        headerStyles={{ padding: "32px" }}
        bodyStyles={{
          height: "500px",
          padding: "32px 32px 48px 32px",
          overflow: "auto",
        }}
      >
        <ReportFilterForm
          handleClose={closeModal}
          reportFilter={reportFilter}
        />
      </Modal>
      <ConfirmModal
        isShow={showDeleteReportFilter}
        title={"Delete Report Filter"}
        message={"Are you sure you want to delete this filter?"}
        onDelete={deleteTaskFilter}
        onClose={setShowDeleteReportFilter}
      />
    </>
  );
};

export default FiltersList;
