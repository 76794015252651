import axios from './axios';

export const getTags = (workspaceId, projectId) => {

  const params = new URLSearchParams();
  if (workspaceId) params.append("workspace_id", workspaceId);
  if (projectId) params.append("project_id", projectId);

  return axios.get('app/get_tags', {
    params
  })
};

export const addTag = payload => axios.post('app/add_tag', payload);

export const editTag = payload => axios.patch('app/edit_tag', payload);

export const deleteTag = payload => axios.delete('app/delete_tag', { data: payload });

