import React, { useState } from "react";
import "./Reports.css";
import PropTypes from "prop-types";
import { RiFlag2Fill, RiFlag2Line } from "react-icons/ri";
import TaskStatusDropdown from "components/TaskStatusDropdown";
import { formatDate } from "services/helpers";
import TaskDetail from "features/task/TaskDetail";
import SubTaskDetail from "features/task/SubTaskDetail";
import useGetProjectData from "hooks/useGetProjectData";

const Report = ({ report /*, onSelectReport */ }) => {
  const { fetchProjectData } = useGetProjectData();

  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [showSubTaskDetail, setShowSubTaskDetail] = useState(false);

  const [taskId, setTaskId] = useState(null);
  const [subTaskId, setSubTaskId] = useState(null);

  const [projectId, setProjectId] = useState(null);
  const onChangeReportStatus = async () => {
    // formData.append("api_method", "edit_task");
    // formData.append("task_id", task._id);
    // formData.append("name", task.name);
    // formData.append("task_closedYN", task.task_closedYN === "1" ? 0 : 1);
    // try {
    //   await post(server_domain, formData);
    //   updateData();
    // } catch (err) {
    //   console.log(err);
    // }
  };

  // const onReport = (e, report) => {
  //   if (e.target && e.target.name && e.target.name === "status") {
  //     return;
  //   }
  //   onSelectReport(report);
  // };
  // console.log(report, "report in Report");
  return (
    <>
      <div
        className="report-item-wrapper"
        onClick={async () => {
          try {
            await fetchProjectData(report.projectISbb_t925_projectsID);
            setTaskId(
              report.parent_task_id ? report.parent_task_id : report._id
            );
            setSubTaskId(report.parent_task_id ? report._id : null);
            setProjectId(report.projectISbb_t925_projectsID);
            report.parent_task_id ? setShowSubTaskDetail(true) : setShowTaskDetail(true);
          } catch (error) {
            console.error("Error fetching project data:", error);
          }
        }}
      >
        <div className="d-flex justify-content-between align-items-center report-item">
          <div className="d-flex align-items-center">
            <TaskStatusDropdown
              task={report}
              onChangeTaskStatus={onChangeReportStatus}
            />
            <div
              className="reportNameBody ellipsis"
              title={`TK(${report._id}): ${report.name}`}
            >{`TK(${report._id}): ${report.name}`}</div>
          </div>
          <div className="dateReportPriorityBlock">
            <div className="ownerNameBody">{report.actioner_name}</div>
            <div className="reportDuedateBody">
              {formatDate(report.date_deadline)}
            </div>
            <div className="reportPriorityBody">
              {report.priorityNUM ? (
                <RiFlag2Fill color="red" id="prority" />
              ) : (
                <RiFlag2Line id="prority" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="task-hr" style={{ margin: "10px 0px" }}></div>
      {showTaskDetail && (
        <TaskDetail
          onClose={() => setShowTaskDetail(false)}
          id={taskId}
          projectId={projectId}
        />
      )}
      {showSubTaskDetail && (
        <SubTaskDetail
          onClose={() => setShowSubTaskDetail(false)}
          id={subTaskId}
          taskid={taskId}
          // projectId={projectId}
        />
      )}
    </>
  );
};

Report.propTypes = {
  report: PropTypes.object,
  onSelectReport: PropTypes.func,
};
export default Report;
