import { getProject } from "API/projects";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/workspaceSlice";
import { setProject, setIsUpdateProject } from "redux/projectSlice";

const useGetProjectData = () => {
  const display_project = useSelector(
    (state) => state.workspaces.display_project
  );
  const dispatch = useDispatch();

  const fetchProjectData = async (projectId = null) => {
    // console.log(projectId, 'projectId in FetchProjectData')
    try {
      const res = projectId
        ? await getProject(projectId)
        : await getProject(display_project);
      if (res.data.success === 1) {
        console.log(res, res.data.project, "response-get-project-success-1");
        const project = res.data?.project;
        dispatch(setProject(project));
        dispatch(setIsUpdateProject(false));
      }
    } catch (err) {
      console.log(err.response.data, "err-data-get-project");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.response.data.error,
        })
      );
    }
  };

  return { fetchProjectData };
};

export default useGetProjectData;
