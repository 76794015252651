import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PlusIconButton, XIconButton } from "./IconButton";
import { setError } from "redux/workspaceSlice";
import LoadingTags from "./LoadingTags";
import { getTags, addTag, editTag, deleteTag } from "API/tags";
import { HexColorPicker } from "react-colorful";
import { isJSONRegExp } from "services/helpers";
import MultiSelect from "components/MultiSelect";
import { useDebouncedCallback } from "use-debounce";
import { deepEqualObj } from "services/helpers";

const TagsSettingsMobile = ({
  disabled,
  workspaceId,
  projectId,
  openState,
  handleColorClick,
}) => {
  const templates = useSelector((state) => state.appsetting.tag_templates);
  const visibleTo = useSelector((state) => state.appsetting.tag_visible_to);
  const editors = useSelector((state) => state.appsetting.tag_editors);
  const dispatch = useDispatch();

  const tagTemplates = [
    {
      name: "No template",
      tags: null,
    },
  ].concat(templates);
  let templatesOptions = tagTemplates.map((template, i) => (
    <option key={i} value={template.name}>
      {template.name}
    </option>
  ));
  const [selectedTemplateName, setSelectedTemplateName] =
    useState("No template");

  // parsing tags object into the format for tags element
  const parseTagTemplates = (tagsObj) => {
    let tag_list = [];
    for (const [key, value] of Object.entries(tagsObj)) {
      const taglist = value?.split(";").map((tag) => ({
        tag_name: tag.trim(),
        tag_color: "#0000ff",
      }));
      const ct = {
        _id: null,
        name: key,
        taglist: taglist,
        visible_to: [],
        editors: [],
      };
      tag_list.push(ct);
    }
    return tag_list;
  };

  const initCategoryTags = {
    _id: null,
    name: "",
    taglist: [{ tag_name: "", tag_color: "#0000ff" }],
    visible_to: [],
    editors: [],
  };

  const initTagsObj = [
    {
      name: "",
      taglist: [{ tag_name: "", tag_color: "#0000ff" }],
      visible_to: [],
      editors: [],
    },
  ];

  const [categoryTags, setCategoryTags] = useState([initCategoryTags]);
  const [noTemplateCategoryTags, setNoTemplateCategoryTags] = useState([]);

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [colorErrors, setColorErrors] = useState({});
  const [afterAddTags, setAfterAddTags] = useState([]);
  const [initTagsData, setInitTagsData] = useState(initTagsObj);

  useEffect(() => {
    if (workspaceId || projectId) getInitCategoryTagsData();
  }, [workspaceId, projectId]);

  // Update data if the save was performed by clicking on the PlusIcon - OnNewClicked().
  // In this case, we cannot update the data correctly only with onBlur()
  useEffect(() => {
    if (afterAddTags.length !== 0) {
      const updatedTags = categoryTags.map((categotyTag, index) => {
        if (categotyTag._id === null) {
          if (index < afterAddTags.length) {
            return {
              ...categotyTag,
              _id: afterAddTags[index]._id,
            };
          } else {
            return categotyTag;
          }
        } else {
          return categotyTag;
        }
      });
      console.log(
        categoryTags,
        afterAddTags,
        updatedTags,
        "categoryTags-afterAddTags-updatedTags-useEffect"
      );
      setCategoryTags(updatedTags);
      // setNoTemplateCategoryTags(updatedTags.filter((item) => "_id" in item && item?._id));
      setAfterAddTags([]);
    }
  }, [afterAddTags]);

  const getInitCategoryTagsData = async () => {
    try {
      setLoading(true);
      const response = await getTags(workspaceId, projectId);
      // console.log(response, response.data.list,'response-getTags')
      if (response?.data?.success === 1) {
        // check if the element includes a JSON object
        const ct_list = response?.data?.list.filter((el) => {
          const isJsonValid = isJSONRegExp(el.taglistISsmallplaintextbox);
          return isJsonValid;
        });
        // console.log(ct_list, "ct-List");
        if (ct_list.length > 0) {
          let initCategoryTags = ct_list.map((ct) => {
            let taglist = JSON.parse(ct.taglistISsmallplaintextbox);
            return {
              _id: ct._id,
              name: ct.name,
              taglist: taglist || [{ tag_name: "", tag_color: "#0000ff" }],
              visible_to: isJSONRegExp(ct.visible_to)
                ? JSON.parse(ct.visible_to)
                : [ct.visible_to],
              editors: isJSONRegExp(ct.editors)
                ? JSON.parse(ct.editors)
                : [ct.editors],
            };
          });
          const reversedCategoryTagList = [...initCategoryTags].reverse();
          setCategoryTags(reversedCategoryTagList);
          setNoTemplateCategoryTags(reversedCategoryTagList);
          const initData = reversedCategoryTagList.map((item) => {
            return {
              name: item.name,
              taglist: item.taglist,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          setInitTagsData(initData);
        }
      } else if (response?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response?.data?.error,
          })
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err, "try-catch-err-get tags");
      if (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.toString(),
          })
        );
        setLoading(false);
      }
    }
  };

  const handleTemplateChange = (template) => {
    setSelectedTemplateName(template);
    setFormErrors([]);
    setColorErrors({});

    if (template !== "No template") {
      const currentTemplate = templates.find((t) => t.name === template);
      const template_tags = currentTemplate.tags;
      setCategoryTags(parseTagTemplates(template_tags));
    } else {
      noTemplateCategoryTags.length === 0
        ? setCategoryTags([initCategoryTags])
        : setCategoryTags(noTemplateCategoryTags);
    }
  };

  const onNewCategoryClick = () => {
    setCategoryTags([...categoryTags, initCategoryTags]);
  };

  const onCategoryNameChange = (e, index) => {
    const newCategoryTags = categoryTags.map((ct, i) =>
      i === index
        ? {
            ...ct,
            name: e.target.value,
          }
        : ct
    );

    setCategoryTags(newCategoryTags);
  };

  const onVisibleToChanged = (data, index) => {
    const valuesArray = data.map((el) => el.value);
    const newCategoryTags = categoryTags.map((ct, i) =>
      i === index
        ? {
            ...ct,
            visible_to: valuesArray,
          }
        : ct
    );

    setCategoryTags(newCategoryTags);
  };

  const onEditorsChanged = (data, index) => {
    const valuesArray = data.map((el) => el.value);
    const newCategoryTags = categoryTags.map((ct, i) =>
      i === index
        ? {
            ...ct,
            editors: valuesArray,
          }
        : ct
    );
    setCategoryTags(newCategoryTags);
  };

  const onNewTagClick = (category_index) => {
    const newCategoryTags = categoryTags.map((ct, i) =>
      i === category_index
        ? {
            ...ct,
            taglist: [...ct.taglist, { tag_name: "", tag_color: "#0000ff" }],
          }
        : ct
    );

    setCategoryTags(newCategoryTags);
  };

  const onTagNameChange = (e, tag_index, category_index) => {
    const newCategoryTags = categoryTags.map((ct, i) =>
      i === category_index
        ? {
            ...ct,
            taglist: ct.taglist.map((tag, j) =>
              j === tag_index ? { ...tag, tag_name: e.target.value } : tag
            ),
          }
        : ct
    );
    setCategoryTags(newCategoryTags);
  };

  const onInputTagColorChange = (e, tag_index, category_index) => {
    const color = e.target.value;
    const colorRegex = /^#[0-9A-Fa-f]{6}$/;
    if (!colorRegex.test(color)) {
      setColorErrors((prevErrors) => ({
        ...prevErrors,
        [`${category_index}-${tag_index}`]: "Invalid format color",
      }));
    } else {
      setColorErrors((prevErrors) => ({
        ...prevErrors,
        [`${category_index}-${tag_index}`]: null,
      }));
    }
    const newCategoryTags = categoryTags.map((ct, i) =>
      i === category_index
        ? {
            ...ct,
            taglist: ct.taglist.map((tag, j) =>
              j === tag_index ? { ...tag, tag_color: color || "#0000ff" } : tag
            ),
          }
        : ct
    );

    setCategoryTags(newCategoryTags);
  };

  const handleColorChange = useDebouncedCallback(
    (newColor, category_index, tag_index) => {
      colorErrors[`${category_index + "-" + tag_index}`] = null;
      setColorErrors(colorErrors);
      const newCategoryTags = categoryTags.map((ct, i) =>
        i === category_index
          ? {
              ...ct,
              taglist: ct.taglist.map((tag, j) =>
                j === tag_index ? { ...tag, tag_color: newColor } : tag
              ),
            }
          : ct
      );

      setCategoryTags(newCategoryTags);
      Object.keys(colorErrors).length !== 0 &&
        Object.values(colorErrors).every((item) => item === null) &&
        onBlur(
          category_index,
          newCategoryTags[category_index].taglist,
          newCategoryTags
        );
    },
    500
  );

  const onDeleteTagClick = (tag_index, category_index) => {
    const newCategoryTags = categoryTags.map((ct, i) => {
      if (i === category_index) {
        const newTags = ct?.taglist.filter((tag, j) => j !== tag_index);
        return {
          ...ct,
          taglist:
            newTags.length !== 0
              ? newTags
              : [{ tag_name: "", tag_color: "#0000ff" }],
        };
      }
      return ct;
    });
    setCategoryTags(newCategoryTags);
    onBlur(
      category_index,
      newCategoryTags[category_index].taglist,
      newCategoryTags
    );
  };

  const onBlur = async (category_index, tagColorList, categories) => {
    const categoryTag = (categories || categoryTags).find(
      (ct, i) => i === category_index
    );
    console.log(categoryTag, "categoryTag");
    validate(categories);
    if (
      !categoryTag.name ||
      !categoryTag.visible_to.length ||
      !categoryTag.editors.length ||
      categoryTag.taglist.some((item) => !item.tag_name)
    )
      return;

    // if (categoryTag.id === null) {
    //   formData.append("api_method", "add_tag");
    // } else {
    //   formData.append("api_method", "edit_tag");
    //   formData.append("tag_id", categoryTag.id);
    // }
    // console.log(categoryTag.taglist, "categoryTag.taglist")
    const formData = {
      name: categoryTag.name,
      taglist: tagColorList
        ? JSON.stringify(tagColorList)
        : JSON.stringify(categoryTag.taglist),
      visible_to: JSON.stringify(categoryTag.visible_to),
      editors: JSON.stringify(categoryTag.editors),
      workspace_id: workspaceId === undefined ? "" : workspaceId,
      project_id: projectId === undefined ? "" : projectId,
    };
    console.log(categoryTag, "categoryTag inBlur");
    const initTagObj = initTagsData.find((p, i) => i === category_index);
    const editedFormObj = {
      name: categoryTag.name,
      taglist: tagColorList ? tagColorList : categoryTag.taglist,
      visible_to: categoryTag.visible_to,
      editors: categoryTag.editors,
    };
    console.log(
      initTagsData,
      initTagObj,
      editedFormObj,
      "initFieldObj, editedFormObj"
    );
    const isFormDataChanged = categoryTag._id
      ? !deepEqualObj(initTagObj, editedFormObj)
      : null;

    try {
      const response =
        categoryTag._id === null
          ? await addTag(formData)
          : isFormDataChanged
          ? await editTag({ ...formData, tag_id: categoryTag._id })
          : null;
      // console.log(response, "edit-tag");
      if (response?.data?.success === 1) {
        // getInitCategoryTagsData();
        if (categoryTag._id === null) {
          const newCategoryTags = categoryTags.map((ct, i) => {
            if (i === category_index) {
              return {
                ...ct,
                _id: response?.data?.new_tag_id.toString(),
              };
            }
            return ct;
          });
          console.log(newCategoryTags, "newCategorytags in Add-Edit");
          setAfterAddTags(newCategoryTags);
          setNoTemplateCategoryTags([
            ...noTemplateCategoryTags,
            newCategoryTags.find(
              (item) => item._id === response?.data?.new_tag_id.toString()
            ),
          ]);
          const initData = newCategoryTags.map((item) => {
            return {
              name: item.name,
              taglist: item.taglist,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          setInitTagsData(initData);
          // setCategoryTags(newCategoryTags);
        } else {
          // setCategoryTags(categories || categoryTags);
          setAfterAddTags(categories || categoryTags);
          setNoTemplateCategoryTags(categories || categoryTags);
          const initData = (categories || categoryTags).map((item) => {
            return {
              name: item.name,
              taglist: item.taglist,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          setInitTagsData(initData);
        }
      } else if (response?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: "",
        })
      );
    }
  };

  const onDeleteCategoryClick = async (category_index) => {
    const targetCategoryTag = categoryTags.find(
      (ct, i) => i === category_index
    );
    const targetId = targetCategoryTag._id;
    if (targetId !== null) {
      // formData.append("api_method", "delete_tag");
      // formData.append("tag_id", targetId);
      try {
        const response = await deleteTag({ tag_id: targetId });
        if (response.data.success === 1) {
          const newCategoryTags = categoryTags.filter(
            (ct, i) => i !== category_index
          );
          console.log(newCategoryTags, "newCategoryTags-delete Tag");
          if (newCategoryTags.length !== 0) {
            setCategoryTags(newCategoryTags);
            setNoTemplateCategoryTags(newCategoryTags);
            const initData = newCategoryTags.map((item) => {
              return {
                name: item.name,
                taglist: item.taglist,
                visible_to: item.visible_to,
                editors: item.editors,
              };
            });
            setInitTagsData(initData);
          } else {
            setCategoryTags([initCategoryTags]);
            setNoTemplateCategoryTags([]);
            setInitTagsData(initTagsObj);
          }
        } else if (response?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: response?.data?.error,
            })
          );
        }
      } catch (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.toString(),
          })
        );
      }
    } else if (categoryTags.length > 1) {
      const newCategoryTags = categoryTags.filter(
        (ct, i) => i !== category_index
      );
      setCategoryTags(newCategoryTags);
      setNoTemplateCategoryTags(newCategoryTags);
    }
  };

  const validate = (categories) => {
    let errors = [];
    (categories || categoryTags).forEach((tag) => {
      let error = {
        name: "",
        visible_to: "",
        editors: "",
        taglist: [],
        color: "",
      };

      if (!tag.name) {
        error.name = "This field is required";
      }

      if (!tag.visible_to.length) {
        error.visible_to = "This field is required";
      }

      if (!tag.editors.length) {
        error.editors = "This field is required";
      }
      tag.taglist.forEach((item) => {
        let tagError = { tag_name: "", tag_color: "" };

        if (!item.tag_name) {
          tagError.tag_name = "Tag name is required";
        }
        // if (!item.tag_color) {
        //   tagError.tag_color = "Tag color is required";
        // }

        error.taglist.push(tagError);
      });
      errors.push(error);
    });
    setFormErrors(errors);
  };

  return (
    <div>
      {loading ? (
        <div className="d-flex flex-column mb-2">
          <Form.Label className="fs-16">Tags</Form.Label>
          <LoadingTags />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between mb-2">
            <div style={{ width: "36px", height: "36px" }}>
              <PlusIconButton
                onClick={onNewCategoryClick}
                disabled={disabled}
              />
            </div>
            <Form.Select
              className="w-100 ms-2"
              disabled={disabled}
              value={selectedTemplateName}
              onChange={(e) => handleTemplateChange(e.target.value)}
              handleTemplateChange
              required
            >
              <option value="" disabled>
                Apply template
              </option>
              {templatesOptions}
            </Form.Select>
          </div>
          <div className="d-flex align-items-center">
            <Form.Label className="">Category</Form.Label>
          </div>
          <Row>
            {categoryTags?.map((categoryTag, i) => (
              <div key={i} className={i !== 0 ? "mt-4" : ""}>
                <div className="d-flex flex-column">
                  <div className="form-field d-flex w-100">
                    <Form.Control
                      type="text"
                      placeholder="Tag category"
                      disabled={disabled}
                      value={categoryTag.name}
                      onChange={(e) => onCategoryNameChange(e, i)}
                      onBlur={() => onBlur(i)}
                      className={
                        formErrors[i] && formErrors[i].name
                          ? "border-danger me-2"
                          : "me-2"
                      }
                      style={{ height: "36px" }}
                    />
                    <div style={{ width: "36px", height: "36px" }}>
                      <XIconButton
                        onClick={() => onDeleteCategoryClick(i)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-field d-flex flex-column w-100 mt-2">
                    <div>
                      {i === 0 && (
                        <Form.Label className="">Visible to</Form.Label>
                      )}
                      <MultiSelect
                        onChange={(data) => onVisibleToChanged(data, i)}
                        options={visibleTo}
                        selected={categoryTag.visible_to}
                        placeholder="Visible to"
                        disabled={disabled}
                        onBlur={() => onBlur(i)}
                        required
                        style={{ height: "36px" }}
                        className={
                          formErrors[i] && formErrors[i].visible_to
                            ? "border-danger mb-2"
                            : "mb-2"
                        }
                      />
                    </div>
                    <div>
                      {i === 0 && <Form.Label className="">Editor</Form.Label>}
                      <MultiSelect
                        multiple
                        onChange={(data) => onEditorsChanged(data, i)}
                        options={editors}
                        selected={categoryTag.editors}
                        placeholder="Editors"
                        disabled={disabled}
                        onBlur={() => onBlur(i)}
                        required
                        style={{ height: "36px" }}
                        className={
                          formErrors[i] && formErrors[i].editors
                            ? "border-danger"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {categoryTag?.taglist?.map(({ tag_name, tag_color }, j) => (
                    <div className="w-100 d-flex flex-column mt-2" key={j}>
                      <div className="d-flex mb-2">
                        {j === 0 && (
                          <div style={{ width: "36px", height: "36px" }}>
                            <PlusIconButton
                              onClick={() => onNewTagClick(i)}
                              disabled={disabled}
                            />
                          </div>
                        )}
                        <Form.Control
                          style={{ width: "", height: "36px" }}
                          className={
                            formErrors[i] && formErrors[i]?.taglist[j]?.tag_name
                              ? "border-danger ms-2"
                              : j === 0 ? "ms-2" : ""
                          }
                          type="text"
                          placeholder="Tag Name"
                          value={tag_name}
                          disabled={disabled}
                          onChange={(e) => onTagNameChange(e, j, i)}
                          onBlur={() => onBlur(i)}
                        />
                      </div>
                      <div className="d-flex">
                    
                        <Form.Control
                          style={{ width: "", height: "36px" }}
                          className={
                            colorErrors[`${i}-${j}`]
                              ? "me-2 border-danger"
                              : "me-2"
                          }
                          type="text"
                          placeholder="Tag color"
                          value={tag_color}
                          disabled={disabled}
                          onChange={(e) => onInputTagColorChange(e, j, i)}
                          onBlur={() =>
                            !colorErrors?.[`${i}-${j}`] && onBlur(i)
                          }
                        />
                        {colorErrors[`${i}-${j}`] && (
                          <p
                            className="text-danger"
                            style={{ fontSize: "12px", margin: 0 }}
                          >
                            {colorErrors[`${i}-${j}`]}
                          </p>
                        )}
                      {/* </div> */}
                      <div onClick={(e) => e.stopPropagation()}>
                        <div
                          style={{
                            width: "36px",
                            height: "36px",
                            backgroundColor: tag_color,
                            cursor: "pointer",
                            marginRight: "8px",
                            borderRadius: "0.25rem",
                          }}
                          onClick={() => !disabled && handleColorClick(i, j)}
                        ></div>
                        {openState[`${i}-${j}`] && (
                          <div
                            style={{
                              position: "relative",
                              zIndex: 100,
                              width: "1px",
                              height: "1px",
                              cursor: "pointer",
                            }}
                          >
                            <HexColorPicker
                              color={tag_color}
                              onChange={(newColor) =>
                                handleColorChange(newColor, i, j)
                              }
                              style={{ width: "150px", height: "150px" }}
                            />
                          </div>
                        )}
                      </div>
                      <XIconButton
                        onClick={() => onDeleteTagClick(j, i)}
                        disabled={disabled}
                      />
                    </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};
TagsSettingsMobile.propTypes = {
  disabled: PropTypes.bool,
  workspaceId: PropTypes.string,
  projectId: PropTypes.string,
  openState: PropTypes.object,
  handleColorClick: PropTypes.func,
};
export default TagsSettingsMobile;
