import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { addDays } from "date-fns";
import { formatDate } from "services/helpers";
import { setError } from "redux/workspaceSlice";
import { editTask } from "API/tasks";
import { HambugurMenuContext } from "services/contexts";
import { setIsUpdateProject } from "redux/projectSlice";
import compareAsc from "date-fns/compareAsc";
import { Spinner } from "react-bootstrap";

const TaskTimeline = ({ tasks, projectId }) => {
// console.log(tasks, 'incoming tasks')
  const project = useSelector((state) => state.project.project);
  const { name: projectName, date_from, date_to } = project;
  const projectDateFrom = formatDate(date_from);
  const projectDateTo = formatDate(date_to);

  const dispatch = useDispatch();
  const onItemSelected = useContext(HambugurMenuContext);
  const [graphTasks, setGraphTasks] = useState([]);
  
  useEffect(() => {
    const initTasks = getGraphTasks();
    setGraphTasks(initTasks);
  }, [tasks]);

  useEffect(() => {
    const myTimeout = setTimeout(initDomElement, 100);

    return () => {
      clearTimeout(myTimeout);
    };
  }, [graphTasks]);

  const isDisabled = (task) => {
    return task.role !== "Admin" && task.role !== "Owner";
  };

  const getGraphTasks = () => {
    const tempTasks = [];
    for (let task of tasks) {
      const endDay = task.date_to
        ? addDays(new Date(task.date_to), 1)
        : new Date();
      const taskObj = {
        start: new Date(task.date_from),
        end: endDay,
        name: `TK(${task._id}) - ${task.name}`,
        customText: "Custom Text 1",
        id: task._id,
        type: "task",
        progress: 0,
        role: task.role,
        isDisabled: isDisabled(task),
        styles: {
          progressColor: "#ffbb54",
          progressSelectedColor: "#ff9e0d",
          progressWidth: "100%",
        },
      };
      tempTasks.push(taskObj);
      if (task.sub_tasks.length > 0) {
        for (let subTask of task.sub_tasks) {
          const endDate = subTask.date_to
            ? addDays(new Date(subTask.date_to), 1)
            : new Date();
          const subTaskObj = {
            start: new Date(subTask.date_from),
            end: endDate,
            name: `SbTK(${subTask._id}) - ${subTask.name}`,
            customText: "Custom Text 2",
            id: subTask._id,
            type: "task",
            progress: 0,
            role: subTask.role,
            isDisabled: isDisabled(subTask),
            styles: {
              progressColor: "#ffbb54",
              progressSelectedColor: "#ff9e0d",
            },
            isSubTask: true,
          };
          tempTasks.push(subTaskObj);
        }
      }
    }
    return tempTasks;
  };

  const initDomElement = () => {
    const headerCells = document.getElementsByClassName("_WuQ0f");
    for (let i = 0; i < headerCells.length; i++) {
      if (i > 0) {
        headerCells[i].style.display = "none";
      }
    }

    const cells = document.getElementsByClassName("_3lLk3");
    for (let i = 0; i < cells.length; i++) {
      if (!cells[i].hasAttribute("title")) {
        cells[i].style.display = "none";
      }
    }

    let taskNameElements = document.getElementsByClassName("_nI1Xw");
    for (let i = 0; i < taskNameElements.length; i++) {
      taskNameElements[i].style.cursor = "pointer";
      taskNameElements[i].addEventListener(
        "click",
        // function (event) {
        function () {
          // const taskName = event.target.innerHTML.trim().replace(/&gt;/g, ">");
          const graphTaskItem = graphTasks.find((graphTask, idx) => {
            if (idx === i) return true;
            // if (idx === i && graphTask.name.trim() === taskName) return true;
            return false;
          });
          // console.log(graphTaskItem, 'graphTaskItem-true-false')
          if (graphTaskItem) openTask(graphTaskItem);
        },
        false
      );
    }
  };

  const handleTaskChange = async (task) => {
    console.log(task, "task-handleTaskChange");
    const initialtaskData = tasks.find((el) => el._id === task.id);

    const formattedValueStart = formatDate(task.start);
    let compareResultFrom = compareAsc(
      new Date(projectDateFrom),
      new Date(formattedValueStart)
    );
    if (compareResultFrom > 0) {
      setGraphTasks(graphTasks);
      dispatch(
        setError({
          isShow: true,
          title: "Warning",
          message: `Date From in ${
            task.name
          } must match Date From in the ${projectName} ${formatDate(
            projectDateFrom
          )}`,
        })
      );
      return;
    }
    const formattedValueEnd = formatDate(task.end);
    let compareResultTo = compareAsc(
      new Date(formattedValueEnd),
      new Date(projectDateTo)
    );
    let equalDatesTo = compareAsc(
      new Date(formattedValueEnd),
      new Date(initialtaskData.date_to)
    );
    if (compareResultTo > 0 && equalDatesTo !== 0) {
      setGraphTasks(graphTasks);
      dispatch(
        setError({
          isShow: true,
          title: "Warning",
          message: `Date To in ${
            task.name
          } must match Date To in the ${projectName} ${formatDate(
            projectDateTo
          )}`,
        })
      );
      return;
    }

    const formData = {
      task_id: task.id,
      name: task.name,
      date_from: formatDate(task.start),
      date_to: formatDate(task.end),
      date_deadline: formatDate(task.end),
    };
    try {
      const response = await editTask(formData);
      if (response.data.success === 1) {
        let newTasks = graphTasks.map((t) => (t.id === task.id ? task : t));
        setGraphTasks(newTasks);
        dispatch(setIsUpdateProject(true));
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const openTask = (task) => {
    const { id, isSubTask } = task;
    isSubTask
      ? onItemSelected("Edit Sub-task", id, projectId)
      : onItemSelected("Edit Task", id, projectId);
  };
  // console.log(graphTasks, "graphTasks-before render");
  return (
    <>
      {graphTasks.length > 0 ? (
        <Gantt
          tasks={graphTasks}
          listCellWidth={300}
          columnWidth={60}
          headerHeight={50}
          rowHeight={50}
          viewMode={ViewMode.Day}
          barFill={40}
          handleWidth={20}
          barCornerRadius={10}
          ganttHeight={graphTasks.length >= 10 ? 500 : 0}
          draggable={true}
          onDateChange={handleTaskChange}
          onDoubleClick={openTask}
          fontSize="14px"
        />
      ) : 
      <div className="d-flex justify-content-center" style={{marginTop: '50px'}}>
      <Spinner animation="border" />
    </div>
      }
    </>
  );
};

TaskTimeline.propTypes = {
  projectId: PropTypes.string,
  tasks: PropTypes.array,
};
export default TaskTimeline;
