import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account:{},
  agile_sections_editors: [],
  agile_sections_templates: [],
  agile_sections_visible_to: [],
  participant_types: [],
  participant_status: [],
  tag_templates: [],
  tag_editors: [],
  tag_visible_to: [],
  field_types: [],
  field_visible_to: [],
  field_editors: [],
  message_visible_to: [],
  message_recipients: [],
  isMessages: false,
  isAlerts: false,
  app_setting_load: false,
  priorities: null,
  date_filter: null,
  periods: null,
  // report_filters: [],
  mailServer: "",
  mailAddress: "",
  mailPassword: "",
  filters: [],
}

const appsettingSlice = createSlice({
  name: 'appsetting',
  initialState,
  reducers: {
    init(state, action) {
      console.log(action.payload, 'action.payload-settings')       
      state.account = action.payload.account;
      state.agile_sections_editors = action.payload.agile_sections_editors;
      state.agile_sections_templates = action.payload.agile_sections_templates;
      state.agile_sections_visible_to = action.payload.agile_sections_visible_to;
      state.participant_types = action.payload.participant_types;
      state.participant_status = action.payload.participant_status;
      state.tag_templates = action.payload.tag_templates;
      state.tag_editors = action.payload.tag_editors;
      state.tag_visible_to = action.payload.tag_visible_to;
      state.field_types = action.payload.field_types;
      state.field_visible_to = action.payload.field_visible_to;
      state.field_editors = action.payload.field_editors;
      state.message_visible_to = action.payload.message_visible_to;
      state.message_recipients = action.payload.message_recipients;
      state.priorities = action.payload.priorities;
      state.date_filter = action.payload.date_filter;
      state.periods = action.payload.periods;
      state.mailServer = action.payload.account.incoming_mail_server;
      state.mailAddress = action.payload.account.incoming_mail_address;
      state.mailPassword = action.payload.account.incoming_mail_password;
      state.filters = action.payload.account.filters;
    },

    setIsAlerts(state, action) {
      state.isAlerts = action.payload.isAlerts
    },

    setIsMessages(state, action) {
      state.isMessages = action.payload.isMessages
    },

    setAppSettingLoad(state, action) {
      state.app_setting_load = action.payload.app_setting_load
    },
    updateAppSettingFilters(state, action) {
      console.log(action.payload, 'action.payload-updateAppSetingsFilter')
      state.filters = action.payload
    },
    // setReportFilters(state, action) {
    //     state.report_filters = action.payload.report_filters
    // },
  }
})

export const { init, setIsAlerts, setIsMessages, setAppSettingLoad, setReportFilters, updateAppSettingFilters } = appsettingSlice.actions

export default appsettingSlice.reducer