import React, { useEffect, useState, useMemo } from "react";
// import "features/task/Task.css";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setError } from "redux/workspaceSlice";
import { setIsUpdateProject } from "redux/projectSlice";
import { getTags } from "API/tags";
import { deleteTaskTagLink, addTaskTagLink } from "API/tasks";
import Select, { components } from "react-select";
import { isJSONRegExp } from "services/helpers";
import { v4 as uuidv4 } from "uuid";

const TagMultiSelect = ({
  disabled,
  taskId,
  projectId,
  workspaceId,
  savedTags,
  taskTagsList,
  isFilterReport,
  setFilterReportTags,
  readOnly,
}) => {
  const dispatch = useDispatch();

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (workspaceId || projectId) setTagsAsnyc();
  }, [workspaceId, projectId]);

  // useEffect(() => {
  //   console.log(savedTags,'report-saved-tags')
  //   isFilterReport && setSelectedTags(savedTags);
  // }, [savedTags]);

  const setTagsAsnyc = async () => {
    let categorytags = [];
    if (isFilterReport) {
      const response = await getTags(workspaceId, projectId);
      if (response.data.success === 1) {
        if (response.data.error) {
          // console.log(response.data.error, "response.data.error-get tags");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: response.data.error,
            })
          );
          return;
        }
        // check if the element includes a JSON object
        categorytags = response.data.list.filter((el) => {
          const isJsonValid = isJSONRegExp(el.taglistISsmallplaintextbox);
          return isJsonValid;
        });
      }
    } else {
      categorytags =
        taskTagsList &&
        taskTagsList?.filter((el) => {
          const isJsonValid = isJSONRegExp(el.taglistISsmallplaintextbox);
          return isJsonValid;
        });
    }

    // console.log(categorytags, "categoryetags")
    const tempTags = [];
    const tempSelectedTags = [];
    for (let ct of categorytags) {
      const tag_list = JSON.parse(ct.taglistISsmallplaintextbox);
      for (let tag of tag_list) {
        const tagObj = {
          _id: ct._id,
          name: ct.name,
          tag: tag.tag_name,
          color: tag.tag_color,
          tag_id: null,
        };
        tempTags.push(tagObj);
        for (let savedTag of savedTags) {
          if (savedTag.tag_name.trim() === tag.tag_name.trim())
            tempSelectedTags.push({
              ...tagObj,
              tag_id: savedTag.task_tag_link_id,
            });
        }
      }
    }
    const tagsIds = mergeTagIds(tempTags, tempSelectedTags);
    setTags(tagsIds);
    setSelectedTags(tempSelectedTags);
  };

  const mergeTagIds = (arr1, arr2) => {
    // console.log(arr1, arr2, "arr1-arr2");
    const mergedArray = [...arr1];
    for (let i = 0; i < mergedArray.length; i++) {
      const item1 = mergedArray[i];

      for (let j = 0; j < arr2.length; j++) {
        const item2 = arr2[j];

        if (item1.tag === item2.tag) {
          // item1.tag_id = item2.tag_id.toString();
          item1._id = item2._id.toString();
          break;
        }
      }
    }
    return mergedArray;
  };

  const onSelected = async (list, item) => {
    // console.log(selectedTags, list, item, "selectedTags-list-item-add-selectedTags")
    // formData.append('api_method', 'add_task_tag_link')
    const formData = {
      tag_id: item._id,
      tag_name: item.tag,
      task_id: taskId,
      tag_color: item.color,
    };
    try {
      const response = await addTaskTagLink(formData);
      if (response.data.success === 1) {
        const newSelectedValues = list.map((l) => {
          if (l.tag === item.tag)
            return Object.assign({}, l, {
              tag_id: response.data.new_task_tag_link_id.toString(),
            });
          return l;
        });
        const tagsIds = mergeTagIds(tags, newSelectedValues);

        setTags(tagsIds);
        setSelectedTags(newSelectedValues);
        dispatch(setIsUpdateProject(true));
      } else {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response?.data?.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err?.toString(),
        })
      );
    }
  };

  const onRemoved = async (item) => {
    console.log(item, "onRemove");
    // formData.append('api_method', 'delete_task_tag_link')
    // formData.append('task_tag_link_id', item.tag_id)
    try {
      const response = await deleteTaskTagLink({
        task_tag_link_id: item.tag_id.toString(),
      });
      // console.log(response.data, "deleteTaskTagLink")
      if (response.data.success !== 1) {
        if (response.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: response.data.error,
            })
          );
        }
      } else {
        setSelectedTags((prevSelectedTags) =>
          prevSelectedTags.filter((el) => el.tag !== item.tag)
        );
        dispatch(setIsUpdateProject(true));
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err?.toString(),
        })
      );
    }
  };

  const removeDuplicatesByTag = (arr) => {
    const tagsSet = new Set();
    let result = [];

    for (const item of arr) {
      if (!tagsSet.has(item.tag)) {
        tagsSet.add(item.tag);
        result.push(item);
      } else {
        result = result.filter((resItem) => resItem.tag !== item.tag);
        continue;
      }
    }
    console.log(result, "result removing");
    return result;
  };
  const handleChange = (selected) => {
    // console.log(selected, "selected in the Select-hadleChange");
    const chosenTag = tags.find(
      (el) => el.tag === selected[selected.length - 1].label
    );
    // console.log(chosenTag, "chosenTag in the Select-hadleChange");
    const isOptionSelected = selectedTags.some(
      (el) => el.tag === chosenTag.tag
    );
    // console.log(isOptionSelected, "sOptionSelected");
    if (isOptionSelected) {
      let chosenTag = selectedTags.find(
        (el) => el.tag === selected[selected.length - 1].label
      );
      // console.log();
      // Delete the selected option
      if (!isFilterReport) {
        onRemoved(chosenTag);
      } else {
        setFilterReportTags(removeDuplicatesByTag(selected));
        setSelectedTags(removeDuplicatesByTag(selected));
      }
    } else if (isFilterReport) {
      setFilterReportTags([...selectedTags, chosenTag], chosenTag);
      setSelectedTags([...selectedTags, chosenTag], chosenTag);
    } else {
      // Add the selected option
      onSelected([...selectedTags, chosenTag], chosenTag);
    }
  };

  const handleRemove = (option) => {
    // console.log(option, "option-remove")
    if (isFilterReport) {
      setFilterReportTags((prevSelectedTags) =>
        prevSelectedTags.filter((el) => el.tag_name !== option.tag)
      );
      setSelectedTags((prevSelectedTags) =>
        prevSelectedTags.filter((el) => el.tag !== option.tag)
      );
    } else {
      onRemoved(option);
    }
  };

  const options = useMemo(() => {
    const groupedOptions = {};
    tags.forEach((item) => {
      if (!groupedOptions[item.name]) {
        groupedOptions[item.name] = [];
      }
      groupedOptions[item.name].push({
        value: item.tag,
        label: item.tag,
        _id: item._id,
        tag_id: item.tag_id,
        tag: item.tag,
        color: item.color,
      });
    });
    return Object.keys(groupedOptions).map((name) => ({
      label: name,
      options: groupedOptions[name],
    }));
  }, [tags]);

  // This component is used to fix console warning- "Warning: Encountered two children with the same key, `undefined-undefined`."
  // eslint-disable-next-line react/prop-types
  const MyValueContainer = ({ children, ...props }) => {
    // eslint-disable-next-line react/prop-types
    if (children && children[0]?.length) {
      // eslint-disable-next-line react/prop-types
      const newChilds = children[0].map((child) =>
        React.cloneElement(child, {
          key: `${child.props.data.tag_id}-${uuidv4()}`,
        })
      );
      newChilds.forEach((newChild, index) => {
        children[0][index] = newChild;
      });
    }
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  };
  return (
    <Select
      options={options}
      isMulti
      isDisabled={disabled}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleChange}
      value={selectedTags}
      placeholder="Tags"
      components={{
        MultiValueLabel:
          // eslint-disable-next-line react/prop-types
          ({ data }) => (
            <div className="multi-value-label" style={{ color: "white" }}>
              {/* eslint-disable-next-line react/prop-types */}
              {data.tag}
            </div>
          ),
        // eslint-disable-next-line react/prop-types
        ValueContainer: MyValueContainer,
        // eslint-disable-next-line react/prop-types
        Option: ({ innerProps, label }) => (
          <div {...innerProps} style={{ marginLeft: "8px" }}>
            <input
              type="checkbox"
              checked={selectedTags?.some((option) => option.tag === label)}
              readOnly
            />
            <span style={{ marginLeft: "5px", cursor: "pointer" }}>
              {label}
            </span>
          </div>
        ),
        // eslint-disable-next-line react/prop-types
        MultiValueRemove: ({ innerProps, data }) => (
          <div
            {...innerProps}
            // eslint-disable-next-line react/prop-types
            className="multi-value-remove"
            onClick={() => handleRemove(data)}
          >
            <div className="circle">
              <span>&#x2715;</span>
            </div>
          </div>
        ),
        ClearIndicator: () => null,
      }}
      styles={{
        multiValue: (provided, state) => ({
          ...provided,
          background: state.data.color,
          borderRadius: "10px",
          padding: "2px 6px",
          marginRight: "4px",
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: "white",
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor:
            disabled && readOnly
              ? "rgba(255, 255, 255, 0.5)"
              : disabled
              ? "#e9ecef"
              : "rgba(255, 255, 255, 0.5)",
        }),
      }}
    />
  );
};
TagMultiSelect.propTypes = {
  disabled: PropTypes.bool,
  workspaceId: PropTypes.string,
  projectId: PropTypes.string,
  taskId: PropTypes.string,
  savedTags: PropTypes.array,
  taskTagsList: PropTypes.array,
  isFilterReport: PropTypes.bool,
  setFilterReportTags: PropTypes.func,
  readOnly: PropTypes.bool,
};
export default TagMultiSelect;
