import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useSelector, useDispatch } from "react-redux";
import ActionerTodayBlock from "./ActionerTodayBlock";
import Agenda from "./Agenda";
import LoadingScreen from "layouts/LoadingScreen";
import { setError } from "redux/workspaceSlice";
import TodayTimeline from "./TodayTimeline";
import { getTeamMemberDetails } from "API/members";
import { sortByField } from "services/helpers";
import { setIsUpdateProject } from "redux/projectSlice";
// import { getViewReport } from "API/reports";
import {getDashboardData} from "API/dashboard";

const Dashboard = () => {
  const { accountISbb_accountsID: accountID } = useSelector(
    (state) => state.appsetting.account
  );
  const dispatch = useDispatch();
  const [todayTasks, setTodayTasks] = useState([]);
  const [isActionerResults, setIsActionersResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionerTodayList, setActionerTodayList] = useState({});

  useEffect(() => {
    setIsLoading(true); 
  
    const fetchData = async () => {
      try {
        await getAsActionerTodayData(accountID);
        await ViewFilters();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false); 
      }
    };
  
    fetchData();
  
    return () => {
      dispatch(setIsUpdateProject(false));
    };
  }, []);
 
  const getAsActionerTodayData = async (accountId) => {
    await getTeamMemberDetails(accountId)
      .then((res) => {
        const teamMember = res?.data?.team_member;
        if (teamMember?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: teamMember?.error,
            })
          );
        } else {
          setTodayTasks(sortByField(teamMember?.tasks, "actioner_priorityNUM"));
        }
      })
      .catch((err) => {
        if (err?.response?.status) {
          console.log(err, err?.response?.status, "add-workspace-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message || err.response.data.error,
            })
          );
        }
      })
  };

  const ViewFilters = async () => {
    setIsLoading(true);
    try {
      // await getViewReport({
      //   closedYN: "0",
      //   group_by_actionerYN: "1",
      // })
      await getDashboardData()
        .then((res) => {
          console.log(res, 'getDashboard')
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res?.data?.error,
              })
            );
            return;
          }
          if (res.data.success === 1) {
            // console.log(res.data.list, "res.data-Dashboard ViewReport");
            setActionerTodayList(Object.values(res?.data?.list));
            setIsActionersResults(Boolean(res?.data?.results))
          }
        })
        .catch((err) => {
          console.log(err, "Error-catch Get ViewReport");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err?.response?.data?.error,
            })
          );
        })
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="container content-wrapper">
      <div className="dashboard-wrapper">
        <div className="dashboard-left">
          <TodayTimeline todayTasks={todayTasks} isActioner={isActionerResults} />
        </div>
        <div className="dashboard-center-right">
          {isActionerResults && (
            <div className="dashboard-center">
              <ActionerTodayBlock actionerTodayList={actionerTodayList}/>
            </div>
          )}
          <div className="dashboard-right">
            <Agenda />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
