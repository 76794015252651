import React, { useState, useEffect, useContext } from "react";
import styles from "./Workspace.module.scss";
import PropTypes from "prop-types";
import { Card, Button, Dropdown/*, ButtonGroup */} from "react-bootstrap";
import { useSelector } from "react-redux";
import HambugerDropdown from "components/HambugerDropdown";
import ProjectList from "features/project/ProjectList";
import ProjectMonth from "features/project/ProjectMonth";
import ProjectTimeline from "features/project/ProjectTimeline";
import { WORKSPACE_MENUITEMS } from "services/constants";
import { HambugurMenuContext } from "services/contexts";
import useMobileWindow from "hooks/useMobileWindow";

const WorkspaceSingle = () => {
  const isMobile = useMobileWindow();

  const noProjectsMessageForOwnerAndAdmin =
    'This workspace has no projects. Click "New Project" above to add one.';
  const noProjectsMessageForOther = "This workspace has no projects.";

  const workspace = useSelector((state) => state.workspaces.workspace);
  const projects = useSelector((state) => state.workspaces.projects);
  const { permission } = workspace;
  const isPermission =
    permission === "Owner" ||
    permission === "Admin" ||
    permission === "Project Manager";
  const isOnlyOwnerPermission =
    permission === "Owner" || permission === "Admin";

  const onItemSelected = useContext(HambugurMenuContext);
  const [viewStatus, setViewStatus] = useState("list");

  useEffect(() => {
    setViewStatus("list");
  }, [projects]);
  return (
    <>
      <div className={styles.workspaceHeader}>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {isOnlyOwnerPermission && (
              <HambugerDropdown
                menuItems={WORKSPACE_MENUITEMS}
                itemId={workspace?._id}
                parentId={null}
                isExistedElements={!!projects.length}
              />
            )}
            <span className={`${styles.workspaceTitle}`}>
              {workspace?.name}
            </span>
          </div>
          {isPermission && (
            <div className="d-flex align-center-items">
              {workspace.permission !== "Project Manager" && (
                <Button
                  size="sm"
                  className="mx-3 action-btn"
                  onClick={() => onItemSelected("New Project", workspace?._id)}
                >
                  + New Project
                </Button>
              )}
              {!isMobile && <Dropdown align="end">
                <Dropdown.Toggle className="px-0 docsToggle">
                  View
                </Dropdown.Toggle>
                <Dropdown.Menu
                  onClick={(e) => e.stopPropagation()}
                  className="docsDropdownMenu"
                >
                  <Dropdown.Item onClick={() => setViewStatus("list")}>
                    List
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setViewStatus("month")}>
                    Month
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setViewStatus("timeline")}>
                    Timeline
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>}
            </div>
          )}
        </div>
      </div>
      <Card
        className="show"
        style={{ backgroundColor: "var(--background-block)" }}
      >
        <Card.Body className={styles.workspaceCardBody}>
          <>
            {projects?.length ? (
              <>
                {viewStatus === "list" ? (
                  <ProjectList workspaceId={workspace?._id} />
                ) : viewStatus === "month" ? (
                  <ProjectMonth
                    projects={projects}
                    workspaceId={workspace?._id}
                  />
                ) : (
                  <ProjectTimeline
                    projects={projects}
                    workspaceId={workspace?._id}
                  />
                )}
              </>
            ) : isPermission ? (
              <div className="message">{noProjectsMessageForOwnerAndAdmin}</div>
            ) : (
              <div className="message">{noProjectsMessageForOther}</div>
            )}
          </>
        </Card.Body>
      </Card>
    </>
  );
};

WorkspaceSingle.propTypes = {
  workspace: PropTypes.object,
};

export default WorkspaceSingle;
