import React from "react";
import PropTypes from "prop-types";
import TaskList from "features/task/TaskList";
import TaskMonth from "features/task/TaskMonth";
import TaskTimeline from "features/task/TaskTimeline";

const ProjectView = ({
  viewStatus,
  tasks,
  projectId,
  isProjectLoading,
  tasksSelected,
  tasksTotal
}) => {
  let ViewElement = null;
  if (viewStatus === "list") {
    ViewElement = (
        <TaskList
          tasks={tasks}
          projectId={projectId}
          isProjectLoading={isProjectLoading}
          tasksTotal={tasksTotal}
          tasksSelected={tasksSelected}
        />
    );
  } else if (viewStatus === "month") {
    ViewElement = (
      <div>
        <TaskMonth tasks={tasks} projectId={projectId} />
      </div>
    );
  } else {
    ViewElement = <TaskTimeline tasks={tasks} projectId={projectId} />;
  }

  return <div style={{ marginTop: 10 }}>{ViewElement}</div>;
};

ProjectView.propTypes = {
  viewStatus: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  isProjectLoading: PropTypes.bool,
  tasksSelected:PropTypes.number.isRequired,
  tasksTotal:PropTypes.number.isRequired
};
export default ProjectView;
