import React, { useEffect, useState, useContext } from "react";
import "./Task.css";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// import { ListGroup, Row, Col, Container } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/daygrid/main.css";
import interactionPlugin /*, { Draggable }*/ from "@fullcalendar/interaction";
import { addDays } from "date-fns";
import { setError } from "redux/workspaceSlice";
import { editTask } from "API/tasks";
import { HambugurMenuContext } from "services/contexts";
import { setIsUpdateProject } from "redux/projectSlice";
import compareAsc from "date-fns/compareAsc";
import { formatDate } from "services/helpers";

const TaskMonth = ({ tasks, projectId }) => {
  const project = useSelector((state) => state.project.project);
  const { name: projectName, date_from, date_to } = project;
  const projectDateFrom = formatDate(date_from);
  const projectDateTo = formatDate(date_to);

  const dispatch = useDispatch();
  const onItemSelected = useContext(HambugurMenuContext);

  const [initialGraphTasks, setInitialGraphTasks] = useState([]);
  const [graphTasks, setGraphTasks] = useState([]);
  const [pendingTasks, setPendingTasks] = useState([]);

  const isEditable = (task) => {
    // console.log(task,'task-isEditable')
    return (
      task.role === "Admin" ||
      task.role === "Owner"
    );
  };

  useEffect(() => {
    // let draggableEl = document.getElementById("external-events");
    // new Draggable(draggableEl, {
    //   itemSelector: ".fc-event",
    //   eventData: function (eventEl) {
    //     let title = eventEl.getAttribute("title");
    //     let id = eventEl.getAttribute("data");
    //     return {
    //       title: title,
    //       id: id,
    //     };
    //   },
    // });

    const tempTasks = [];
    const tempPendingTasks = [];
    for (let task of tasks) {
      if (!task.date_from || !task.date_to) {
        const taskObj = {
          id: task._id,
          title: task.name,
          backgroundColor: "var(--timeline-color)",
          borderColor: "var(--timeline-color)",
          editable: isEditable(task),
        };
        tempPendingTasks.push(taskObj);
      } else {
        const taskObj = {
          id: task._id,
          title: task.name,
          start: task.date_from,
          end: addDays(Date.parse(task.date_to), 1),
          allDay: true,
          backgroundColor: "var(--timeline-color)",
          borderColor: "var(--timeline-color)",
          editable: isEditable(task),
        };
        tempTasks.push(taskObj);
      }
      if (task.sub_tasks.length > 0) {
        for (let subTask of task.sub_tasks) {
          if (!subTask.date_from || !subTask.date_to) {
            console.log(subTask, "subtask in TaskMonth");
            const subTaskObj = {
              id: subTask._id,
              title: subTask.name,
              backgroundColor: "var(--timeline-color)",
              borderColor: "var(--timeline-color)",
              // borderColor: "#aeb8c2",
              editable: isEditable(subTask),
              isSubTask: true,
            };
            tempPendingTasks.push(subTaskObj);
          } else {
            const subTaskObj = {
              id: subTask._id,
              title: subTask.name,
              start: subTask.date_from,
              end: subTask.date_to,
              allDay: true,
              backgroundColor: "var(--timeline-color)",
              borderColor: "var(--timeline-color)",
              editable: isEditable(subTask),
              isSubTask: true,
            };
            tempTasks.push(subTaskObj);
          }
        }
      }
    }
    setInitialGraphTasks(tempTasks);
    setGraphTasks(tempTasks);
    setPendingTasks(tempPendingTasks);
  }, [tasks]);

  //   handling to drop the external task on calendar
  const handleReceive = (info) => {
    //   removing the moved task from pending task list
    const newPendingTasks = pendingTasks.filter(
      (task) => task.id !== info.event.id
    );
    setPendingTasks(newPendingTasks);

    // synchronize the updated task with backend using api and update app setting data
    const endDay = addDays(info.event.start, 1);
    const newInfo = {
      ...info,
      event: {
        ...info.event,
        id: info.event.id,
        title: info.event.title,
        start: info.event.start,
        end: endDay,
      },
    };
    updateTask(newInfo);

    // updating the list of tasks on calendar
    const taskObj = {
      id: info.event.id,
      title: info.event.title,
      start: info.event.start,
      end: endDay,
      allDay: true,
      // backgroundColor: "red",
      // backgroundColor: "#aeb8c2",
      // borderColor: "#aeb8c2",
      editable: isEditable(info.event.id),
    };
    const newGraphTasks = [...graphTasks, ...[taskObj]];
    setGraphTasks(newGraphTasks);

    // remove the task in blur color
    info.event.remove();
  };

  const updateTask = async (info) => {
    const fromDate = formatDate(info.event.start);
    const toDate = formatDate(addDays(info.event.end, -1));
    let compareResultFrom = compareAsc(
      new Date(projectDateFrom),
      new Date(fromDate)
    );
    console.log(compareResultFrom, "compareResultFrom");
    if (compareResultFrom > 0) {
      setGraphTasks(initialGraphTasks);
      dispatch(
        setError({
          isShow: true,
          title: "Warning",
          message: `Date From in ${
            info.event.title
          } must match Date From in the ${projectName} ${formatDate(
            projectDateFrom
          )}`,
        })
      );
      return;
    }
    let compareResultTo = compareAsc(new Date(toDate), new Date(projectDateTo));
    if (compareResultTo > 0) {
      setGraphTasks(initialGraphTasks);
      dispatch(
        setError({
          isShow: true,
          title: "Warning",
          message: `Date To in ${
            info.event.title
          } must match Date To in the ${projectName} ${formatDate(
            projectDateTo
          )}`,
        })
      );
      return;
    }
    // formData.append("api_method", "edit_task");
    const formData = {
      task_id: info.event.id,
      name: info.event.title,
      date_from: fromDate,
      date_to: toDate,
    };
    try {
      const response = await editTask(formData);
      if (response.data.success === 1) {
        dispatch(setIsUpdateProject(true));
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const handleClick = (info) => {
    const { id, extendedProps } = info.event;

    extendedProps?.isSubTask
      ? onItemSelected("Edit Sub-task", id, projectId)
      : onItemSelected("Edit Task", id, projectId);
  };

  // const onPendingTaskClicked = (pendingTask) => {
  //   const task_id = pendingTask.id;
  //   const role = getRole(task_id);

  //   if (role === "Admin" || role ==="Owner") onItemSelected("Edit Task", task_id, projectId);
  //   // else onItemSelected("View Task", task_id, projectId);
  // };

  // const unplanningWidth = pendingTasks.length === 0 ? 2 : 3;
  return (
    <FullCalendar
      initialView="dayGridMonth"
      plugins={[dayGridPlugin, interactionPlugin]}
      headerToolbar={{
        start: "prev",
        center: "title",
        end: "next",
      }}
      events={graphTasks}
      droppable={true}
      editable={true}
      eventResize={(info) => updateTask(info)} // resizing the date range of calendar
      eventDrop={(info) => updateTask(info)} // dropping task inside calendar
      eventClick={handleClick}
      eventReceive={handleReceive} // dropping external task into calendar
      fixedWeekCount={false}
    />
    // <Container>
    //   <Row>
    //     <Col sm={unplanningWidth}>
    //       <div
    //         id="external-events"
    //         style={{
    //           marginTop: 20,
    //           padding: "10px",
    //           width: "80%",
    //           height: "auto",
    //           maxHeight: "-webkit-fill-available",
    //         }}
    //       >
    //         {pendingTasks.length !== 0 && (
    //           <p>
    //             <strong>Unplanned Tasks</strong>
    //           </p>
    //         )}
    //         <ListGroup as="ol" numbered>
    //           {pendingTasks.map((task) => (
    //             <ListGroup.Item
    //               as="li"
    //               className="fc-event"
    //               title={task.title}
    //               data={task.id}
    //               key={task.id}
    //               style={{ cursor: "pointer" }}
    //               onClick={() => onPendingTaskClicked(task)}
    //             >
    //               {task.title}
    //             </ListGroup.Item>
    //           ))}
    //         </ListGroup>
    //       </div>
    //     </Col>
    //     <Col sm={8}>
    //       <FullCalendar
    //         initialView="dayGridMonth"
    //         plugins={[dayGridPlugin, interactionPlugin]}
    //         headerToolbar={{
    //           start: "prev",
    //           center: "title",
    //           end: "next",
    //         }}
    //         events={graphTasks}
    //         droppable={true}
    //         editable={true}
    //         eventResize={(info) => updateTask(info)} // resizing the date range of calendar
    //         eventDrop={(info) => updateTask(info)} // dropping task inside calendar
    //         eventClick={handleClick}
    //         eventReceive={handleReceive} // dropping external task into calendar
    //         fixedWeekCount={false}
    //       />
    //     </Col>
    //   </Row>
    // </Container>
  );
};

TaskMonth.propTypes = {
  projectId: PropTypes.string,
  tasks: PropTypes.array,
};
export default TaskMonth;
