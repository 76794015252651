import axios from './axios';

export const getAppSettingData = (workspaceId, projectId) => {
  const params = new URLSearchParams();
  if (workspaceId) params.append("workspace_id", workspaceId);
  if (projectId) params.append("project_id", projectId);

  return axios.get('app/settings', { params })
};
export const getReadEmail = () => axios.get('app/read_email');

export const getAlerts = () => axios.get('app/get_alerts');

export const getMessages = () => axios.get('app/get_messages');

export const addFilterSetting = payload => axios.post('app/add_filter_setting', payload);

export const updateAccountSettings = payload => axios.patch('app/update_account_settings', payload);

export const deleteFilterSetting = payload => axios.delete('app/delete_filter_setting', { data: payload });

export const getMarkAlertsAsSeen = () => axios.get('app/mark_alerts_as_seen');

export const getMarkMessagesAsSeen = () => axios.get('app/mark_messages_as_seen');





