import React, { useState, useEffect, useRef } from "react";
import "features/project/Project.css";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { PaperclipIconButton } from "components/IconButton";
import FileUpload from "components/FileUpload";
import { setError } from "redux/workspaceSlice";
import {
  getAgileDoc,
  addAgileDoc,
  deleteAgileDocFile,
  deleteAgileDocFileName,
} from "API/agiles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useGetAgileSectionsData from "hooks/useGetAgileSectionData";
import { useDispatch } from "react-redux";
import Modal from "components/ModalWindow";
import useModal from "hooks/useModal";
import FileUploadModal from "./FileUploadModal";
// import DotsSpinner from "components/DotsSpinner";
import { deepEqualObj } from "services/helpers";
import Textarea from "components/Textarea";

const AddEditAgile = ({ handleClose, projectId }) => {
  const dispatch = useDispatch();
  const [isShow, openModal, closeModal] = useModal();
  const noSections = "Please add Agile Doc Sections to this project first.";
  const [projectAgileSections, isAgileDocLoading] =
    useGetAgileSectionsData(projectId);
  const [encodedFile1, setEncodedFile1] = useState(null);
  const [encodedFile2, setEncodedFile2] = useState(null);
  const [encodedFile3, setEncodedFile3] = useState(null);
  const [encodedFile4, setEncodedFile4] = useState(null);
  const [fileName1, setFileName1] = useState(null);
  const [fileName2, setFileName2] = useState(null);
  const [fileName3, setFileName3] = useState(null);
  const [fileName4, setFileName4] = useState(null);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [chosenFile, setChosenFile] = useState(null);
  const [agileDocId, setAgileDocId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [initDocsData, setInitDocsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const itemRefs = useRef([]);

  const schema = yup.object().shape({
    title: yup.string().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    projectAgileSections.length !== 0 && getAgileDocsData();
  }, [projectAgileSections]);

  const getAgileDocsData = async () => {
    try {
      setIsLoading(true);
      await getAgileDoc(null, projectId)
        .then((res) => {
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
            return;
          }
          const data = res?.data?.doc;
          // console.log(data, "getAgilesDocs-data-doc");
          if (data) {
            setAgileDocId(data._id);
            setValue("title", data.name);
            const sectionDecriptions = JSON.parse(data.textISsmallplaintextbox);

            const updatedArray = projectAgileSections?.map((item) => {
              const text = sectionDecriptions[item.name] || "";
              return { ...item, text };
            });
            updatedArray.forEach((section, index) => {
              setValue(`sections[${index}]`, section.text);
            });

            const {
              attachment01ISfile,
              attachment02ISfile,
              attachment03ISfile,
              attachment04ISfile,
              attachment01_name,
              attachment02_name,
              attachment03_name,
              attachment04_name,
            } = data;

            setAttachmentFiles([
              {
                inputName: "attachment01ISfile",
                link: attachment01ISfile,
                name: attachment01_name,
                isUploaded: attachment01ISfile ? false : true,
              },
              {
                inputName: "attachment02ISfile",
                link: attachment02ISfile,
                name: attachment02_name,
                isUploaded: attachment02ISfile ? false : true,
              },
              {
                inputName: "attachment03ISfile",
                link: attachment03ISfile,
                name: attachment03_name,
                isUploaded: attachment03ISfile ? false : true,
              },
              {
                inputName: "attachment04ISfile",
                link: attachment04ISfile,
                name: attachment04_name,
                isUploaded: attachment04ISfile ? false : true,
              },
            ]);
            const initDocumentData = { ...data };
            delete initDocumentData.versionNUM;
            delete initDocumentData._id;
            initDocumentData.attachment01ISfile = null;
            initDocumentData.attachment02ISfile = null;
            initDocumentData.attachment03ISfile = null;
            initDocumentData.attachment04ISfile = null;
            initDocumentData.attachment01_name = null;
            initDocumentData.attachment02_name = null;
            initDocumentData.attachment03_name = null;
            initDocumentData.attachment04_name = null;
            // console.log(initDocumentData, "initDocumentData");
            setInitDocsData(initDocumentData);
          } else {
            setAttachmentFiles([
              {
                inputName: "attachment01ISfile",
                link: null,
                name: "",
                isUploaded: true,
              },
              {
                inputName: "attachment02ISfile",
                link: null,
                name: "",
                isUploaded: true,
              },
              {
                inputName: "attachment03ISfile",
                link: null,
                name: "",
                isUploaded: true,
              },
              {
                inputName: "attachment04ISfile",
                link: null,
                name: "",
                isUploaded: true,
              },
            ]);
          }
        })
        .catch((err) => console.log(err, "Error-catch getAgilesData"))
        .finally(() => setIsLoading(false));
    } catch (err) {
      console.log(err, "Error getAgilesData");
    }
  };
  const onFileNameChanged = (inputName, fileName) => {
    const newFilesTitle = attachmentFiles.map((el) => {
      if (el.inputName === inputName) {
        return {
          ...el,
          name: fileName,
          isUploaded: true,
        };
      }
      return el;
    });
    setAttachmentFiles(newFilesTitle);
    if (inputName === "attachment01ISfile") {
      setFileName1(fileName);
    }
    if (inputName === "attachment02ISfile") {
      setFileName2(fileName);
    }
    if (inputName === "attachment03ISfile") {
      setFileName3(fileName);
    }
    if (inputName === "attachment04ISfile") {
      setFileName4(fileName);
    }
  };

  const onFileDelete = (inputName) => {
    const newFilesTitle = attachmentFiles.map((el) => {
      if (el.inputName === inputName) {
        return {
          ...el,
          name: "",
          isUploaded: true,
        };
      }
      return el;
    });
    setAttachmentFiles(newFilesTitle);
    if (inputName === "attachment01ISfile") {
      setEncodedFile1(null);
      setFileName1("");
    }
    if (inputName === "attachment02ISfile") {
      setEncodedFile2(null);
      setFileName2("");
    }
    if (inputName === "attachment03ISfile") {
      setEncodedFile3(null);
      setFileName3("");
    }
    if (inputName === "attachment04ISfile") {
      setEncodedFile4(null);
      setFileName4("");
    }
  };
  const onSubmit = async (data) => {
    const sectionTextObj = {};

    projectAgileSections.forEach((item, index) => {
      const { name } = item;
      const text = data?.sections ? data?.sections[index] : "";
      sectionTextObj[name] = text;
    });
    const formData = {
      name: data.title,
      // attachment01ISfile must be-  null,
      attachment01ISfile: encodedFile1,
      attachment02ISfile: encodedFile2,
      attachment03ISfile: encodedFile3,
      attachment04ISfile: encodedFile4,
      // attachment01_name: null,
      attachment01_name: fileName1,
      attachment02_name: fileName2,
      attachment03_name: fileName3,
      attachment04_name: fileName4,
      textISsmallplaintextbox: JSON.stringify(sectionTextObj),
    };

    const isFormDataChanged = !deepEqualObj(initDocsData, formData);
    if (isFormDataChanged) {
      try {
        setIsSubmit(true);
        await addAgileDoc({ ...formData, project_id: projectId })
          .then((res) => {
            console.log(res, "response");
            if (res.data.success === 1) {
              if (res.data.error) {
                dispatch(
                  setError({
                    isShow: true,
                    title: "Error",
                    message: res.data.error,
                  })
                );
                return;
              }
              handleClose();
            }
          })
          .catch((err) => {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.message,
              })
            );
          })
          .finally(() => setIsSubmit(false));
      } catch (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.toString(),
          })
        );
      }
    } else {
      handleClose();
    }
  };

  const onDeleteAgileDoc = async (inputName) => {
    const index = attachmentFiles.findIndex(
      (item) => item.inputName === inputName
    );
    const fileNameMap = {
      attachment01ISfile: "attachment01_name",
      attachment02ISfile: "attachment02_name",
      attachment03ISfile: "attachment03_name",
      attachment04ISfile: "attachment04_name",
    };
    const fileName = fileNameMap[inputName];

    await deleteAgileDocFile(agileDocId, inputName);
    await deleteAgileDocFileName(agileDocId, { [fileName]: "" })
      .then((res) => {
        const data = res.data;
        const updatedFile = {
          inputName,
          link: data[inputName]?.uri,
          name: data[fileName],
          isUploaded: !data[inputName],
        };

        // Update an element in an array
        setAttachmentFiles((prevFiles) => [
          ...prevFiles.slice(0, index),
          updatedFile,
          ...prevFiles.slice(index + 1),
        ]);
      })
      .catch((err) => console.log(err, "edit agile doc"));
  };

  if (!isAgileDocLoading && projectAgileSections.length === 0) {
    return (
      <div className="d-flex justify-content-between align-content-center w-100">
        <p style={{ fontSize: "20px", margin: "0px" }}>{noSections}</p>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-content-center w-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {projectAgileSections.length !== 0 ? (
            <Form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>Document title</Form.Label>
                <div className="d-flex w-100">
                  <Form.Control
                    type="text"
                    placeholder="title"
                    autoFocus
                    name="title"
                    {...register("title")}
                    className={errors?.title ? "is-invalid" : ""}
                  />
                </div>
                {errors?.title && (
                  <p className="text-danger">{errors?.title.message}</p>
                )}
              </Form.Group>

              {projectAgileSections.map((section, index) => {
                itemRefs.current[index] = itemRefs.current[index] ?? React.createRef()
                return(
                <Form.Group className="mb-3" key={index}>
                  <Form.Label>{section.name}</Form.Label>
                  <Textarea
                    textareaRef={itemRefs.current[index]}
                    rows={4}
                    name={`sections[${index}]`}
                    value={watch(`sections[${index}]`)}
                    onChange={(e) => {
                      setValue(`sections[${index}]`, e.target.value);
                      // register(`sections[${index}]`, {
                      //   required: true,
                      // });
                    }}
                    className="agile-textarea"
                  />
                </Form.Group>
              )})}
              <div className="mb-3 d-flex flex-wrap">
                {attachmentFiles.map((attachmentFile, index) => (
                  <div className="d-flex fileUploaderElement mt-2" key={index}>
                    <PaperclipIconButton
                      onClick={() => {
                        openModal();
                        setChosenFile(attachmentFile);
                      }}
                    />
                    <Controller
                      name={attachmentFile.inputName}
                      control={control}
                      defaultValue={null}
                      render={() => (
                        <FileUpload
                          fileName={attachmentFile?.name}
                          fileLink={attachmentFile?.link}
                          isUploaded={attachmentFile?.isUploaded}
                          customStyles={{ margin: "0 8px" }}
                          onFileDelete={() =>
                            attachmentFile?.isUploaded
                              ? onFileDelete(attachmentFile?.inputName)
                              : onDeleteAgileDoc(attachmentFile?.inputName)
                          }
                          openModal={() => {
                            openModal();
                            setChosenFile(attachmentFile);
                          }}
                        />
                      )}
                    />
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-center mt-5 pb-5">
                <Button
                  variant="outline-secondary"
                  type="submit"
                  className="d-flex align-items-center"
                >
                  {isSubmit && (
                    <div className="spinner-border spinner-border-sm m-2"></div>
                  )}
                  <span>{isSubmit ? "Saving..." : "Save"}</span>
                </Button>
              </div>
            </Form>
          ) : null}
          <Modal
            isShow={isShow}
            handleClose={closeModal}
            modalHeader="New file"
            style={{ marginTop: "75px" }}
            size="md"
            centered
          >
            <FileUploadModal
              attachmentFile={chosenFile}
              control={control}
              projectId={projectId}
              handleClose={closeModal}
              setEncodedFile1={setEncodedFile1}
              setEncodedFile2={setEncodedFile2}
              setEncodedFile3={setEncodedFile3}
              setEncodedFile4={setEncodedFile4}
              onFileNameChanged={onFileNameChanged}
            />
          </Modal>
        </>
      )}
    </>
  );
};
AddEditAgile.propTypes = {
  handleClose: PropTypes.func,
  projectId: PropTypes.string,
};
export default AddEditAgile;
