import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";

const ButtonSpinner = ({ loading, onClick, children }) => {
  return (
    <Button
      variant="primary"
      onClick={onClick}
      disabled={loading}
      className="btn-sm d-flex align-items-center"
    >
      {loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{
            marginRight: "4px",
          }}
        />
      )}
      {children}
    </Button>
  );
};
ButtonSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export default ButtonSpinner;
