import React, { useState } from "react";
import "./Dashboard.css";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { formatDate} from "services/helpers";
import { addSpecaialDays } from "API/calendar";
import { setError } from "redux/workspaceSlice";
import MultipleDatePicker from "components/MultipleDatePicker";

const AddSpecialDaysForm = () => {
  //   registerLocale("en-US", enUS);
  const display_workspace = useSelector(
    (state) => state.workspaces.display_workspace
  );
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    workspace_id: display_workspace,
    dates: [],
    range_date: [],
  });

  
  const onSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
      ...formData,
      dates: formData.dates.map((dateObj) => ({
        ...dateObj,
        date: formatDate(dateObj.date), 
      })),
    };

    console.log(formattedData, "formattedData"); 

    await addSpecaialDays(formattedData)
      .then((res) => {
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
          return;
        }
      })
      .catch((err) => {
        console.log(err, "err-add-leave-days");
        err?.response?.status === 401
          ? (window.location.href = "https://account2.task925.com/?killsession")
          : dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.message,
              })
            );
      });
  };
  return (
    <Form onSubmit={onSubmit}>
      <div className="selectsBlock">
        <Form.Group className="selectItem mb-3 me-1">
          <Form.Label>Workspaces</Form.Label>
          <Form.Select
            value={formData.workspace_id}
            onChange={(e) =>
              setFormData({ ...formData, workspace_id: e.target.value })
            }
            required
          >
            <option value="" hidden>
              Workspace
            </option>
            <option value="">None</option>

            {workspaces?.map((workspace) => (
              <option value={workspace._id} key={workspace._id}>
                {workspace.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {/* <Form.Group className="selectItem mb-3 ms-1">
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Holiday name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </Form.Group> */}
      </div>

      <MultipleDatePicker formData={formData} setFormData={setFormData}/>
      {/* <Textarea
        textareaRef={textRef}
        placeholder="Enter your text..."
        rows={4}
        className="mt-3 mb-5"
        value={formData.textISsmallplaintextbox}
        onChange={(e) =>
          setFormData({ ...formData, textISsmallplaintextbox: e.target.value })
        }
      /> */}
      <div className="d-flex justify-content-end mt-5">
        <Button type="submit">Submit</Button>
      </div>
    </Form>
  );
};

AddSpecialDaysForm.propTypes = {
  onClose: PropTypes.func,
};
export default AddSpecialDaysForm;
