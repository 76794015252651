import { createSlice } from "@reduxjs/toolkit";
import {sortArrayAlphabetically} from "services/helpers";

const initialState = {
  workspaces: [],
  workspace: {},
  projects:[],
  display_workspace: '0',
  display_project: '0',
  error: {},
  isProjectChosen: false
}

const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    fetchWorkspaces(state, action) {
      state.workspaces = action.payload ? sortArrayAlphabetically(action.payload) : []
    },
    setWorkspace(state, action) {
      state.workspace = action.payload
    },
    setWorkspaceProjects(state, action) {
      state.projects = action.payload ? sortArrayAlphabetically(action.payload) : []
    },
    setIsProjectChosen(state, action) {
      state.isProjectChosen = action.payload
    },
    setDisplayWorkspace(state, action) {
      state.display_workspace = action.payload.display_workspace
    },
    setDisplayProject(state, action) {
      state.display_project = action.payload.display_project
    },
    setError(state, action) {
      state.error.isShow = action.payload.isShow
      state.error.title = action.payload.title
      state.error.message = action.payload.message
    }
  }
})

export const { fetchWorkspaces, setWorkspace, setWorkspaceProjects,setIsProjectChosen, setError, setDisplayWorkspace, setDisplayProject } = workspaceSlice.actions

export default workspaceSlice.reducer