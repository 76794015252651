import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { setError } from "redux//workspaceSlice";
import { addTaskFieldLink, editTaskFieldLink } from "API/fields";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { formatDate, formatDateTime } from "services/helpers";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import { setReadOnlyFieldByEditors } from "features/task/helpers";

const FieldsListView = ({
  taskId,
  projectId,
  disabled,
  isReadOnly,
  savedFields,
  isFilterReport = false,
  setReportFields,
  taskPermission
}) => {
  registerLocale("en-US", enUS);
  const dispatch = useDispatch();

  const [taskFields, setTaskFields] = useState([]);

  useEffect(() => {
    if (savedFields.length) {
      setTaskFields(savedFields);
    }
  }, [projectId, savedFields]);

  const onFieldLinkChanged = (e, field) => {
    const value = e.target.value;
    // console.log(value, "value-onFiledLinkChanched-text");
    const newLinks = taskFields.map((taskField) => (taskField._id === field._id ? { ...taskField, value } : taskField));
    setTaskFields(newLinks);
    setReportFields && setReportFields(newLinks);
  };

  const onDateLinkChanged = (value, link) => {
    const newLinks = taskFields.map((fieldlink) => (fieldlink._id === link._id ? { ...fieldlink, value } : fieldlink));

    setTaskFields(newLinks);
    setReportFields && setReportFields(newLinks);
  };

  const onCheckboxLinkChanged = async (e, link) => {
    const checkVal = e.target.checked;
    const fieldValue = checkVal ? "true" : "false";

    const formData = {
      field_id: link._id,
      task_id: taskId,
      field_name: fieldValue
    };
    try {
      if (!isFilterReport) {
        const response =
          link.linkISbb_t925_tasks_fieldsID === null
            ? await addTaskFieldLink(formData)
            : await editTaskFieldLink({
                ...formData,
                task_field_link_id: link.linkISbb_t925_tasks_fieldsID
              });
        console.log(response, "switch-form-Field");
        if (response?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: response.data.error
            })
          );
        }

        if (response?.data?.success === 1) {
          let newLinks = null;
          if (link.linkISbb_t925_tasks_fieldsID === null) {
            const newLinkId = response?.data?.new_task_field_link_id?.toString();
            newLinks = taskFields.map((fieldlink) =>
              fieldlink._id === link._id
                ? {
                    ...fieldlink,
                    value: fieldValue,
                    linkISbb_t925_tasks_fieldsID: newLinkId
                  }
                : fieldlink
            );
          } else {
            newLinks = taskFields.map((fieldlink) => (fieldlink._id === link._id ? { ...fieldlink, value: fieldValue } : fieldlink));
          }

          setTaskFields(newLinks);
        }
      } else {
        let newLinks = taskFields.map((fieldlink) => (fieldlink._id === link._id ? { ...fieldlink, value: fieldValue } : fieldlink));
        setReportFields(newLinks);
      }
    } catch (err) {
      console.log(err,'catch-err');
      if (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.message
          })
        );
      }
    }
  };

  const onFieldLinkBlured = async (link) => {
    const formData = {
      task_id: taskId,
      field_id: link._id,
      field_name: link.value
    };
    // console.log(link, formData, "targetLink-formdate");
    try {
      if (!isFilterReport) {
        const response =
          link.linkISbb_t925_tasks_fieldsID === null
            ? await addTaskFieldLink(formData)
            : await editTaskFieldLink({
                ...formData,
                task_field_link_id: link.linkISbb_t925_tasks_fieldsID
              });

        let error = response?.data?.error;
        if (error && error !== "No task field_name found.") {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: error
            })
          );
          return;
        }
        if (response.data.success === 1) {
          if (link.linkISbb_t925_tasks_fieldsID === null) {
            const newLinkId = response?.data?.new_task_field_link_id?.toString();
            const newLinks = taskFields.map((fieldlink) =>
              fieldlink._id === link._id ? { ...fieldlink, linkISbb_t925_tasks_fieldsID: newLinkId } : fieldlink
            );
            setTaskFields(newLinks);
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.message
          })
        );
      }
    }
  };
  const isDate = (str) => {
    var datePattern = /^\d{4}-\d{2}-\d{2}$/;
    // Check if the string matches the date format
    if (!datePattern.test(str)) return false;
    // Check if the date is correct
    var dateObj = new Date(str);
    if (isNaN(dateObj.getTime())) return false;

    return true;
  };

  const isDateTime = (str) => {
    var dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    if (!dateTimePattern.test(str)) return false;
    var dateObj = new Date(str);
    if (isNaN(dateObj.getTime())) return false;
    return true;
  };

  return (
    <div className="d-flex justify-content-between flex-wrap mt-3 container p-0">
      {disabled ? (
        <Col>
          <Form.Control type="text" placeholder="Field" disabled={true} />
        </Col>
      ) : (
        taskFields.map((field, i) => (
          <Fragment key={i}>
            {field.type === "text" && (
              <Form.Group className="mb-3 d-flex flex-column fieldItem">
                <Form.Label className="fs-14">{field.name}</Form.Label>
                <Form.Control
                  disabled={disabled || isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                  style={{
                    backgroundColor:
                      isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission)) ? "transparent" : ""
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Text"
                  value={field.value || ""}
                  onChange={(e) => onFieldLinkChanged(e, field)}
                  onBlur={() => onFieldLinkBlured(field)}
                />
              </Form.Group>
            )}
            {field.type === "number" && (
              <Form.Group className="mb-3 d-flex flex-column fieldItem">
                <Form.Label className="fs-14">{field.name}</Form.Label>
                <Form.Control
                  disabled={disabled || isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                  style={{
                    backgroundColor:
                      isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission)) ? "transparent" : ""
                  }}
                  type="number"
                  className="form-control"
                  placeholder="Number"
                  min={0}
                  value={field.value || ""}
                  onChange={(e) => onFieldLinkChanged(e, field)}
                  onBlur={() => onFieldLinkBlured(field)}
                />
              </Form.Group>
            )}
            {field.type === "date" && (
              <Form.Group className="mb-3 d-flex flex-column fieldItem">
                <Form.Label className="fs-14">{field.name}</Form.Label>
                <DatePicker
                  selected={field.value && isDate(field.value) ? new Date(field.value) : null}
                  value={field.value && isDate(field.value) ? field.value : null}
                  onCalendarClose={() => onFieldLinkBlured(field)}
                  onChange={(value) => onDateLinkChanged(formatDate(value), field)}
                  disabled={disabled || isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                  formatWeekDay={(day) => day.slice(0, 3)}
                  locale="en-US"
                  placeholderText="Date"
                  customInput={
                    <DatePickerCustomInput
                      isPermission={isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                    />
                  }
                  showYearDropdown
                />
              </Form.Group>
            )}
            {field.type === "date/time" && (
              <Form.Group className="mb-3 d-flex flex-column fieldItem">
                <Form.Label className="fs-14">{field.name}</Form.Label>
                <DatePicker
                  selected={field.value && isDateTime(field.value) ? new Date(field.value) : null}
                  value={field.value && isDateTime(field.value) ? field.value : null}
                  onCalendarClose={() => onFieldLinkBlured(field)}
                  onChange={(value) => onDateLinkChanged(formatDateTime(value), field)}
                  disabled={disabled || isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                  formatWeekDay={(day) => day.slice(0, 3)}
                  locale="en-US"
                  placeholderText="Date Time"
                  customInput={
                    <DatePickerCustomInput
                      isPermission={isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                    />
                  }
                  showYearDropdown
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="Time"
                />
              </Form.Group>
            )}
            {field.type === "yes/no" && (
              <Form.Group className="mb-3 pb-2 d-flex justify-content-between align-items-end fieldItem">
                <Form.Label className="fs-14">{field.name}</Form.Label>
                <label className="switch ms-3">
                  <input
                    type="checkbox"
                    disabled={disabled || isReadOnly || (!isFilterReport && setReadOnlyFieldByEditors(field.editors, taskPermission))}
                    checked={field.value !== "true" ? false : true}
                    onChange={(e) => onCheckboxLinkChanged(e, field)}
                  />
                  <span className="slider round"></span>
                </label>
              </Form.Group>
            )}
          </Fragment>
        ))
      )}
    </div>
  );
};
FieldsListView.propTypes = {
  name: PropTypes.string,
  taskId: PropTypes.string,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  projectId: PropTypes.string,
  savedFields: PropTypes.array,
  isFilterReport: PropTypes.bool,
  setReportFields: PropTypes.func,
  taskPermission: PropTypes.string
};
export default FieldsListView;
