import { useEffect, useState } from "react";
import { getAgileSections } from "API/agiles";
import { setError } from "redux/workspaceSlice";
import { useDispatch } from "react-redux";

const useGetAgileSectionsData = (projectId) => {
  const [projectAgileSections, setProjectAgileSections] = useState([]);
  const [isAgileDocLoading, setIsAgileDocLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    getAgileSectionsData();
  }, [projectId]);

  const getAgileSectionsData = async () => {
    // setIsAgileDocLoading(true);
    try {
      await getAgileSections(null, projectId)
        .then((res) => {
          // console.log(res, res.data, "get-agilesection-in Docs");
          if (res.data.error) {
            res.data.error === "No active session found for that account_id."
              ? (window.location.href =
                  "https://account2.task925.com/?killsession")
              : dispatch(
                  setError({
                    isShow: true,
                    title: "Error",
                    message: res.data.error,
                  })
                );
            return;
          }
          setProjectAgileSections(res.data.list);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log(err, err.response.status, "err-status-401");
            window.location.href = "https://account2.task925.com/?killsession";
          }
        })
        .finally(() => setIsAgileDocLoading(false));
    } catch (err) {
      console.log(err, "Error getAgilesData");
    }
  };
  return [projectAgileSections, isAgileDocLoading];
};

export default useGetAgileSectionsData;
