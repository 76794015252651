import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import "./mobile.css";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { init, setAppSettingLoad } from "redux/appsettingSlice";
import { getAppSettingData } from "API/settings";
import {
  fetchWorkspaces,
  setDisplayWorkspace,
  setDisplayProject,
  setError,
  setWorkspace,
  setWorkspaceProjects,
  setIsProjectChosen,
} from "redux/workspaceSlice";
import { setProjectsList } from "redux/projectSlice";
import { getWorkspace } from "API/workspaces";
import useMobileWindow from "hooks/useMobileWindow";
import { BsX } from "react-icons/bs";

const ProjectsDropdown = ({ toggleMobileNav, isMobileNav }) => {
  const isMobile = useMobileWindow();

  const projectsList = useSelector((state) => state.project.projectsList);
  const { display_project, isProjectChosen } = useSelector(
    (state) => state.workspaces
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    isMobileNav === false && setShowMobileModal(false);
  }, [isMobileNav]);

  const onChangeProject = async (workspaceId, projectId) => {
    dispatch(setAppSettingLoad({ app_setting_load: true }));
    const data = await getAppSettingData(workspaceId, projectId);
    dispatch(setAppSettingLoad({ app_setting_load: false }));
    if (data.error) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: data.message,
        })
      );
      return;
    }
    const settingsData = data.data.app_settings;
    dispatch(fetchWorkspaces(settingsData.workspaces));
    dispatch(setProjectsList(settingsData.projects));
    dispatch(
      setDisplayWorkspace({ display_workspace: settingsData.display_workspace })
    );
    dispatch(
      setDisplayProject({ display_project: settingsData.display_project })
    );
    dispatch(init(settingsData));

    await fetchWorkspaceData(
      settingsData.display_workspace,
      settingsData.display_project
    );
    navigate("/");
  };

  const fetchWorkspaceData = async (workspaceId, projectId) => {
    try {
      const res = await getWorkspace(workspaceId);
      // console.log(res.data.workspace, "response-get-workspace");
      if (res.data.success === 1) {
        const workspace = res.data?.workspace;
        dispatch(setWorkspace(workspace));
        dispatch(
          setWorkspaceProjects(
            workspace?.projects?.filter((item) => item._id === projectId)
          )
        );
        dispatch(setIsProjectChosen(true));
      }
    } catch (err) {
      console.log(err.response.data, "err-data-get-workspace");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.response.data.error || err.message,
        })
      );
    }
  };

  const toggleWorkspaceMobileModal = () => {
    setShowMobileModal(!showMobileModal);
  };

  return (
    <Dropdown align={{ lg: "start" }}>
      {isMobile ? (
        <Dropdown.Toggle
          onClick={toggleWorkspaceMobileModal}
          className={`${
            isProjectChosen ? styles.activeDropdown : styles.selectWorkspace
          } ${styles["dropdown-toggle"]}`}
        >
          My Projects
        </Dropdown.Toggle>
      ) : (
        <Dropdown.Toggle
          className={`${
            isProjectChosen ? styles.activeDropdown : styles.selectWorkspace
          } ${styles["dropdown-toggle"]}`}
        >
          My Projects
        </Dropdown.Toggle>
      )}
      {isMobile && showMobileModal && (
        <div
          className={`mobile-dropdown animate__animated ${
            showMobileModal ? "animate__slideInUp" : "animate__slideOutDown"
          }`}
        >
          <div className="mobile-content">
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={toggleWorkspaceMobileModal}
              className="d-flex justify-content-end mt-3 pe-3"
            >
              <BsX size={24} />
            </div>
            {projectsList.length !== 0 &&
              projectsList.map((item, i) => (
                <Dropdown.Item
                  key={i}
                  as="button"
                  onClick={() => {
                    toggleMobileNav && toggleMobileNav();
                    (location.pathname !== "/" ||
                      item._id !== display_project ||
                      isProjectChosen === false) &&
                      onChangeProject(
                        item.workspaceISbb_t925_workspacesID,
                        item._id
                      );
                  }}
                  className={`${
                    location.pathname === "/" && item._id === display_project
                      ? `${styles.active} ellipsis`
                      : "ellipsis"
                  }`}
                >
                  {item.name}
                </Dropdown.Item>
              ))}
          </div>
        </div>
      )}
      {!isMobile && (
        <Dropdown.Menu className={styles.customDropdownMenu}>
          {projectsList.length !== 0 &&
            projectsList.map((item, i) => (
              <Dropdown.Item
                key={i}
                as="button"
                onClick={() => {
                  // toggleMobileNav && toggleMobileNav();
                  (location.pathname !== "/" ||
                    item._id !== display_project ||
                    isProjectChosen === false) &&
                    onChangeProject(
                      item.workspaceISbb_t925_workspacesID,
                      item._id
                    );
                }}
                className={`${
                  location.pathname === "/" && item._id === display_project
                    ? `${styles.active}`
                    : ""
                }`}
              >
                {item.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

ProjectsDropdown.propTypes = {
  toggleMobileNav: PropTypes.func,
  isMobileNav: PropTypes.bool,
};

export default ProjectsDropdown;
