import { getWorkspace } from "API/workspaces";
import { useDispatch, useSelector } from "react-redux";
import {
  setWorkspace,
  setWorkspaceProjects,
  setError,
  setIsProjectChosen
} from "redux/workspaceSlice";

const useGetWorkspaceData = () => {
  const display_workspace = useSelector(
    (state) => state.workspaces.display_workspace
  );
  const dispatch = useDispatch();

  const fetchWorkspaceData = async (workspaceId) => {
    // console.log(workspaceId, 'workspaceId in FetcWorkspcaedata')
    try {
      const res = await getWorkspace(
        workspaceId ? workspaceId : display_workspace
      );
      // console.log(res.data.workspace, "response-get-workspace");
      if (res.data.success === 1) {
        const workspace = res.data?.workspace;
        dispatch(setWorkspace(workspace));
        dispatch(setWorkspaceProjects(workspace.projects));
        dispatch(setIsProjectChosen(false));
      }
    } catch (err) {
      console.log(err, "err-data-get-workspace");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err?.response?.data?.error || err.message,
        })
      );
    }
  };

  return { fetchWorkspaceData };
};

export default useGetWorkspaceData;
