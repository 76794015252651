import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import "./mobile.css";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import useMobileWindow from "hooks/useMobileWindow";
// import useClickOutside from "hooks/useClickOutside";
import { BsX } from "react-icons/bs";

const WorkspaceDropdown = ({ toggleMobileNav, isMobileNav }) => {
  const isMobile = useMobileWindow();
  // const dropdownRef = useClickOutside(() => setShowModal(false));

  const { isProjectChosen } = useSelector((state) => state.workspaces);
  const display_workspace = useSelector(
    (state) => state.workspaces.display_workspace
  );
  const workspaces = useSelector((state) => state.workspaces.workspaces);

  const navigate = useNavigate();
  let location = useLocation();
  const { onUpdateSettingData } = useUpdateSettingData();
  const { fetchWorkspaceData } = useGetWorkspaceData();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (display_workspace !== "0") fetchWorkspaceData(display_workspace);
    isMobileNav === false && setShowModal(false);
  }, [isMobileNav]);

  const onChangeWorkspace = async (id) => {
    await onUpdateSettingData(id, null);
    await fetchWorkspaceData(id);
    navigate("/");
  };

  const toggleWorkspaceMobileModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Dropdown
        align={{ lg: "start" }}
        // ref={dropdownRef}
      >
        {isMobile ? (
          <Dropdown.Toggle
            onClick={toggleWorkspaceMobileModal}
            className={`${
              location.pathname === "/" && !isProjectChosen
                ? styles.activeDropdown
                : styles.selectWorkspace
            } ${styles["dropdown-toggle"]}`}
          >
            My Workspaces
          </Dropdown.Toggle>
        ) : (
          <Dropdown.Toggle
            className={`${
              location.pathname === "/" && !isProjectChosen
                ? styles.activeDropdown
                : styles.selectWorkspace
            } ${styles["dropdown-toggle"]}`}
          >
            My Workspaces
          </Dropdown.Toggle>
        )}
        {isMobile && showModal && (
          <div
            className={`mobile-dropdown animate__animated ${
              showModal ? "animate__slideInUp" : "animate__slideOutDown"
            }`}
          >
            <div className="mobile-content">
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={toggleWorkspaceMobileModal}
                className="d-flex justify-content-end mt-3 pe-3"
              >
                <BsX size={24} />
              </div>
              {workspaces?.length !== 0 &&
                workspaces.map((item, i) => (
                  <Dropdown.Item
                    key={i}
                    as="button"
                    onClick={() => {
                      toggleWorkspaceMobileModal(false);
                      toggleMobileNav();
                      (location.pathname !== "/" ||
                        item._id !== display_workspace ||
                        isProjectChosen) &&
                        onChangeWorkspace(item._id);
                    }}
                    className={`${
                      location.pathname === "/" &&
                      item._id === display_workspace
                        ? `${styles.active} ellipsis`
                        : "ellipsis"
                    }`}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
            </div>
          </div>
        )}
        {!isMobile && (
          <Dropdown.Menu>
            {workspaces?.length !== 0 &&
              workspaces.map((item, i) => (
                <Dropdown.Item
                  key={i}
                  as="button"
                  onClick={() => {
                    // toggleMobileNav && toggleMobileNav();
                    (location.pathname !== "/" ||
                      item._id !== display_workspace ||
                      isProjectChosen) &&
                      onChangeWorkspace(item._id);
                  }}
                  className={`${
                    location.pathname === "/" && item._id === display_workspace
                      ? `${styles.active}`
                      : ""
                  }`}
                >
                  {item.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};
WorkspaceDropdown.propTypes = {
  toggleMobileNav: PropTypes.func,
  isMobileNav: PropTypes.bool,
};
export default WorkspaceDropdown;
