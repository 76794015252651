import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./fileUpload.module.scss";
import { XIconButton } from "components/IconButton";
import { server_domain } from "services/constants";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const FileUpload = ({
  onChange,
  fileName,
  fileLink,
  isClose = true,
  isUploaded,
  customStyles,
  onFileDelete,
  openModal,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    onChange(event);
  };

  const handleUpload = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };
  const handleDelete = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;

    // isUploaded && onFileDelete();
    onFileDelete();
  };

  const tooltip = (
    <Tooltip id="tooltip">
      Only the following formats are available for download - jpg, png, pdf,
      mpg, mp4, quicktime(mov), xlsx and docx.
    </Tooltip>
  );
  return (
    <div className="d-flex w-100">
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {selectedFile?.name || fileName ? (
        <>
          {fileName && isUploaded === false ? (
            <div className={styles.labelLinkBlock}>
              <a
                className="d-flex align-content-center me-2"
                href={server_domain + "/" + fileLink}
                target="_blank"
                rel="noreferrer"
              >
                <span>{fileName}</span>
              </a>
            </div>
          ) : (
            <div
              // onClick={handleUpload}
              onClick={isUploaded ? openModal : handleUpload}
              className={styles.labelBlock}
              style={{ ...customStyles }}
            >
              <div className={styles.chooseFile}>
                <span>Choose file</span>
              </div>
              <div className={styles.noFileChosen}>
                {selectedFile?.name || fileName}
              </div>
            </div>
          )}
        </>
      ) : (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <div
            onClick={isUploaded ? openModal : handleUpload}
            className={styles.labelBlock}
            style={{ ...customStyles }}
          >
            <div className={styles.chooseFile}>
              <span>Choose file</span>
            </div>
            <div className={styles.noFileChosen}>
              <span>No file chosen</span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      {isClose && <XIconButton onClick={handleDelete} />}
    </div>
  );
};
FileUpload.propTypes = {
  onChange: PropTypes.func,
  fileName: PropTypes.string,
  fileLink: PropTypes.string,
  isClose: PropTypes.bool,
  isUploaded: PropTypes.bool,
  customStyles: PropTypes.object,
  onFileDelete: PropTypes.func,
  openModal: PropTypes.func,
};
export default FileUpload;
