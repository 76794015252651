import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  user: {},
  system: {},
  groups: [],
  notifications: {},
  ui_settings: {},
  first_name:"",
  last_name:"",
  email: "",
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSessionData(state,action){
      const { first_name, last_name, email } = action.payload;
      state.first_name = first_name;
      state.last_name = last_name;
      state.email = email;
    },
    login(state, action) {
      const { user, system, groups, notifications, ui_settings } = action.payload;
      state.isLogin = true;
      state.user = { ...user };
      state.system = { ...system };
      state.groups = [...groups];
      state.notifications = { ...notifications };
      state.ui_settings = { ...ui_settings };
    },

    logout(state) {
      state.isLogin = false;
      state.user = {};
      state.system = {};
      state.groups = [];
      state.notifications = {};
      state.ui_settings = {};
    }
  }
})

export const { setSessionData, login, logout, initialize } = authSlice.actions

export default authSlice.reducer