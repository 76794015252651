import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  List,
  PersonFill,
  PlusLg,
  XLg,
  Paperclip,
  Send,
  Link45deg,
  Eye,
} from "react-bootstrap-icons";
import { FaFile, FaEdit } from "react-icons/fa";
import { MdPrint } from "react-icons/md";

export const FaPrintButton = ({ clickHandler, variant, styles }) => {
  return (
    <Button
      className="d-inline-flex justify-content-center align-items-center"
      type="button"
      variant={variant ? variant : "outline-danger"}
      onClick={clickHandler}
      style={{ height: 36, width: 36, padding: 2 }}
    >
      <MdPrint style={{ cursor: "pointer", ...styles }} />
    </Button>
  );
};
FaPrintButton.propTypes = {
  clickHandler: PropTypes.func,
  variant: PropTypes.string,
  styles: PropTypes.object,
};

export const FaFileButton = ({ clickHandler, styles }) => {
  return (
    <FaFile onClick={clickHandler} style={{ cursor: "pointer", ...styles }} />
  );
};
FaFileButton.propTypes = {
  clickHandler: PropTypes.func,
  styles: PropTypes.object,
};

export const FaEditButton = ({ clickHandler }) => {
  return <FaEdit onClick={clickHandler} style={{ cursor: "pointer" }} />;
};
FaEditButton.propTypes = {
  clickHandler: PropTypes.func,
  styles: PropTypes.object,
};

export const ListIconButton = () => {
  return (
    <Button
      variant="primary"
      size="sm"
      style={{ marginRight: 10, marginBottom: 3 }}
    >
      <List />
    </Button>
  );
};

export const PersonIconButton = () => {
  return (
    <Button
      variant="primary"
      size="sm"
      style={{ marginRight: 10, marginBottom: 3 }}
    >
      <PersonFill />
    </Button>
  );
};

export const PlusIconButton = ({ style, disabled, onClick }) => {
  return (
    <Button
      className="d-flex justify-content-center align-items-center"
      type="button"
      variant="primary"
      disabled={disabled}
      onClick={onClick}
      style={{
        height: 36,
        width: 36,
        padding: 8,
        marginTop: style?.marginTop ? style?.marginTop : "",
      }}
    >
      <PlusLg />
    </Button>
  );
};
PlusIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};
export const XIconButton = ({ variant, disabled, onClick }) => {
  return (
    <Button
      className="d-inline-flex justify-content-center align-items-center"
      type="button"
      variant={variant ? variant : "outline-danger"}
      disabled={disabled}
      onClick={onClick}
      style={{ height: 36, width: 36, padding: 8, cursor: "pointer" }}
    >
      <XLg />
    </Button>
  );
};
XIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};
export const PaperclipIconButton = ({ className, disabled, onClick, text }) => {
  const tooltip = (
    <Tooltip id="tooltip">
      Only the following formats are available for download - jpg, png, pdf,
      mpg, mp4, quicktime(mov), xlsx and docx.
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <Button
        className={`d-inline-flex justify-content-center align-items-center ${className}`}
        type="button"
        variant="primary"
        onClick={onClick}
        disabled={disabled}
        style={{ height: 36, width: 36, padding: 8 }}
      >
        <Paperclip style={{ transform: "rotate(45deg)" }} />
        {text}
      </Button>
    </OverlayTrigger>
  );
};
PaperclipIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.node,
};
export const SendIconButton = ({ onClick, disabled, className, text }) => {
  return (
    <Button
      className={`d-inline-flex justify-content-center align-items-center ${className}`}
      type="button"
      variant="primary"
      onClick={onClick}
      disabled={disabled}
      style={{ height: 36, width: 36, padding: 8 }}
    >
      {text}
      <Send />
    </Button>
  );
};
SendIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.node,
};

export const LinkIconButton = ({ className, disabled, onClick }) => {
  return (
    <Button
      className={`d-inline-flex justify-content-center align-items-center ${className}`}
      type="button"
      variant="primary"
      onClick={onClick}
      disabled={disabled}
      style={{ height: 32, width: 32, padding: 8 }}
    >
      <Link45deg />
    </Button>
  );
};
LinkIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
export const EyeIconButton = ({ className, disabled, onClick }) => {
  return (
    <Button
      className={`d-inline-flex justify-content-center align-items-center ${className}`}
      type="button"
      variant="primary"
      onClick={onClick}
      disabled={disabled}
      style={{ height: 32, width: 32, padding: 8 }}
    >
      <Eye />
    </Button>
  );
};
EyeIconButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
export const BellIcon = ({ className, style }) => {
  return (
    <svg
      className={`svg-inline--fa ${className}`}
      data-fa-transform="shrink-6"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="bell"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      data-fa-i2svg=""
      style={style}
    >
      <g transform="translate(224 256)">
        <g transform="translate(0, 0)  scale(0.625, 0.625)  rotate(0 0 0)">
          <path
            fill="currentColor"
            d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
            transform="translate(-224 -256)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
BellIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};
