import { server_domain } from "services/constants";
import { isJSONRegExp } from "services/helpers";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

export const setReadOnlyFieldByEditors = (fieldEditors, taskPermission) => {
  const editors = isJSONRegExp(fieldEditors)
    ? JSON.parse(fieldEditors)
    : [fieldEditors];
  return !editors.some((el) => {
    return (
      el === "All" ||
      el === taskPermission ||
      (el === "Admin" && taskPermission === "Owner")
    );
  });
};

function replaceLinksAndLineBreaks(str) {
  const linkRegex = /(?<!<a[^>]*?)(https?:\/\/\S+|www\.\S+)/gi;
  const lineBreakRegex = /(\r\n|\n|\r)/gm;

  str = str.replace(linkRegex, (match) => {
    if (!match.includes("://")) {
      match = "https://" + match;
    }
    const shortenedText =
      match.length > 50 ? match.substring(0, 50) + "..." : match;
    return `<a href="${match}" target="_blank">${shortenedText}</a>`;
  });

  str = str.replace(lineBreakRegex, "<br>");

  return str;
}

export function parseEmailData(str) {
  const regex = /\[image:\s*(.+?)\]/g;
  const matches = str.match(regex);

  if (!matches) {
    str.replace(/>/g, "<br>");
    return `<div>${str}</div>`;
  }
  const newStr = matches.reduce(
    (acc, match) => {
      const imageMatch = match.match(/\[(.+?)\]/)[1].split(": ")[1];
      const imageObj = { image: imageMatch };
      const newImageValue = `${server_domain}/${imageObj.image}`;
      const newImageLink = `<div><a href="${newImageValue}" target="_blank">${
        newImageValue.split("_")[2]
      }</a></div>`;

      return acc.replace(match, newImageLink);
    },
    str.replace(/>/g, "<br>")
  );

  return replaceLinksAndLineBreaks(newStr);
}

const unescapeHtml = (safe) => {
  return safe
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
};

export const loadContent = (savedContent) => {
  const parsedContent = JSON.parse(savedContent, (key, value) =>
    typeof value === "string" ? unescapeHtml(value) : value
  );
  const contentState = convertFromRaw(parsedContent);
  return EditorState.createWithContent(contentState);
};

const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
};

export const saveContent = (description) => {
  const content = description.getCurrentContent();
  const rawContent = convertToRaw(content);
  const escapedContent = JSON.stringify(rawContent, (key, value) =>
    typeof value === "string" ? escapeHtml(value) : value
  );
  return escapedContent;
};
