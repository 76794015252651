import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { addDays } from "date-fns";
import { setError } from "redux/workspaceSlice";
import { editProject } from "API/projects";
import { HambugurMenuContext } from "services/contexts";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { formatDate } from "services/helpers";

const ProjectTimeline = ({ projects, workspaceId }) => {
  const { fetchWorkspaceData } = useGetWorkspaceData();

  const dispatch = useDispatch();

  const onItemSelected = useContext(HambugurMenuContext);

  const [graphProjects, setGraphProjects] = useState([]);

  useEffect(() => {

    const initProjects = getGraphProjects();
    const myTimeout = setTimeout(initDomElement, 100);

    setGraphProjects(initProjects);

    return () => {
      clearTimeout(myTimeout);
    };
  }, []);

  const initDomElement = () => {
    const headerCells = document.getElementsByClassName("_WuQ0f");
    for (let i = 0; i < headerCells.length; i++) {
      if (i > 0) {
        headerCells[i].style.display = "none";
      }
    }

    const cells = document.getElementsByClassName("_3lLk3");
    for (let i = 0; i < cells.length; i++) {
      if (!cells[i].hasAttribute("title")) {
        cells[i].style.display = "none";
      }
    }

    let projectNameElements = document.getElementsByClassName("_nI1Xw");
    for (let i = 0; i < projectNameElements.length; i++) {
      projectNameElements[i].addEventListener(
        "click",
        function (event) {
          const projectName = event.target.innerHTML.replaceAll("&nbsp;", "");
          const graphProjectItem = projects.find(
            (graphProject, idx) => {
              if (idx === i && graphProject.name.trim() === projectName)
                return true;
              return false;
            }
          );

          if (graphProjectItem) handleDblClick(graphProjectItem);
        },
        false
      );
    }
  };

  const isDisabled = (project) => {
    return project.role !== "Admin" && project.role !== "Owner";
  };

  const getGraphProjects = () => {
    const tempProjects = [];
    for (let project of projects) {
      const projectObj = {
        start: project.date_from ? new Date(project.date_from) : new Date(),
        end: project.date_to
          ? addDays(new Date(project.date_to), 1)
          : new Date(),
        name: project.name,
        id: project._id,
        type: "task",
        progress: 0,
        role: project.role,
        isDisabled: isDisabled(project),
        // styles: {
        //   progressColor: "orange",
        //   // progressColor: "#ffbb54",
        //   progressSelectedColor: "red",
        //   // progressSelectedColor: "#ff9e0d",
        // },
      };
      tempProjects.push(projectObj);
    }
    return tempProjects;
  };

  const handleProjectChange = async (project) => {
    let newProjects = graphProjects.map((p) =>
      p.id === project.id ? project : p
    );

    setGraphProjects(newProjects);

    const formData = {
      project_id: project.id,
      name: project.name,
      date_from: formatDate(project.start),
      date_to: formatDate(project.end),
    };
    try {
      const res = await editProject(formData);
      console.log(res, "editProject in ProjectTimeLine")
      if (res.data.success === 1) {
        fetchWorkspaceData();
      } else if (res.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: res.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const handleDblClick = (project) => {
    onItemSelected("Edit Project", project._id, workspaceId);
  };

  return (
    <>
      {graphProjects.length > 0 && (
        <Gantt
          tasks={graphProjects}
          listCellWidth={300}
          columnWidth={60}
          headerHeight={50}
          ganttHeight={graphProjects.length >= 9 ? 450 : 0}
          rowHeight={50}
          viewMode={ViewMode.Day}
          barFill={40}
          barCornerRadius={10}
          onDateChange={handleProjectChange}
          onDoubleClick={handleDblClick}
        />
      )}
    </>
  );
};
ProjectTimeline.propTypes = {
  projects: PropTypes.array,
  workspaceId: PropTypes.string,
};
export default ProjectTimeline;
