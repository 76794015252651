import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const Textarea = ({
  textareaRef,
  value,
  onChange,
  onBlur,
  isPermission,
  isDisable,
  placeholder,
  name,
  className,
  register
}) => {

  useEffect(() => {
    if (textareaRef?.current) {

      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, textareaRef]);

  return (
    <Form.Control
      type="text"
      {...register}
      ref={textareaRef}
      placeholder={placeholder}
      as="textarea"
      rows={4}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{
        backgroundColor: isDisable && isPermission ? "transparent" : "",
        minHeight: "108px",
      }}
      disabled={isDisable}
      className={className}
    />
  );
};
Textarea.propTypes = {
  textareaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isPermission: PropTypes.bool,
  isDisable: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.object,
};
export default Textarea;
