import React, {useEffect} from "react";
import styles from "features/members/styles.module.scss";
import "./Dashboard.css";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Badge } from "react-bootstrap";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { setIsUpdateProject } from "redux/projectSlice";
import {
  dispatchIsShowTaskDetail,
  dispatchOpenTaskId,
  dispatchIsShowSubTaskDetail,
  dispatchOpenSubTaskId,
} from "redux/taskSlice";

const TodayTimeline = ({ todayTasks, isActioner }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { onUpdateSettingData, settingsUploaded } = useUpdateSettingData();
  const { fetchWorkspaceData } = useGetWorkspaceData();

  useEffect(() => {
    if (settingsUploaded) {
      navigate("/");
      dispatch(setIsUpdateProject(true));
    }
  }, [settingsUploaded]);
  
  return (
    <>
      <div
        className={`${styles.memberCard} card`}
        style={{ minWidth: "280px" }}
      >
        <div
          className="tasks-card-title project-card-title mb-2"
          style={{ marginLeft: "14px" }}
        >
          Today's Timeline
        </div>
        {todayTasks?.length === 0 ? (
          <div className={styles.memberTasks}>This member has no tasks.</div>
        ) : (
          <div
            className={
              isActioner ? "memberTaskWrapper" : "isActionerTaskWrapper"
            }
          >
            {todayTasks.map((task) => (
              <div
                className={styles.memberTasks}
                key={task._id}
                style={{ marginTop: "0px" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span>TK({task._id})</span>
                  </div>
                  <div className="d-flex">
                    {task.task_tags.map(
                      ({ tag_color, tag_name }) =>
                        tag_color && (
                          <Badge
                            key={tag_color}
                            pill
                            bg={tag_color}
                            style={{
                              backgroundColor: tag_color,
                            }}
                            className={styles.taskBadge}
                          >
                            {tag_name}
                          </Badge>
                        )
                    )}
                  </div>
                </div>
                <span
                  className="fw-600 my-2 hover-grey"
                  onClick={async () => {
                    try {
                      await onUpdateSettingData(
                        task.workspaceISbb_t925_workspacesID,
                        task.projectISbb_t925_projectsID
                      );
                      await fetchWorkspaceData(
                        task.workspaceISbb_t925_workspacesID
                      );
                    } catch (error) {
                      console.error("Error fetching project data:", error);
                    }
                  }}
                >
                  {task.project_name}
                </span>
                <span
                  className="hover-grey"
                  onClick={async () => {
                    try {
                      await onUpdateSettingData(
                        task.workspaceISbb_t925_workspacesID,
                        task.projectISbb_t925_projectsID
                      );
                      await fetchWorkspaceData(
                        task.workspaceISbb_t925_workspacesID
                      );
                      dispatch(
                        dispatchOpenTaskId(
                          task.parent_task_id ? task.parent_task_id : task._id
                        )
                      );
                      dispatch(
                        dispatchOpenSubTaskId(
                          task.parent_task_id ? task._id : null
                        )
                      );
                      task?.parent_task_id
                        ? dispatch(dispatchIsShowSubTaskDetail(true))
                        : dispatch(dispatchIsShowTaskDetail(true));
                    } catch (error) {
                      console.error("Error fetching project data:", error);
                    }
                  }}
                >
                  {task.name}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
TodayTimeline.propTypes = {
  todayTasks: PropTypes.array,
  isActioner: PropTypes.bool,
};

export default TodayTimeline;
