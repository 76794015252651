import React from "react";
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import { Paperclip } from "react-bootstrap-icons";
import { server_domain } from "services/constants";

const AttachmentLink = ({ link, children, className }) => {
  return (
    <Badge
      as="a"
      pill
      className={`rounded-pill d-flex justify-content-center align-items-center ${className}`}
      size="sm"
      style={{ textDecoration: "none" }}
      href={server_domain + "/" + link}
      target="_blank"
      rel="noreferrer"
    >
      <span
        style={{
          width: "65px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <Paperclip style={{ transform: "rotate(45deg)", marginRight:'2px' }} />
        <span>
          {children}
        </span>
      </span>
    </Badge>
  );
};
AttachmentLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};
export default AttachmentLink;
