import React, { useEffect, useState } from "react";
import "./settings.css";
import PropTypes from "prop-types";
import {
  Modal,
  Tabs,
  Tab,
  Form,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/workspaceSlice";
import {
  addFilterSetting,
  getReadEmail,
  deleteFilterSetting,
} from "API/settings";
import ReadEmailModal from "components/ModalWindow";
import useModal from "hooks/useModal";
import { AiOutlineWarning } from "react-icons/ai";
import { sortArrayAlphabetically } from "services/helpers";
import { toast } from "react-toastify";
import {updateAppSettingFilters} from "redux/appsettingSlice";

const SettingsModal = ({ showSetting, onHide }) => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.appsetting);
  const projectsList = useSelector((state) => state.project.projectsList);

  const addFilterSchema = yup.object().shape({
    // filter_email_address: yup
    //   .string()
    //   .email("Invalid email address")
    //   .matches(
    //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    //     "Invalid email address"
    //   ),
    // filter_subject_text: yup.string(),
    project_id: yup.string().required("Project is required"),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(addFilterSchema),
  });

  const [isReadEmailShow, openReadEmailModal, closeReadEmailModal] = useModal();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const [authorizationLink, setAuthorizationLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterProjects, setFilterProjects] = useState([]);

  useEffect(() => {
    clearErrors();
    setFilterProjects(
      projectsList.filter(
        (item) =>
          item.permission === "Owner" ||
          item.permission === "Admin" ||
          item.permission === "Project Manager"
      )
    );
  }, [projectsList]);

  const getReadEmailData = async () => {
    setLoading(true);
    await getReadEmail()
      .then((res) => {
        console.log(res, res.data, "res-read-emil");
        if (res.data.error === "Authorization required") {
          setAlertTitle(res?.data?.alert_title);
          setAlertText(res?.data?.error);
          setAuthorizationLink(res?.data?.alert_text);
          openReadEmailModal();
        } else {
          setAlertTitle(res?.data?.alert_title);
          setAlertText(res?.data?.success);
          // setAuthorizationLink(res?.data?.alert_text);
          openReadEmailModal();
        }
      })
      .catch((err) => {
        console.log(err, "err-read-email");
        if (err?.message) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message,
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const openEmailAutorizationLink = () => {
    window.location.href = authorizationLink;
    // window.open(authorizationLink, "_blank");
  };

  const onAddFilterSubmit = async (data) => {
    // console.log(data, "data in Add Filter");
    const project_name = projectsList.find(
      (item) => item._id === data.project_id
    ).name;
    await addFilterSetting({ ...data, project_name })
      .then((res) => {
        console.log(res, res.data, "res-read-emil");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
          return;
        }
        if (res.data.success === 1) {
          toast.success(`Filter was added`, {
            theme: "colored",
          });
          const updatedFilters = [
            {
              ...data,
              _id: res.data.new_filter_id.toString(),
              project_name,
            },
            ...filters,
          ];
          dispatch(updateAppSettingFilters(updatedFilters));
        }
      })
      .catch((err) => {
        console.log(err, "err-read-email");
        if (err?.message) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message,
            })
          );
        }
      })
      .finally(() => reset());
  };

  const onDeleteFilterClicked = async (filterId) => {
    await deleteFilterSetting({
      filter_setting_id: filterId,
    })
      .then((res) => {
        console.log(res, res.data, "res-read-emil");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
          return;
        }
        if (res.data.success === 1) {
          const updatedFilters = filters.filter((f) => f._id !== filterId);
          dispatch(updateAppSettingFilters(updatedFilters));
        }
      })
      .catch((err) => {
        console.log(err, "err-read-email");
        if (err?.message) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message,
            })
          );
        }
      });
  };
  // console.log(filters, "projectList-filters");
  return (
    <>
      <Modal
        show={showSetting}
        onHide={onHide}
        size="lg"
        centered
        backdrop={true}
        className="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title className="ms-3">Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="account">
            <Tab eventKey="account" title="Account Settings">
              <div className="d-flex align-items-center mt-3 ms-3">
                <span>Gmail integration:</span>
                <Button className="ms-2" onClick={getReadEmailData}>
                  Access Mailbox
                </Button>
              </div>
              {loading && (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "50px" }}
                >
                  <Spinner animation="border" />
                </div>
              )}
            </Tab>
            <Tab eventKey="filters" title="Filters">
              <Form
                onSubmit={handleSubmit(onAddFilterSubmit)}
                className="addFilterForm mt-3"
              >
                <div className="emailSubjectBlock">
                  <Form.Group style={{ flex: 1, marginRight: "8px" }}>
                    <Form.Control
                      placeholder="From Email..."
                      {...register("filter_email_address")}
                    />
                  </Form.Group>

                  <Form.Group style={{ flex: 1, marginRight: "8px" }}>
                    <Form.Control
                      placeholder="Subject contains..."
                      {...register("filter_subject_text")}
                    />
                  </Form.Group>
                  <Form.Group controlId="selectId" style={{ flex: 1 }}>
                    <Form.Select
                      {...register("project_id")}
                      isInvalid={!!errors?.project_id}
                      id={
                        watch("project_id") === undefined ||
                        watch("project_id") === ""
                          ? "readOnlyPlaceholderId"
                          : "selectId"
                      }
                      defaultValue=""
                    >
                      <option value="" hidden>
                        Set project
                      </option>
                      {!!filterProjects.length &&
                        filterProjects?.map((option) => (
                          <option value={option._id} key={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors?.project_id?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="submitBtn ms-2">
                  <Button type="submit" className="addFilterBtn">
                    Add Filter
                  </Button>
                </div>
              </Form>
              <div
                className="mt-4"
                style={{ maxHeight: "220px", overflowY: "auto" }}
              >
                <Table bordered hover size="sm">
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>From Email</th>
                      <th style={{ width: "120px" }}>Filter Subject</th>
                      <th>Set Project</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortArrayAlphabetically(filters)?.map((filter, i) => (
                      <tr key={filter._id}>
                        <td>{i + 1}</td>
                        <td>{filter.filter_email_address}</td>
                        <td>{filter.filter_subject_text}</td>
                        <td>{filter.project_name}</td>
                        <th className="text-center">
                          <Trash
                            style={{ cursor: "pointer" }}
                            className="text-danger"
                            onClick={() => onDeleteFilterClicked(filter._id)}
                          />
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      <ReadEmailModal
        isShow={isReadEmailShow}
        modalHeader={alertTitle}
        handleClose={closeReadEmailModal}
        size="md"
      >
        <div className="d-flex flex-column p-3 justify-content-center align-items-center">
          <div className="d-flex mb-3 align-items-center">
            {authorizationLink && (
              <AiOutlineWarning
                className="text-danger me-2"
                style={{ fontSize: "16px" }}
              />
            )}
            <span>{alertText}</span>
          </div>
          {authorizationLink ? (
            <Button onClick={openEmailAutorizationLink}>Authorization</Button>
          ) : (
            <Button onClick={closeReadEmailModal}>OK</Button>
          )}
        </div>
      </ReadEmailModal>
    </>
  );
};

SettingsModal.propTypes = {
  showSetting: PropTypes.bool,
  onHide: PropTypes.func,
};
export default SettingsModal;
