import axios from "./axios";

export const getTask = (taskId) => {
  const params = new URLSearchParams();
  params.append("task_id", taskId);

  return axios.get("app/get_task", {
    params,
  });
};

export const addTask = (payload) => axios.post("app/add_task", payload);

export const editTask = (payload) => axios.patch("app/edit_task", payload);

export const deleteTask = (payload) =>
  axios.delete("app/delete_task", { data: payload });

export const getTaskMessages = (taskId, page=null, limit=null) => {
  const params = new URLSearchParams();
  params.append("task_id", taskId);
  page && params.append("page", page);
  limit && params.append("limit", limit);


  return axios.get("app/get_task_messages", {
    params,
  });
};

export const addTaskMessage = (payload) =>
  axios.post("app/add_task_message", payload);

export const deleteTaskMessage = (payload) =>
  axios.delete("app/delete_task_message", { data: payload });

export const addTaskTagLink = (payload) =>
  axios.post("app/add_task_tag_link", payload);

export const deleteTaskTagLink = (payload) =>
  axios.delete("app/delete_task_tag_link", { data: payload });

export const addSubTask = (payload) => axios.post("app/add_sub_task", payload);
// export const editSubTask = payload => axios.patch('app/edit_sub_task', payload);
