import React from "react";
import { Image } from "react-bootstrap";
import PulseLoader from "react-spinners/PulseLoader";

const LoadingScreen = () => {
  return (
    <div className="vh-100 bg-loading">
      <div className="d-flex justify-content-center align-items-center h-75">
        <div
          className="d-flex flex-column align-itens-center justify-content-center"
          style={{ maxWidth: "350px" }}
        >
          <div>
            <Image src="/imgs/logo.png" width="100%" />
          </div>
          <div className="d-flex w-100 justify-content-center">
            <PulseLoader size="12px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
