import React, { useState } from "react";
import "./navbar.css";
import "./../../App.css";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Image, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { GearFill } from "react-bootstrap-icons";
import AlertModal from "components/AlertModal";
import SettingsModal from "../SettingsModal";
import { setError, setIsProjectChosen } from "redux/workspaceSlice";
import WorkspaceDropdown from "layouts/Navbar/WorkspaceDropdown";
import ProjectsDropdown from "layouts/Navbar/ProjectsDropdown";
import ReportsDropdown from "layouts/Navbar/ReportsDropdown";
import WorkspaceDetail from "features/workspace/WorkspaceDetail";
import SearchInput from "components/SearchInput";
import MobileNavbar from "./MobileNavbar";
import useMobileWindow from "hooks/useMobileWindow";
import LogoutDropdown from "layouts/Navbar//LogoutDropdown";

const NavbarPanel = () => {
  let location = useLocation();
  const isMobile = useMobileWindow();

  const error = useSelector((state) => state.workspaces.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSetting, setShowSetting] = useState(false);
  const [show, setShow] = useState(false);

  const onModalClosed = () => {
    dispatch(
      setError({
        isShow: false,
        title: "",
        message: "",
      })
    );
  };

  // const onAlertDropdownToggled = async () => {
  //   if (!isAlerts) return;
  //   // formData.append("api_method", "mark_alerts_as_seen");
  //   try {
  //     const response = await getMarkAlertsAsSeen();
  //     if (response.data.success === 1) {
  //       dispatch(
  //         setIsAlerts({
  //           isAlerts: false,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         setError({
  //           isShow: true,
  //           title: "Error",
  //           message: response.data.message,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       setError({
  //         isShow: true,
  //         title: "Error",
  //         message: err.toString(),
  //       })
  //     );
  //   }
  // };

  // const onMessageDropdownToggled = async () => {
  //   if (!isMessages) return;
  //   // formData.append("api_method", "mark_messages_as_seen");
  //   try {
  //     const response = await getMarkMessagesAsSeen();
  //     if (response.data.success === 1) {
  //       dispatch(
  //         setIsMessages({
  //           isMessages: false,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         setError({
  //           isShow: true,
  //           title: "Error",
  //           message: response.data.message,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       setError({
  //         isShow: true,
  //         title: "Error",
  //         message: err.toString(),
  //       })
  //     );
  //   }
  // };
  return (
    <>
      {isMobile ? (
        <MobileNavbar setShow={setShow} />
      ) : (
        <Navbar
          expand="lg"
          className="d-flex justify-content-between align-items-center flex-wrap px-5"
        >
          <Navbar.Brand
            style={{ cursor: "pointer" }}
            className="navItem"
            onClick={() => navigate("/dashboard")}
          >
            <Image src="/imgs/logo.png" style={{ maxHeight: 50 }}></Image>
          </Navbar.Brand>

          <div className="navItem">
            {/* <Button
              className={`${
                location.pathname === "/dashboard"
                  ? styles.activeDropdown
                  : styles.selectWorkspace
              }`}
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </Button> */}
            <WorkspaceDropdown />
            <ProjectsDropdown />
            <Button
              className={`${
                location.pathname === "/members"
                  ? styles.activeDropdown
                  : styles.selectWorkspace
              }`}
              onClick={() => {
                navigate("/members");
                dispatch(setIsProjectChosen(false));
              }}
            >
              My Team
            </Button>
            <ReportsDropdown />
          </div>

          <div className="navItem">
            <Button
              style={{ minWidth: "160px", height: "37.6px" }}
              className={`me-2 ${styles.activeButton}`}
              onClick={() => {
                setShow(true);
                document.body.classList.remove("overflow-auto");
                document.body.classList.add("overflow-hidden");
              }}
            >
              New Workspace
            </Button>
            <SearchInput />
            <div className="top-nav-icon px-2" role="button">
              <GearFill
                style={{ fontSize: "1.25rem" }}
                className="text-primary"
                onClick={() => {
                  setShowSetting(true);
                }}
              />
            </div>
            <LogoutDropdown />
          </div>
        </Navbar>
      )}
      {show && <WorkspaceDetail onClose={() => setShow(false)} id={null} />}

      <AlertModal
        isShow={error.isShow}
        title={error.title}
        message={error.message}
        onClose={onModalClosed}
      />
      <SettingsModal
        showSetting={showSetting}
        onHide={() => setShowSetting(false)}
      />
    </>
  );
};

export default NavbarPanel;
