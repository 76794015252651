export const server_domain = process.env.REACT_APP_API_URL;

export const ACCOUNT_URL = "https://account2.task925.com/";

export const WORKSPACE_MENUITEMS = [
  { label: "Edit Workspace", link: "" },
  { label: "Delete Workspace", link: "" },
];

export const PROJECT_MENUITEMS = [
  { label: "Edit Project", link: "" },
  { label: "Delete Project", link: "" },
];
export const PM_PROJECT_MENUITEMS = [
  { label: "Edit Project", link: "" },
];
export const TASK_MENUITEMS = [
  { label: "Delete Task", link: "" },
  { label: "New Sub-task", link: "" },
];
export const PM_TASK_MENUITEMS = [
  { label: "New Sub-task", link: "" }
];

