import axios from "./axios";

export const getAgileSections = (workspaceId, projectId) => {
  const params = new URLSearchParams();
  if (workspaceId) params.append("workspace_id", workspaceId);
  if (projectId) params.append("project_id", projectId);

  return axios.get("app/get_agile_sections", {
    params,
  });
};

export const editAgileSections = (payload) =>
  axios.patch("app/edit_agile_sections", payload);

export const addAgileSection = (payload) =>
  axios.post("app/add_agile_section", payload);

export const editAgileSection = (payload) =>
  axios.patch("app/edit_agile_section", payload);

export const deleteAgileSection = (payload) =>
  axios.delete("app/delete_agile_section", { data: payload });

export const getAgileDoc = (workspaceId, projectId) => {
  const params = new URLSearchParams();
  if (workspaceId) params.append("workspace_id", workspaceId);
  if (projectId) params.append("project_id", projectId);

  return axios.get("app/get_agile_doc", {
    params,
  });
};

export const addAgileDoc = (payload) =>
  axios.post("app/add_agile_doc", payload);

export const editAgileDoc = (payload) =>
  axios.patch("app/edit_agile_doc", payload);

export const deleteAgileDocFile = (agileDocId, attachmentFile) =>
  axios.delete(
    `module/bb_t925_agile_docs/${agileDocId}/files/${attachmentFile}`
  );

export const deleteAgileDocFileName = (agileDocId, payload) =>
  axios.patch(`module/bb_t925_agile_docs/${agileDocId}`, payload);
