import axios from "./axios";

export const getParticipants = (workspaceId, projectId) => {
  const params = new URLSearchParams();
  if (workspaceId) params.append("workspace_id", workspaceId);
  if (projectId) params.append("project_id", projectId);

  return axios.get("app/get_participants", {
    params,
  });
};

export const addParticipant = (payload) =>
  axios.post("app/add_participant", payload);

export const editParticipant = (payload) =>
  axios.patch("app/edit_participant", payload);

export const deleteParticipant = (payload) =>
  axios.delete("app/delete_participant", { data: payload });
