import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "features/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button /*, Collapse*/ } from "react-bootstrap";
import ButtonSpinner from "components/ButtonSpinner";
import DocumentsLinks from "components/DocumentsLinks";
import Timeline from "components/ChronoTimeline";
import FieldsListView from "components/FieldListView";
import TagMultiSelect from "components/TagMultiSelect";
import LoadingTimeline from "components/LoadingTimeline";
import Message from "./Message";
import Status from "./Status";
import { getParticipants } from "API/participants";
import { getTask, deleteTask, addSubTask, editTask } from "API/tasks";
import { setIsUpdateProject } from "redux/projectSlice";
import ConfirmModal from "components/ConfirmModal";
import { setError } from "redux/workspaceSlice";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import compareAsc from "date-fns/compareAsc";
import { formatDate, deepEqualObj } from "services/helpers";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import DraftEditor from "components/DraftEditor";
import { isJSONRegExp } from "services/helpers";
import useTabletWindow from "hooks/useTabletWindow";
import { dispatcOpenTaskLoading } from "redux/taskSlice";
import useMobileWindow from "hooks/useMobileWindow";
import { parseEmailData, loadContent, saveContent } from "./helpers";
import MultiSelect from "components/MultiSelect";

const SubTaskDetail = ({ onClose, id, taskId }) => {
  registerLocale("en-US", enUS);
  const isTablet = useTabletWindow();
  const isMobile = useMobileWindow();

  const project = useSelector((state) => state.project.project);
  const { permission, _id: projectId } = project;
  const dispatch = useDispatch();
  const today = formatDate(new Date());

  const [name, setName] = useState("");
  const [isSubTask, setIsSubTask] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [internalDescription, setInternalDescription] = useState(
    EditorState.createEmpty()
  );
  const [owner, setOwner] = useState(0);
  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(today);
  const [deadline, setDeadline] = useState(today);
  const [taskFields, setTaskFields] = useState([]);
  const [taskTags, setTaskTags] = useState([]);
  const [taskTagsList, setTaskTagsList] = useState([]);

  const [loggerName, setLoggerName] = useState("");
  const [addedDate, setAddedDate] = useState("");
  const [closedDate, setClosedDate] = useState("");
  const [isTaskClosed, setIsTaskClosed] = useState(false);
  const [isActioned, setIsActioned] = useState();
  const [observers, setObservers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [actionerIds, setActionersIds] = useState([]);
  const [fullParticipantsData, setFullParticipantsData] = useState([]);
  const [selecetedActioners, setSelecetedActioners] = useState([]);

  const [timelines, setTimelines] = useState([]);
  const [currentSubTaskId, setCurrentSubTaskId] = useState(null);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [errorName, setErrorName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDocumentsLinks, setIsDocumentsLinks] = useState(true);
  const [isSpinnerLoad, setIsSpinnerLoad] = useState(false);
  const [initData, setInitData] = useState({});
  const [taskProjectId, setTaskProjectId] = useState("");
  const [taskPermission, setTaskPermission] = useState(null);

  useEffect(() => {
    if (id !== null) {
      getInitSubTaskData(id);
      setCurrentSubTaskId(id);
    }
  }, [id]);

  const getInitSubTaskData = async (id) => {
    // formData.append("api_method", "get_task");
    // formData.append("task_id", id);
    try {
      setLoading(true);
      const response = await getTask(id);
      if (response.data.success === 1) {
        const task = response.data.task;
        // console.log(task, "subtask");
        setTaskProjectId(task.projectISbb_t925_projectsID);
        setName(task.name);
        setTaskPermission(task.permission);
        setIsSubTask(Boolean(task.parent_task_id));
        setTimelines(task.timeline);
        // const parsedDescriptionData =
        //   task.detailsISsmallplaintextbox !== null &&
        //   isJSONRegExp(task.detailsISsmallplaintextbox)
        //     ? EditorState.createWithContent(
        //         convertFromRaw(JSON.parse(task.detailsISsmallplaintextbox))
        //       )
        //     : task.detailsISsmallplaintextbox !== null
        //     ? EditorState.createWithContent(
        //         ContentState.createFromText(task.detailsISsmallplaintextbox)
        //       )
        //     : EditorState.createEmpty();
        //new parsedDescriptionData with loadContent,allowing us to parse data with <-- characters
        const parsedDescriptionData =
          task.detailsISsmallplaintextbox !== null &&
          isJSONRegExp(task.detailsISsmallplaintextbox)
            ? loadContent(task.detailsISsmallplaintextbox)
            : task.detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(
                    parseEmailData(task.detailsISsmallplaintextbox)
                  )
                )
              )
            : EditorState.createEmpty();
        setDescription(parsedDescriptionData);

        // const parsedInternalDescription =
        //   task.internal_detailsISsmallplaintextbox !== null &&
        //   isJSONRegExp(task.internal_detailsISsmallplaintextbox)
        //     ? EditorState.createWithContent(
        //         convertFromRaw(
        //           JSON.parse(task.internal_detailsISsmallplaintextbox)
        //         )
        //       )
        //     : task.internal_detailsISsmallplaintextbox !== null
        //     ? EditorState.createWithContent(
        //         ContentState.createFromText(
        //           task.internal_detailsISsmallplaintextbox
        //         )
        //       )
        //     : EditorState.createEmpty();
        const parsedInternalDescription =
          task.internal_detailsISsmallplaintextbox !== null &&
          isJSONRegExp(task.internal_detailsISsmallplaintextbox)
            ? loadContent(task.internal_detailsISsmallplaintextbox)
            : task.internal_detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(
                    parseEmailData(task.internal_detailsISsmallplaintextbox)
                  )
                )
              )
            : EditorState.createEmpty();
        setInternalDescription(parsedInternalDescription);

        setDateFrom(
          task.date_from === null ? today : formatDate(task.date_from)
        );
        setDateTo(task.date_to === null ? today : formatDate(task.date_to));
        setDeadline(
          task.date_deadline === null ? "" : formatDate(task.date_deadline)
        );
        if (task.actioners.length > 0) {
          const actionerNames = task.actioners.map((item) => item.name);
          setSelecetedActioners(actionerNames);
          setActionersIds(task.actioners.map((item) => item._id));
        }
        setOwner(task.task_owner_id === null ? "0" : task.task_owner_id);
        setIsTaskClosed(
          task.task_closedYN === null
            ? false
            : task.task_closedYN === "1"
            ? true
            : false
        );
        setIsActioned(!!parseFloat(task.task_actionedYN));

        const taskFieldsList = task.task_fields_list;
        setTaskFields(taskFieldsList === null ? [] : taskFieldsList.reverse());
        const taskTagsList = task.task_tags_list;
        setTaskTagsList(taskTagsList === null ? [] : taskTagsList);
        setTaskTags(task.task_tags === null ? [] : task.task_tags);

        setLoggerName(task.logger_name === null ? "" : task.logger_name);
        setAddedDate(
          task._dateadded === null ? "" : task._dateadded.split(" ")[0]
        );
        setClosedDate(
          task.datetime_closed === null
            ? ""
            : task.datetime_closed.split(" ")[0]
        );

        const taskInitData = {
          task_id: id,
          name: task.name,
          detailsISsmallplaintextbox:
            task.detailsISsmallplaintextbox === null
              ? EditorState.createEmpty()
              : parsedDescriptionData,
          internal_detailsISsmallplaintextbox:
            task.internal_detailsISsmallplaintextbox === null
              ? EditorState.createEmpty()
              : parsedInternalDescription,
          task_owner_id: task.task_owner_id === null ? "0" : task.task_owner_id,
          date_from: task.date_from
            ? formatDate(task.date_from)
            : task.date_from,
          date_to: task.date_to ? formatDate(task.date_to) : task.date_to,
          date_deadline: task.date_deadline
            ? formatDate(task.date_deadline)
            : "",
          task_closedYN:
            task.task_closedYN === null
              ? 0
              : task.task_closedYN === "1"
              ? 1
              : 0,
          task_actionedYN: +task.task_actionedYN === 1 ? 1 : 0,
        };
        setInitData(taskInitData);
        await setParticipantsAsync(task.projectISbb_t925_projectsID);
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
      setLoading(false);
      dispatch(dispatcOpenTaskLoading(false));
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const setParticipantsAsync = async (taskProjectId) => {
    await getParticipants(null, projectId !== "0" ? projectId : taskProjectId)
      .then((res) => {
        if (res?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
        }
        // console.log(res.data.list, "participants in Task Detail");
        const participantsData = res?.data?.list;
        setObservers(
          participantsData.filter(
            (item) => item.type === "Observer" && item.account_id !== null
          )
        );
        const fullArrParticipants = participantsData?.filter((item) => item.type !== "Observer" && item.account_id !== null);
        setParticipants(fullArrParticipants.map((el) => el.account_name));
        setFullParticipantsData(fullArrParticipants);
      })
      .catch((err) => {
        console.log(err, "participanrs-task-detail-err");
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err.response.data.error,
          })
        );
      });
  };

  const onDoneSubTask = async () => {
    if (loading) {
      onClose();
      document.body.classList.add("overflow-auto");
      document.body.classList.remove("overflow-hidden");
    }
    if (currentSubTaskId && name === "") {
      setErrorName("The Title field is required");
      return;
    }
    const formData = {
      task_id: currentSubTaskId,
      name: name,
      detailsISsmallplaintextbox: saveContent(description),
      internal_detailsISsmallplaintextbox: saveContent(internalDescription),
      task_owner_id: owner,
      actioner_id: actionerIds,
      date_from: dateFrom,
      date_to: dateTo,
      date_deadline: deadline,
      task_closedYN: isTaskClosed ? 1 : 0,
      task_actionedYN: isActioned ? 1 : 0,
    };
    const changedFormData = { ...formData };
    changedFormData.detailsISsmallplaintextbox = description;
    changedFormData.internal_detailsISsmallplaintextbox = internalDescription;
    const isFormDataChanged = !deepEqualObj(initData, changedFormData);

    if (currentSubTaskId && isFormDataChanged) {
      setIsSpinnerLoad(true);
      await editTask(formData)
        .then((res) => {
          console.log(res.data, "edit-subTask");
          if (res.data.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
            return;
          }
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "edit-project-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error,
              })
            );
          }
        });
    }
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
    // document.body.style.overflow = "auto";
  };

  const addNewSubTask = async () => {
    if (name === "") {
      setErrorName("The Title field is required");
      return;
    }
    const formData = {
      name: name,
      task_id: taskId,
      date_from: dateFrom,
      date_to: dateTo,
      date_deadline: deadline,
    };
    if (currentSubTaskId === null) {
      await addSubTask(formData)
        .then((res) => {
          // console.log(res, res.data, "add-subtask");
          if (res?.data?.error) {
            console.log(res?.data?.error, "add-subtask-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res?.data?.error,
              })
            );
          }
          const newSubTaskId = res.data.new_task_id.toString();
          setCurrentSubTaskId(newSubTaskId);
          getInitSubTaskData(newSubTaskId);
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err?.response?.status) {
            console.log(err, err?.response?.status, "add-workspace-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error,
              })
            );
          }
        });
    }
    // if (currentSubTaskId === null) {
    //   formData.append("api_method", "add_sub_task");
    //   formData.append("task_id", taskId);
    // } else {
    //   formData.append("api_method", "edit_task");
    //   formData.append("task_id", currentSubTaskId);
    // }
  };

  const onDateFromChanged = (value) => {
    if (dateTo) {
      const compareResult = compareAsc(value, new Date(dateTo));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "DateFrom field should be before DateTo field!",
          })
        );
        return;
      }
    }
    setDateFrom(formatDate(value));
  };

  const onDateToChanged = (value) => {
    if (dateFrom) {
      const compareResult = compareAsc(new Date(dateFrom), value);
      // console.log(compareResult, "compareresult-subtask-to")
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "DateTo field should be after DateFrom field!",
          })
        );
        return;
      }
    }
    setDateTo(formatDate(value));
  };

  const onDateDeadlineChanged = (value) => {
    if (dateTo) {
      const compareResult = compareAsc(new Date(dateTo), value);
      // console.log(compareResult, "compareresult-subtask-to");
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "Deadline field should be after Date To field!",
          })
        );
        return;
      }
    }
    setDeadline(formatDate(value));
  };

  const onDeleteClicked = async () => {
    // formData.append("api_method", "delete_task");
    // formData.append("task_id", currentSubTaskId);
    await deleteTask({ task_id: currentSubTaskId })
      .then((res) => {
        if (res?.data?.error) {
          console.log(res?.data?.error, "delete-subtask-err-200");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res?.data?.error,
            })
          );
          return;
        }
        dispatch(setIsUpdateProject(true));
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, err.response.status, "delete-task-subtask-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error,
            })
          );
        }
      })
      .finally(() => {
        setIsDeleteConfirm(false);
        onClose();
        document.body.classList.add("overflow-auto");
        document.body.classList.remove("overflow-hidden");
      });
  };

  const onActionerToChanged = (data) => {
    setSelecetedActioners(data.map((el) => el.value));
    const accountIds = fullParticipantsData
      .filter((item) => data.some((option) => option.value === item.account_name))
      .map((item) => item.account_id);
    // console.log(accountIds, "accountIds");
    setActionersIds(accountIds);
  };

  const isPermission =
    permission === "Owner" ||
    permission === "Admin" ||
    permission === "Project Manager";
  // ||(permission === "Observer" && taskPermission === "Owner");

  const isDisable = currentSubTaskId === null || !isPermission;

  const title =
    id === null
      ? "New Sub-task"
      : isPermission
      ? `Edit Sub-task TK(${id})`
      : `View Sub-task TK(${id})`;

  return (
    <>
      <div className="modal-container" onClick={onDoneSubTask}>
        <div
          className="modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Form>
            <div className="formWrapper" style={{ minHeight: "100%" }}>
              <div className="px-4 bg-white mainBlock">
                <div className="bg-white py-4 d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-bold">{title}</span>
                  <div className="d-flex align-items-center">
                    <ButtonSpinner
                      onClick={onDoneSubTask}
                      loading={isSpinnerLoad}
                    >
                      Done
                    </ButtonSpinner>
                    {(isMobile || isTablet) && isPermission && (
                      <Button
                        variant="outline-danger"
                        className="ms-2 btn-sm"
                        onClick={() => setIsDeleteConfirm(true)}
                        style={{ float: "right" }}
                        disabled={isDisable}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
                <div className="nameBlock mb-5">
                  <Form.Group className="nameInput">
                    <Form.Label>Title</Form.Label>
                    {loading ? (
                      <div className="animated-background mx-2"></div>
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Task title"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={addNewSubTask}
                        className={errorName ? "border-danger" : ""}
                        disabled={currentSubTaskId && !isPermission}
                        style={{
                          backgroundColor: !isPermission ? "transparent" : "",
                        }}
                      />
                    )}
                    {errorName && (
                      <div className="text-danger" style={{ fontSize: "12px" }}>
                        {errorName}
                      </div>
                    )}
                  </Form.Group>
                </div>
                <Form.Group className="mb-5" controlId="taskDescription">
                  <Form.Label>Description</Form.Label>
                  {loading ? (
                    <div className="">
                      <div className="animated-background mx-2 my-1"></div>
                      <div className="animated-background mx-2 my-1"></div>
                      <div className="animated-background mx-2 my-1 w-50"></div>
                    </div>
                  ) : (
                    <DraftEditor
                      value={description}
                      onDescriptionChange={(data) => setDescription(data)}
                      isDisable={currentSubTaskId === null}
                      isReadOnly={!isPermission}
                    />
                  )}
                </Form.Group>

                {(permission !== "Observer" ||
                  (currentSubTaskId === null && permission === "Observer") ||
                  (taskPermission === "Owner" &&
                    permission === "Observer")) && (
                  <Form.Group
                    className="mb-5"
                    controlId="taskInternalDescription"
                  >
                    <>
                      <Form.Label>Internal Description</Form.Label>
                      {loading ? (
                        <div className="">
                          <div className="animated-background mx-2 my-1"></div>
                          <div className="animated-background mx-2 my-1"></div>
                          <div className="animated-background mx-2 my-1 w-50"></div>
                        </div>
                      ) : (
                        <DraftEditor
                          value={internalDescription}
                          onDescriptionChange={(data) =>
                            setInternalDescription(data)
                          }
                          isDisable={currentSubTaskId === null}
                          isReadOnly={!isPermission}
                        />
                      )}
                    </>
                  </Form.Group>
                )}

                <div className="mb-5">
                  {loading ? (
                    <>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Owner/Customer</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Actioners</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                    </>
                  ) : (
                    <div className="selectsBlock">
                      <Form.Group className="selectItem me-3">
                        <Form.Label>Owner/Customer</Form.Label>
                        <Form.Select
                          value={owner}
                          onChange={(e) => setOwner(e.target.value)}
                          id={
                            owner === "0" || owner === ""
                              ? "readOnlyPlaceholderId"
                              : "selectId"
                          }
                          style={
                            !isPermission && currentSubTaskId !== null
                              ? { backgroundColor: "transparent" }
                              : {}
                          }
                          disabled={isDisable}
                          required
                        >
                          <option value="" hidden>
                            Owner/Customer
                          </option>
                          <option value="">None</option>
                          {observers.map((participant) => (
                            <option
                              value={participant._id}
                              key={participant._id}
                            >
                              {participant.account_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="selectItem">
                      <Form.Label>Actioners</Form.Label>
                        <MultiSelect
                          onChange={(data) => onActionerToChanged(data)}
                          options={participants}
                          selected={selecetedActioners}
                          placeholder="Actioner"
                          disabled={isDisable}
                          required
                          style={{ height: "36px" }}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>

                <div className="datesBlock mb-5 ">
                  <Form.Group
                    className="dateFrom mb-2 d-flex flex-column"
                    style={{ border: "none" }}
                  >
                    <Form.Label>Date From</Form.Label>
                    <DatePicker
                      selected={dateFrom ? new Date(dateFrom) : new Date()}
                      value={dateFrom}
                      onChange={onDateFromChanged}
                      disabled={isDisable}
                      // onCalendarClose={addEditSubTask}
                      formatWeekDay={(day) => day.slice(0, 3)}
                      locale="en-US"
                      placeholderText="Date from"
                      customInput={
                        <DatePickerCustomInput
                          isPermission={taskPermission !== null}
                        />
                      }
                      showYearDropdown
                    />
                  </Form.Group>

                  <Form.Group className="dateTo mb-2 d-flex flex-column">
                    <Form.Label>Date to</Form.Label>
                    <DatePicker
                      selected={dateTo ? new Date(dateTo) : new Date()}
                      value={dateTo}
                      onChange={onDateToChanged}
                      disabled={isDisable}
                      // onCalendarClose={addEditSubTask}
                      formatWeekDay={(day) => day.slice(0, 3)}
                      locale="en-US"
                      placeholderText="Date to"
                      customInput={
                        <DatePickerCustomInput
                          isPermission={taskPermission !== null}
                        />
                      }
                      showYearDropdown
                    />
                  </Form.Group>
                  <Form.Group className="dateDeadline mb-2 d-flex flex-column">
                    <Form.Label>Deadline</Form.Label>
                    <DatePicker
                      selected={deadline ? new Date(deadline) : new Date()}
                      value={deadline}
                      onChange={onDateDeadlineChanged}
                      disabled={isDisable}
                      // onCalendarClose={addEditSubTask}
                      formatWeekDay={(day) => day.slice(0, 3)}
                      locale="en-US"
                      placeholderText="Deadline"
                      customInput={
                        <DatePickerCustomInput
                          isPermission={taskPermission !== null}
                        />
                      }
                      showYearDropdown
                    />
                  </Form.Group>
                </div>

                {loading ? (
                  <div className="animated-background mx-2"></div>
                ) : taskTags.length > 0 || isPermission ? (
                  <Form.Group className={taskFields.length !== 0 ? "" : "mb-5"}>
                    <Form.Label>Tags</Form.Label>
                    <TagMultiSelect
                      taskId={currentSubTaskId}
                      projectId={projectId !== "0" ? projectId : taskProjectId}
                      taskTagsList={taskTagsList}
                      savedTags={taskTags}
                      disabled={
                        currentSubTaskId === null ||
                        taskPermission === "Actioner" ||
                        taskPermission === "Observer"
                      }
                      readOnly={
                        taskPermission === "Actioner" ||
                        taskPermission === "Observer"
                      }
                    />
                  </Form.Group>
                ) : null}

                {taskFields.length > 0 && <div className="task-hr my-4"></div>}
                <Form.Group>
                  {taskFields.length > 0 && (
                    <>
                      <Form.Label>Fields</Form.Label>
                      <FieldsListView
                        taskId={currentSubTaskId}
                        projectId={
                          projectId !== "0" ? projectId : taskProjectId
                        }
                        savedFields={taskFields}
                        disabled={currentSubTaskId === null}
                        isReadOnly={taskPermission === "Observer"}
                        taskPermission={taskPermission}
                      />
                    </>
                  )}
                </Form.Group>
                {taskFields.length > 0 && (
                  <div className="task-hr mt-2 mb-4"></div>
                )}

                <div className="switchBlock mb-5">
                  <Form.Group className="d-flex justify-content-between align-items-center fieldItem">
                    <Form.Label>Task Closed/Completed</Form.Label>
                    <label className="switch ms-3">
                      <input
                        type="checkbox"
                        checked={isTaskClosed}
                        value={isTaskClosed}
                        onChange={(e) => setIsTaskClosed(e.target.checked)}
                        disabled={isDisable}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-between align-items-center fieldItemPriority">
                    <Form.Label>Actioned</Form.Label>
                    <label className="switch ms-3">
                      <input
                        type="checkbox"
                        checked={isActioned}
                        value={isActioned}
                        onChange={(e) => setIsActioned(e.target.checked)}
                        disabled={
                          currentSubTaskId === null || permission === "Observer"
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </Form.Group>
                </div>

                <Status
                  taskId={currentSubTaskId}
                  loggerName={loggerName}
                  addedDate={addedDate}
                  closedDate={closedDate}
                  permission={permission}
                  isSubTask={isSubTask}
                />

                <Form.Group className="w-100 my-5">
                  <Form.Label>Notes & Messages</Form.Label>
                  <Message taskId={currentSubTaskId} />
                </Form.Group>

                {(isMobile || isTablet) &&
                  (isPermission || isDocumentsLinks) && (
                    <Form.Group className="mb-5">
                      <Form.Label>Documents & Links</Form.Label>
                      <DocumentsLinks
                        isDisabled={currentSubTaskId === null}
                        taskId={currentSubTaskId}
                        setIsDocumentsLinks={setIsDocumentsLinks}
                        isPermission={isPermission}
                      />
                    </Form.Group>
                  )}

                {isTablet && !!timelines.length && (
                  <Form.Group className="mb-5 w-100 timelineHorizontal">
                    <Form.Label className="fs-16 mb-3">Timeline</Form.Label>
                    {loading ? (
                      <LoadingTimeline />
                    ) : (
                      <Timeline values={timelines} mode="HORIZONTAL" />
                    )}
                  </Form.Group>
                )}

                {/* {isPermission && (
                  <Button
                    variant="outline-danger"
                    className="mb-5 btn-sm"
                    onClick={() => setIsDeleteConform(true)}
                    disabled={isDisable}
                    style={{ float: "right" }}
                  >
                    Delete
                  </Button>
                )} */}
              </div>
              <div
                className="participants"
                style={{ width: "10px", backgroundColor: "gray", opacity: 0.7 }}
              ></div>
              <div
                className="px-4 pt-4 bg-white rounded participants"
                style={{ width: "31%" }}
              >
                {isPermission && (
                  <Button
                    variant="outline-danger"
                    className="mb-5 btn-sm"
                    onClick={() => setIsDeleteConfirm(true)}
                    style={{ float: "right" }}
                    disabled={isDisable}
                  >
                    Delete
                  </Button>
                )}
                {(isPermission || isDocumentsLinks) && (
                  <Form.Group className="my-5">
                    <Form.Label>Documents & Links</Form.Label>
                    <DocumentsLinks
                      isDisabled={currentSubTaskId === null}
                      taskId={currentSubTaskId}
                      setIsDocumentsLinks={setIsDocumentsLinks}
                      isPermission={isPermission}
                    />
                  </Form.Group>
                )}
                {/* {!isTablet && (
                  <Form.Group className="mb-3" controlId="taskNotes">
                    <Form.Label>Notes & Messages</Form.Label>
                    <Message taskId={currentSubTaskId} />
                  </Form.Group>
                )} */}
                {!!timelines.length && (
                  <Form.Group className="mb-3 w-100 timelineHorizontal">
                    <Form.Label className="mb-3">Timeline</Form.Label>
                    {loading ? (
                      <LoadingTimeline />
                    ) : (
                      <Timeline values={timelines} mode="VERTICAL" />
                    )}
                  </Form.Group>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
      {isDeleteConfirm && (
        <ConfirmModal
          isShow={isDeleteConfirm}
          title={"Delete Sub Task"}
          message={"Are you sure you want to delete this sub task?"}
          onDelete={onDeleteClicked}
          onClose={setIsDeleteConfirm}
        />
      )}
    </>
  );
};
SubTaskDetail.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  taskId: PropTypes.string,
};
export default SubTaskDetail;
