import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Spinner } from "react-bootstrap";
import "./Task.css";
import { HambugurMenuContext } from "services/contexts";
import { MdDragIndicator } from "react-icons/md";
import compareAsc from "date-fns/compareAsc";
import { RiFlag2Fill, RiFlag2Line } from "react-icons/ri";
import TaskStatusDropdown from "components/TaskStatusDropdown";
import { formatDate } from "services/helpers";
import { setError } from "redux/workspaceSlice";
import HambugerDropdown from "components/HambugerDropdown";
import { TASK_MENUITEMS } from "services/constants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { editTask } from "API/tasks";
import { editProject } from "API/projects";
import { v4 as uuidv4 } from "uuid";
import { setIsUpdateProject } from "redux/projectSlice";
import SubTasksList from "./SubTasksList";
import useMobileWindow from "hooks/useMobileWindow";

const TaskList = ({ tasks, projectId, isProjectLoading, tasksSelected, tasksTotal }) => {
  const { project } = useSelector((state) => state.project);
  const { permission } = project;
  const isMobile = useMobileWindow();

  // console.log(tasks, permission, "tasks-permission-TaskList");
  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";
  // || permission === "Observer";

  const dispatch = useDispatch();
  const onItemSelected = useContext(HambugurMenuContext);
  const _isMounted = useRef(false);
  const noTasksMessageForOwnerAndAdmin = 'You have no tasks yet on this project. Click "New Task" to add one.';
  const noTasksMessageForOther = "You have no tasks yet on this project.";
  const allTasksCompletedMessage = "Well done! You have completed all tasks on this project.";
  const [tasksList, setTasksList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _isMounted.current = true;
    setTasksList(tasks);
    return () => {
      _isMounted.current = false;
    };
  }, [tasks]);

  const onChangePriority = async (e, task) => {
    const formData = {
      task_id: task._id,
      name: task.name,
      priorityNUM: +task.priorityNUM ? 0 : 1
    };
    try {
      await editTask(formData)
        .then(() => {
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "edit-project-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error
              })
            );
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeTaskStatus = async (taskId, taskName, taskClosed) => {
    // console.log(task, "task-status");
    const formData = {
      task_id: taskId,
      name: taskName,
      task_closedYN: taskClosed === "1" ? 0 : 1
    };
    await editTask(formData)
      .then((res) => {
        console.log(res.data, "edit-task-status");
        if (res.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.error
            })
          );
          return;
        }
        dispatch(setIsUpdateProject(true));
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, err.response.status, "edit-project-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error
            })
          );
        }
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = reorder(tasksList, result.source.index, result.destination.index);
    // setTasksList(items);
    const formData = {
      project_id: projectId,
      task_order: items.map((item) => item._id)
    };
    // console.log(formData, "form Data after reorder");
    setLoading(true);
    await editProject(formData)
      .then((res) => {
        console.log(res?.data, "res-edit-project with task_order");
        if (res?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res?.data?.error
            })
          );
          return;
        }
        if (res?.data?.success === 1) {
          dispatch(setIsUpdateProject(true));
        }
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, "edit-project-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err?.message
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };
  // console.log(tasksList, "Tasks-List");
  return (
    <>
      <CircleSpinnerOverlay loading={loading} message="Saving..." color="#5e6e82" overlayColor="rgba(0,0,0,0.3)" />
      {tasksList.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div className="py-2 nameHeader"></div>
            <div className="d-flex">
              <div className="d-flex align-items-center py-2 tagsHeader fs-14">Tags</div>
              <div className="d-flex align-items-center py-2 fs-14 teamMemberHeader">Team Member</div>
              <div className="dateHeader">Due Date</div>
              <div className="priorityHeader">Priority</div>
              {isPermission && <div style={{ width: "56px" }}></div>}
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {tasksList.map((task, i) => (
                    <Draggable key={task._id} draggableId={task._id ? task._id : uuidv4()} index={i}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <>
                            <div
                              className={`d-flex align-items-center justify-content-between
                                ${
                                  task.task_closedYN === "1"
                                    ? "actioned-task"
                                    : compareAsc(new Date(), new Date(task.date_deadline)) > 0
                                    ? "overdue-task"
                                    : task.task_actionedYN === "1"
                                    ? "actioned-task"
                                    : "task-item"
                                }
                                `}
                            >
                              <div
                                className="d-flex align-items-center px-1 py-3 w-100"
                                onClick={() => {
                                  onItemSelected("Edit Task", task._id, projectId);
                                }}
                              >
                                <div className="d-flex align-items-center withStatusBlock w-100">
                                  {isPermission && (
                                    <div className="me-2 grab" {...provided.dragHandleProps}>
                                      <MdDragIndicator />
                                    </div>
                                  )}
                                  <div className="d-flex align-items-center p-0 me-2" onClick={(e) => e.stopPropagation()}>
                                    <TaskStatusDropdown
                                      taskClosed={task.task_closedYN}
                                      onChangeTaskStatus={() => onChangeTaskStatus(task._id, task.name, task.task_closedYN)}
                                      isDropMenu={
                                        task.permission === "Owner" || task.permission === "Admin" || task.permission === "Project Manager"
                                      }
                                    />
                                  </div>
                                  <div className="taskWrapper p-0">
                                    <div className="taskName ellipsis" title={`TK(${task._id}): ${task.name}`}>
                                      {`TK(${task._id}): ${task.name}`}
                                    </div>
                                    {!isMobile && (
                                      <div className="rightBlock">
                                        <div className="tagsBlock d-flex align-items-center">
                                          {task.task_tags.slice(0, 2).map(
                                            ({ tag_color, tag_name, task_tag_link_id }) =>
                                              tag_color && (
                                                <Badge
                                                  title={tag_name}
                                                  key={task_tag_link_id}
                                                  pill
                                                  bg={tag_color}
                                                  style={{
                                                    backgroundColor: tag_color,
                                                    height: "20.4px"
                                                  }}
                                                  className="me-2 tagBadge ellipsis"
                                                >
                                                  {tag_name}
                                                </Badge>
                                              )
                                          )}
                                        </div>
                                        <div className="d-flex flex-column teamMember">
                                          <div className="d-flex flex-column">
                                            {task.actioners.length > 0
                                              ? task.actioners.map((el) => {
                                                  return <span key={el._id}>{el.name}</span>;
                                                })
                                              : ""}
                                          </div>
                                          {task.task_actionedYN === "1" && (
                                            <Badge
                                              title="Actioned"
                                              pill
                                              bg="#A5C6F2"
                                              style={{
                                                backgroundColor: "#A5C6F2",
                                                height: "18px",
                                                width: "80px"
                                              }}
                                              className="me-2 tagBadge ellipsis"
                                            >
                                              Actioned
                                            </Badge>
                                          )}
                                        </div>
                                        <div className="datePriorityBlock">
                                          <div className="dateValue">
                                            {task.date_deadline === null ? "" : formatDate(task.date_deadline)}
                                          </div>
                                          <div
                                            className="priority"
                                            id="prority"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              isPermission && onChangePriority(e, task);
                                            }}
                                          >
                                            {+task.priorityNUM ? <RiFlag2Fill color="red" /> : <RiFlag2Line />}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {isPermission && (
                                <div className="hamburgerDropdownBlock">
                                  <HambugerDropdown menuItems={TASK_MENUITEMS} itemId={task._id} parentId={projectId} dropdownAlign="end" />
                                </div>
                              )}
                            </div>
                            <div className="task-hr"></div>
                            <SubTasksList
                              subTasks={task.sub_tasks}
                              parentTaskId={task._id}
                              projectId={projectId}
                              onChangeTaskStatus={onChangeTaskStatus}
                              isPermission={isPermission}
                            />
                          </>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <>
          {isProjectLoading ? (
            <div className="d-flex justify-content-center align-content-center w-100">
              <Spinner animation="border" />
            </div>
          ) : tasksSelected === 0 && tasksTotal > 0 ? (
            <div className="text-center">{allTasksCompletedMessage}</div>
          ) : isPermission ? (
            <div className="text-center">{noTasksMessageForOwnerAndAdmin}</div>
          ) : (
            <div className="text-center">{noTasksMessageForOther}</div>
          )}
        </>
      )}
    </>
  );
};

TaskList.propTypes = {
  projectId: PropTypes.string,
  tasks: PropTypes.array.isRequired,
  fetchProjectData: PropTypes.func,
  isProjectLoading: PropTypes.bool,
  tasksSelected: PropTypes.number.isRequired,
  tasksTotal: PropTypes.number.isRequired
};
export default TaskList;
