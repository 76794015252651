import React, { useState, useRef, useEffect } from "react";
import "./Dashboard.css";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Textarea from "components/Textarea";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { formatDateForServer } from "services/helpers";
import { addLeaveRequest, editLeaveRequest } from "API/calendar";
import { setError } from "redux/workspaceSlice";
import { toast } from "react-toastify";

const AddLeaveDaysForm = ({ onClose, leaveDayItem = null }) => {
  console.log(leaveDayItem, "leaveDayItem");
  registerLocale("en-US", enUS);
  const display_workspace = useSelector(
    (state) => state.workspaces.display_workspace
  );
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //   const [rangeDate, setRangeDate] = useState([]);
  const [rangeDateError, setRangeDateError] = useState("");
  const [formData, setFormData] = useState({
    workspace_id: display_workspace,
    date_start: "",
    date_end: "",
    textISsmallplaintextbox: "",
    action: "",
  });

  const textRef = useRef(null);
  const actions = ["pending", "approved", "rejected"];

  useEffect(() => {
    if (leaveDayItem) {
      setStartDate(leaveDayItem.date_from);
      setEndDate(leaveDayItem.date_to);
      setFormData({
        workspace_id: leaveDayItem.workspaceISbb_t925_workspacesID,
        date_start: leaveDayItem.date_from,
        date_end: leaveDayItem.date_to,
        textISsmallplaintextbox: leaveDayItem.textISsmallplaintextbox,
      });
    }
  }, [leaveDayItem]);

  const onDateRangeChangeHandler = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    if (start && end === "1970-01-01") {
      setRangeDateError("Please select the end date!");
    } else {
      setRangeDateError("");
      setFormData({
        ...formData,
        date_start: formatDateForServer(start),
        date_end: formatDateForServer(end),
      });
      //   setRangeDate([formatDateForServer(start), formatDateForServer(end)]);
      setEndDate(end);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "formData");
    const request = leaveDayItem
      ? editLeaveRequest({ ...formData, _id: leaveDayItem._id })
      : addLeaveRequest(formData);
    await request
      .then((res) => {
        console.log(res, res.data, "get-docs");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
          return;
        }
        // if (res.data.success === 1) {
        toast.success(res.data.message, {
          theme: "colored",
        });
        onClose();
      })
      .catch((err) => {
        console.log(err, "err-add-leave-days");
        err?.response?.status === 401
          ? (window.location.href = "https://account2.task925.com/?killsession")
          : dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.message,
              })
            );
      });
  };
  
  return (
    <Form onSubmit={onSubmit}>
      <div className="selectsBlock">
        <Form.Group className="selectItem mb-3 me-1">
          <Form.Label>Workspaces</Form.Label>
          <Form.Select
            value={formData.workspace_id}
            onChange={(e) =>
              setFormData({ ...formData, workspace_id: e.target.value })
            }
            required
          >
            <option value="" hidden>
              Workspace
            </option>
            <option value="">None</option>

            {workspaces?.map((workspace) => (
              <option value={workspace._id} key={workspace._id}>
                {workspace.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="selectItem mb-3 ms-1">
          <Form.Label>Leave days dates</Form.Label>
          <DatePicker
            selected={startDate}
            onChange={onDateRangeChangeHandler}
            startDate={startDate}
            formatWeekDay={(day) => day.slice(0, 3)}
            endDate={endDate}
            selectsRange
            dateFormat="MMM dd"
            locale="en-US"
            placeholderText="Select Date"
            showYearDropdown
            customInput={
              <DatePickerCustomInput
                rangeDateError={rangeDateError}
                className="range-datepicker"
              />
            }
          />
        </Form.Group>
      </div>
      <Textarea
        textareaRef={textRef}
        placeholder="Enter your text..."
        rows={4}
        className="mt-3 mb-5"
        value={formData.textISsmallplaintextbox}
        onChange={(e) =>
          setFormData({ ...formData, textISsmallplaintextbox: e.target.value })
        }
      />
      <Form.Group className="selectItem me-3">
        <Form.Label>Actions</Form.Label>
        <Form.Select
          value={formData.action}
          onChange={(e) => setFormData({ ...formData, action: e.target.value })}
          // id={
          //   owner === "0" || owner === ""
          //     ? "readOnlyPlaceholderId"
          //     : "selectId"
          // }
          // style={
          //   isDisable && currentTaskId !== null
          //     ? { backgroundColor: "transparent" }
          //     : {}
          // }
          // disabled={isDisable}
          required
        >
          <option value="" hidden>
            Actions
          </option>
          <option value="">None</option>

          {actions.map((action) => (
            <option value={action} key={action}>
              {action}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <div className="d-flex justify-content-end mt-5 pt-3">
        <Button type="submit">Submit</Button>
      </div>
    </Form>
  );
};

AddLeaveDaysForm.propTypes = {
  onClose: PropTypes.func,
  leaveDayItem: PropTypes.object,
};
export default AddLeaveDaysForm;
