import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";

const AlertModal = ({ isShow, onClose, title, message }) => {
  const [show, setShow] = useState(isShow);

  useEffect(() => {
    setShow(isShow);
  }, [isShow]);

  const handleClose = (result) => {
    onClose(result);
  };

  return (
    <Modal show={show} onHide={() => handleClose(false)}>
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          <div style={{ fontSize: "20px", marginRight: "8px" }}>
            <AiOutlineWarning className="text-danger" />
          </div>
          <span>{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="d-flex align-items-center text-justify">
            <span>{message}</span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            variant="secondary"
            className="mx-2 btn-sm"
            onClick={() => handleClose(false)}
          >
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
AlertModal.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
};
export default AlertModal;
