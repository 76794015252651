import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { XIconButton } from "../IconButton";
import styles from "./agile.module.scss";
import { MdDragIndicator } from "react-icons/md";

const Agile = ({
  index,
  onDelete,
  register,
  handleBlur,
  errors,
  dragHandleProps,
  field,
  isProject,
  isDisabled,
}) => {
  const agileSectionEditors = useSelector(
    (state) => state.appsetting.agile_sections_editors
  );
  const agileSectionVisibleTo = useSelector(
    (state) => state.appsetting.agile_sections_visible_to
  );
  const inputRef = useRef(null);
  const [internalFieldName, setInternalFieldName] = useState("");
  const [internalVisibleTo, setInternalVisibleTo] = useState("");
  const [internalEditors, setInternalEditors] = useState("");

  useEffect(() => {
    setInternalFieldName(field.name);
    setInternalVisibleTo(field.visible_to);
    setInternalEditors(field.editors);
  }, [field]);

  useEffect(() => {
    inputRef.current.blur();
  }, []);

  const fieldError = errors?.sections?.[index];

  return (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex" style={{ width: "47%" }}>
        {isProject && "_id" in field ? (
          <div style={{ backgrounColor: "black" }} {...dragHandleProps}>
            <MdDragIndicator
              className=""
              style={{
                backgrounColor: "black",
                marginTop: index === 0 ? "32px" : "8px",
              }}
            />
          </div>
        ) : (
          isProject ? <div
            style={{
              width: "16px",
              marginTop: index === 0 ? "32px" : "",
            }}
          ></div>  : null
        )}
        <Form.Group className="mb-2 w-100 ms-2">
          {index === 0 && <Form.Label>Section name</Form.Label>}
          <Form.Control
            type="text"
            name={`sections[${index}].name`}
            placeholder="Section name"
            value={internalFieldName}
            {...register(`sections.${index}.name`, { required: true })}
            onChange={(e) => {
              setInternalFieldName(e.target.value);
            }}
            onBlur={(e) => handleBlur(index, "name", e.target.value)}
            className={fieldError?.name ? styles["is-invalid"] : ""}
            ref={inputRef}
            disabled={isDisabled}
          />
        </Form.Group>
      </div>
      <div className="d-flex" style={{ width: "53%" }}>
        <Form.Group className="mb-2 mx-2" style={{ flexGrow: 1 }}>
          {index === 0 && <Form.Label className="ms-1">Visible to</Form.Label>}
          <Form.Select
            name={`sections[${index}].visible_to`}
            required
            value={internalVisibleTo}
            {...register(`sections.${index}.visible_to`)}
            onChange={(e) => {
              const { onChange } = register(`sections.${index}.visible_to`);
              onChange(e);
              setInternalVisibleTo(e.target.value);
            }}
            onBlur={(e) => {
              handleBlur(index, "visible_to", e.target.value);
              const { onBlur } = register(`sections.${index}.visible_to`);
              onBlur(e);
            }}
            disabled={isDisabled}
            className={fieldError?.visible_to ? styles["is-invalid"] : ""}
          >
            <option value="" disabled hidden>
              Visible to
            </option>
            {agileSectionVisibleTo.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-2 me-2" style={{ flexGrow: 1 }}>
          {index === 0 && <Form.Label className="ms-1">Editor</Form.Label>}
          <Form.Select
            name={`sections[${index}].editors`}
            required
            value={internalEditors}
            {...register(`sections.${index}.editors`)}
            onChange={(e) => {
              const { onChange } = register(`sections.${index}.editors`);
              onChange(e);
              setInternalEditors(e.target.value);
            }}
            onBlur={(e) => {
              handleBlur(index, "editors", e.target.value);
              const { onBlur } = register(`sections.${index}.editors`);
              onBlur(e);
            }}
            disabled={isDisabled}
            className={fieldError?.editors ? styles["is-invalid"] : ""}
          >
            <option value="" disabled hidden>
              Editors
            </option>
            {agileSectionEditors.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {"_id" in field ||
        (field?.visible_to !== "" && field?.editors !== "") ? (
          <div style={{ marginTop: index === 0 ? "24px" : "", height: "100%" }}>
            <XIconButton onClick={() => onDelete()} disabled={isDisabled} />
          </div>
        ) : (
          <div
            style={{
              width: "36px",
              marginTop: index === 0 ? "24px" : "",
              height: "100%",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

Agile.propTypes = {
  index: PropTypes.number,
  onDelete: PropTypes.func,
  register: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  isDisabled: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  field: PropTypes.object,
  isProject: PropTypes.bool,
};
export default Agile;
