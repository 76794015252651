import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FaCalendarAlt } from "react-icons/fa";
import { Form } from "react-bootstrap";

const DatePickerCustomInput = forwardRef(
  ({ value, onClick, placeholder, disabled, onChange, isPermission, className }, ref) => {
    // console.log( disabled, isPermission,'-disabled-isPermission')
    return (
      <div className={`position-relative ${className}`}>
        <Form.Control
          ref={ref}
          onClick={onClick}
          onChange={onChange}
          className={className}
          value={value}
          style={{
            backgroundColor: isPermission ? "transparent" : "",
            color: disabled && !isPermission ? "lightgray" : "",
            paddingLeft: "25px",
            cursor: "pointer",
            caretColor: "transparent",
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
        <FaCalendarAlt
          style={{
            width: "12px",
            height: "14px",
            color: "#2c7be5",
          }}
          className="position-absolute top-50 translate-middle-y mx-2 me-2"
        />
      </div>
    );
  }
);
DatePickerCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isPermission: PropTypes.bool,
  className: PropTypes.string
};
DatePickerCustomInput.displayName = "DatePickerCustomInput";
export default DatePickerCustomInput;
