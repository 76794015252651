import React, { useState, useEffect } from "react";
import styles from "./shared.module.scss";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Globe, Capslock, XCircleFill, FileEarmark } from "react-bootstrap-icons";
import { server_domain } from "services/constants";
import { setError } from "redux/workspaceSlice";
import LoadingDocuments from "./LoadingDocuments";
import { getDocs, addDoc, deleteDoc } from "./../API/docs";
import useModal from "hooks/useModal";
import DocumentsLinksModal from "./DocumentsLinksModal";
import useTabletWindow from "hooks/useTabletWindow";

const DocumentsLinks = ({ projectId, taskId, isDisabled, setIsDocumentsLinks, isPermission }) => {
  const dispatch = useDispatch();
  const [isShow, openModal, closeModal] = useModal();
  const isTablet = useTabletWindow();

  const [docs, setDocs] = useState([]);
  const [title, setTitle] = useState("");
  const [fileName, setFileName] = useState('')
  const [titleError, setTitleError] = useState(false);
  const [url, setUrl] = useState("");
  const [docISfile, setDocISFile] = useState("");
  const [linkError, setLinkError] = useState(false);
  const [linkAlert, setLinkAlert] = useState(false);
  const [hoverDocId, setHoverDocId] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (projectId || taskId) getDocumentsAsync();
  }, [projectId, taskId]);

  const getDocumentsAsync = async () => {
    // formData.append("api_method", "get_docs");
    // formData.append("project_id", projectId);
    // formData.append("task_id", taskId);
    try {
      setLoading(true);
      await getDocs(projectId, taskId)
        .then((res) => {
          // console.log(res, res.data, 'get-docs');
          if (res.data.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error
              })
            );
            return;
          }
          let docsList = res?.data?.list.reverse();
          setDocs(docsList);
          setIsDocumentsLinks && setIsDocumentsLinks(!!docsList?.length);
        })
        .catch((err) => {
          console.log(err, "err-401-get-docs");
          err?.response?.status === 401
            ? (window.location.href = "https://account2.task925.com/?killsession")
            : dispatch(
                setError({
                  isShow: true,
                  title: "Error",
                  message: err.data.error
                })
              );
        })
        .finally(() => setLoading(false));
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString()
        })
      );
    }
  };

  const onTitleChanged = (e) => {
    setTitle(e.target.value);
    setTitleError(false);
  };

  const onFileChanged = (e) => {
    setLinkError(false);

    const file = e.target.files[0];
    if (file === undefined) {
      setDocISFile("");
      setLinkAlert(false);
      return;
    }
    // show alert when file and url both are set
    if (file && url) setLinkAlert(true);
    setFileName(file.name);
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // logs data:<type>;base64,wL2dvYWwgbW9yZ...
      // console.log(reader.result);
      setDocISFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onUrlChanged = (e) => {
    setLinkError(false);
    setUrl(e.target.value);

    // show alert when file and url both are set
    if (e.target.value && docISfile) setLinkAlert(true);
    else setLinkAlert(false);
  };

  const handleSubmit = async () => {
    if (title === "") {
      setTitleError(true);
      return;
    }

    if (docISfile === "" && url === "") {
      setLinkError(true);
      return;
    }

    // formData.append("api_method", "add_doc");
    setFormSubmitting(true);
    try {
      const response = await addDoc({
        name: title,
        fileName: fileName,
        docISfile: docISfile,
        url: url,
        project_id: projectId === undefined ? "" : projectId,
        task_id: taskId === undefined ? "" : taskId
      });
      if (response.data.success === 1) {
        setDocs([
          ...docs,
          {
            _id: response.data.new_doc_id,
            name: title,
            fileName: fileName,
            docISfile: response.data.docISfile,
            url: url
          }
        ]);
        setUrl("");
        setTitle("");
        setFormSubmitting(false);
        closeModal();
      } else {
        setFormSubmitting(false);
        closeModal();
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error
          })
        );
      }
    } catch (err) {
      setFormSubmitting(false);
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString()
        })
      );
    }
  };

  const onDocDeleted = async (e, docId) => {
    e.stopPropagation();
    // formData.append("api_method", "delete_doc");
    // formData.append("doc_id", docId);
    try {
      const response = await deleteDoc({ doc_id: docId });
      // console.log(response, "delete-doc");
      if (response.data.success === 1) {
        setDocs(docs.filter((doc) => doc._id !== docId));
      } else {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error
          })
        );
      }
    } catch (err) {
      console.log(err, "delete-doc-err");

      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString()
        })
      );
    }
  };

  const onDocClicked = (e, docId) => {
    e.preventDefault();
    const doc = docs.find((doc) => doc._id === docId);
    let link = "";
    if (doc.url) {
      const url1 = doc.url.replace("https://", "");
      const url2 = url1.replace("http://", "");
      const url = "https://" + url2;
      link = url;
    } else {
      link = server_domain + "/" + doc.docISfile;
    }

    window.open(link);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      Only the following formats are available for download - jpg, png, pdf, mpg, mp4, quicktime(mov), xlsx and docx.
    </Tooltip>
  );

  return (
    <>
      {loading ? (
        <LoadingDocuments />
      ) : (
        <>
          {isPermission && (
            <div className="mb-3 mt-2 w-100">
              <OverlayTrigger placement="top" overlay={tooltip}>
                <Button
                  variant="primary"
                  size="sm"
                  disabled={isDisabled}
                  onClick={() => openModal(true)}
                  style={{ width: isTablet ? "240px" : "100%" }}
                >
                  <Capslock /> New document/link
                </Button>
              </OverlayTrigger>
            </div>
          )}
          <>
            {docs.length > 0 ? (
              <div className={`mt-3 ${styles.docsGrid}`}>
                {docs.map((doc) => (
                  <div className="mb-2" key={doc._id}>
                    <Button
                      className={`${styles.linkBtn}`}
                      variant="primary"
                      size="sm"
                      onClick={(e) => onDocClicked(e, doc._id)}
                      onMouseEnter={() => setHoverDocId(doc._id)}
                      onMouseLeave={() => setHoverDocId(0)}
                    >
                      {doc.url ? <Globe style={{ marginRight: "8px" }} /> : <FileEarmark style={{ marginRight: "8px" }} />}
                      <span className="ellipsis">{doc.name || "Unknown name"}</span>
                      {isPermission && hoverDocId === doc._id && (
                        <XCircleFill
                          className="position-absolute text-white bg-danger rounded-circle"
                          style={{ top: "-4px", right: "-5px" }}
                          onClick={(e) => onDocDeleted(e, doc._id)}
                        />
                      )}
                    </Button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="fs-14">There is no document/link</div>
            )}
          </>
          <DocumentsLinksModal
            isShow={isShow}
            closeModal={closeModal}
            title={title}
            onTitleChanged={onTitleChanged}
            titleError={titleError}
            onFileChanged={onFileChanged}
            url={url}
            onUrlChanged={onUrlChanged}
            linkError={linkError}
            linkAlert={linkAlert}
            handleSubmit={handleSubmit}
            formSubmitting={formSubmitting}
          />
        </>
      )}
    </>
  );
};
DocumentsLinks.propTypes = {
  isDisabled: PropTypes.bool,
  taskId: PropTypes.string,
  projectId: PropTypes.string,
  setIsDocumentsLinks: PropTypes.func,
  isPermission: PropTypes.bool
};
export default DocumentsLinks;
