import { Button, Form } from "react-bootstrap";
import DocumentsLinks from "components/DocumentsLinks";
import LoadingTimeline from "components/LoadingTimeline";
import Timeline from "components/ChronoTimeline";
import PropTypes from "prop-types";

const TaskDetailRightSidebar = ({ permission, id, setIsDeleteConfirm, loading, isDocumentsLinks, setIsDocumentsLinks, timelines }) => {
  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";
  const isDisable = id === null || !isPermission;

  return (
    <div className="d-flex flex-column px-4 pt-4" style={{ width: "31%" }}>
      {isPermission && (
        <Button className="ms-auto" size="sm" variant="outline-danger" onClick={() => setIsDeleteConfirm(true)} disabled={isDisable}>
          Delete
        </Button>
      )}
      {(isPermission || isDocumentsLinks) && (
        <Form.Group className="my-5">
          <Form.Label>Documents & Links</Form.Label>
          <DocumentsLinks isDisabled={id === null} taskId={id} setIsDocumentsLinks={setIsDocumentsLinks} isPermission={isPermission} />
        </Form.Group>
      )}
      <Form.Group className="mb-5 w-100">
        <Form.Label className={loading ? "" : "mb-3"}>Timeline</Form.Label>
        {loading ? <LoadingTimeline /> : <Timeline values={timelines} mode="VERTICAL" />}
      </Form.Group>
    </div>
  );
};

TaskDetailRightSidebar.propTypes = {
  id: PropTypes.string.isRequired,
  setIsDeleteConfirm: PropTypes.func.isRequired,
  setIsDocumentsLinks: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isDocumentsLinks: PropTypes.bool.isRequired,
  timelines: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired
};

export default TaskDetailRightSidebar;
