import axios from './axios';

export const getWorkspace = (workspaceId) => {

  const params = new URLSearchParams();
  params.append("workspace_id", workspaceId);

  return axios.get('app/get_workspace', {
    params
  })
};
export const addWorkspace = payload => axios.post('app/add_workspace', payload);

export const editWorkspace = payload => axios.patch('app/edit_workspace', payload);

export const deleteWorkspace = payload => axios.delete('app/delete_workspace', { data: payload });


