/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "redux/workspaceSlice";
// import { setViewReports } from "redux/reportsSlice";
import { getParticipants } from "API/participants";

const ReportDetail = ({ isShow, onClose, onSave, report, workspaces }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(isShow);
  const [showSaveFilter, setShowSaveFilter] = useState(false);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [workspace, setWorkspace] = useState("");
  const [reports, setReports] = useState([]);

  const inPriorities = useSelector((state) => state.appsetting.priorities);
  const inDateFilter = useSelector((state) => state.appsetting.date_filter);
  const inPeriods = useSelector((state) => state.appsetting.periods);

  const projects = useMemo(() => {
    const selectedWorkspace = workspaces.find((w) => w._id === workspace);
    const result =
      selectedWorkspace && selectedWorkspace.projects
        ? selectedWorkspace.projects
        : [];
    return result;
  }, [workspace]);
  const [project, setProject] = useState("");

  const [priority, setPriority] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [period, setPeriod] = useState("");

  let priorityOptions = [];
  if (inPriorities && Object.keys(inPriorities).length > 0) {
    Object.keys(inPriorities).forEach((key) => {
      const priority = {
        _id: key,
        name: inPriorities[key].name,
        color: inPriorities[key].color,
      };
      priorityOptions = [...priorityOptions, priority];
    });
  }

  let dateFilterOptions = [];
  if (inDateFilter && Object.keys(inDateFilter).length > 0) {
    Object.keys(inDateFilter).forEach((key) => {
      const filter = {
        _id: key,
        name: inDateFilter[key],
      };
      dateFilterOptions = [...dateFilterOptions, filter];
    });
  }

  let periodOptions = [];
  if (inPeriods && Object.keys(inPeriods).length > 0) {
    Object.keys(inPeriods).forEach((key) => {
      const period = {
        _id: key,
        name: inPeriods[key],
      };
      periodOptions = [...periodOptions, period];
    });
  }

  const [participants, setParticipants] = useState([]);

  const [logger, setLogger] = useState("");
  const [taskOwner, setTaskOwner] = useState("");
  const [actioner, setActioner] = useState("");

  const [search, setSearch] = useState("");

  const [unallocatedYN, setUnallocatedYN] = useState(false);
  const [allocatedYN, setAllocatedYN] = useState(false);
  const [openYN, setOpenYN] = useState(false);
  const [closedYN, setClosedYN] = useState(false);
  const [overdueYN, setOverdueYN] = useState(false);

  const [tag, setTag] = useState("");

  const m = new Date();
  const today =
    m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const setupForm = () => {
    if (!report) {
      console.log("No report");
      return;
    }
    setName(report.name);
    setWorkspace("");
    setProject("");
    setDateFilter("");
    setDateFrom("");
    setDateTo("");
    setPeriod("");
    setLogger("");
    setTaskOwner("");
    setActioner("");
    setSearch("");
    setPriority("");
    setUnallocatedYN(false);
    setAllocatedYN(false);
    setOpenYN(false);
    setClosedYN(false);
    setOverdueYN(false);
    setTag("");
  };

  const onDateFromChanged = (value) => {
    setDateFrom(value);
    console.log("from", value);
  };

  const onDateToChanged = (value) => {
    setDateTo(value);
    console.log("to", value);
  };

  const onBlured = async () => {};

  useEffect(() => {
    setupForm();
    setShow(isShow);
  }, [isShow]);

  useEffect(() => {
    if (workspace) {
      getParticipantsData(workspace, project);
    }
  }, [workspace, project]);

  const handleClose = (result) => {
    onClose(result);
  };

  const onSaveReport = async () => {
    onSave();
  };

  const getParticipantsData = async (workspaceId, projectId) => {
    try {
      const response = await getParticipants(workspaceId, projectId);

      if (response.data.success === 1) {
        setParticipants(response.data.list);
        return;
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
    setParticipants([]);
    return;
  };

  return (
    <Form>
      <Form.Group className="mb-3 d-flex" controlId="filterWorksapce">
        {loading ? (
          <Fragment>
            <div className="animated-background mx-2 my-2 w-50"></div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="w-100">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={false}
              />
            </div>
          </Fragment>
        )}
      </Form.Group>
      {/* <Form.Group className="mb-2 d-flex" controlId="filterWorksapce">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="w-50 me-3">
                        <Form.Label>Date Filter</Form.Label>
                        <Form.Select
                          value={dateFilter}
                          onChange={(e) => setDateFilter(e.target.value)}
                          required
                        >
                          <option value="" hidden>
                            Select Date Filter
                          </option>
                          {dateFilterOptions.map((filter, index) => (
                            <option value={filter._id} key={index}>
                              {filter.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Fragment>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 d-flex">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div style={{ width: "32%" }}>
                        <Form.Label>Date From</Form.Label>
                        <DatePicker
                          value={dateFrom}
                          onChange={onDateFromChanged}
                          placeholder="Date From"
                          onBlured={onBlured}
                        />
                      </div>
                      <div className="ms-3" style={{ width: "32%" }}>
                        <Form.Label>Date to</Form.Label>
                        <DatePicker
                          value={dateTo}
                          onChange={onDateToChanged}
                          placeholder="Date To"
                            onBlured={onBlured}
                        />
                      </div>
                    
                    </Fragment>
                  )}
                </Form.Group>

                <Form.Group className="mb-2 d-flex" controlId="filterWorksapce">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="w-50 me-3">
                        <Form.Label>periodISLIST</Form.Label>
                        <Form.Select
                          value={period}
                          onChange={(e) => setPeriod(e.target.value)}
                          required
                        >
                          <option value="" hidden>
                            Select period
                          </option>
                          {periodOptions.map((p, index) => (
                            <option value={p._id} key={index}>
                              {p.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div className="w-50">
                        <Form.Label>loggerISbb_accountsID</Form.Label>
                        <Form.Select
                          value={logger}
                          onChange={(e) => setLogger(e.target.value)}
                          required
                        >
                          <option value="" hidden>
                            select logger
                          </option>
                          {participants.map((p, index) => (
                            <option value={p._id} key={index}>
                              {p.email}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Fragment>
                  )}
                </Form.Group>

                <Form.Group className="mb-2 d-flex" controlId="filterWorksapce">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="w-50 me-3">
                        <Form.Label>task_ownerISbb_accountsID</Form.Label>
                        <Form.Select
                          value={taskOwner}
                          onChange={(e) => setTaskOwner(e.target.value)}
                          required
                          >
                          <option value="" hidden>
                            Select task owner
                          </option>
                          {participants.map((p, index) => (
                            <option value={p._id} key={index}>
                              {p.email}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div className="w-50">
                        <Form.Label>actionerISbb_accountsID</Form.Label>
                        <Form.Select
                          value={actioner}
                          onChange={(e) => setActioner(e.target.value)}
                          required
                          >
                          <option value="" hidden>
                            Select actioner
                          </option>
                          {participants.map((p, index) => (
                            <option value={p._id} key={index}>
                              {p.email}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Fragment>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 d-flex" controlId="filterWorksapce">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="w-50 me-3">
                        <Form.Label>Text_search</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          disabled={false}
                        />
                      </div>
                      <div className="w-50">
                        <Form.Label>priorityNUM </Form.Label>
                        <Form.Select
                          value={priority}
                          onChange={(e) => setPriority(e.target.value)}
                          required
                          >
                          <option value="" hidden>
                            priorityNUM 
                          </option>
                          {priorityOptions.map((p, index) => (
                            <option value={p._id} key={index}>
                              {p.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Fragment>
                  )}
                </Form.Group>

                <Form.Group className="mb-2 d-flex" controlId="filterWorksapce">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-20"></div>
                      <div className="animated-background mx-2 my-2 w-20"></div>
                      <div className="animated-background mx-2 my-2 w-20"></div>
                      <div className="animated-background mx-2 my-2 w-20"></div>
                      <div className="animated-background mx-2 my-2 w-20"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="w-20 toggle-field">
                        <Form.Label className="fs-6 lowercase">unallocated</Form.Label>
                        <label className="switch ms-3">
                          <input
                            type="checkbox"
                            checked={unallocatedYN}
                            value={unallocatedYN}
                            onChange={(e)=>{setUnallocatedYN(e.target.checked)}}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="w-20 toggle-field">
                        <Form.Label className="fs-6 lowercase">allocated</Form.Label>
                        <label className="switch ms-3">
                          <input
                            type="checkbox"
                            checked={allocatedYN}
                            value={allocatedYN}
                            onChange={(e)=>{setAllocatedYN(e.target.checked)}}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="w-20 toggle-field">
                        <Form.Label className="fs-6 lowercase">open</Form.Label>
                        <label className="switch ms-3">
                          <input
                            type="checkbox"
                            checked={openYN}
                            value={openYN}
                            onChange={(e)=>{setOpenYN(e.target.checked)}}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="w-20 toggle-field">
                        <Form.Label className="fs-6 lowercase">closed</Form.Label>
                        <label className="switch ms-3">
                          <input
                            type="checkbox"
                            checked={closedYN}
                            value={closedYN}
                            onChange={(e)=>{setClosedYN(e.target.checked)}}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="w-20 toggle-field">
                        <Form.Label className="fs-6 lowercase">overdue</Form.Label>
                        <label className="switch ms-3">
                          <input
                            type="checkbox"
                            checked={overdueYN}
                            value={overdueYN}
                            onChange={(e)=>{setOverdueYN(e.target.checked)}}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </Fragment>
                  )}
                </Form.Group>

                <Form.Group className="mb-2 d-flex" controlId="filterWorksapce">
                  {loading ? (
                    <Fragment>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                      <div className="animated-background mx-2 my-2 w-50"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="w-50">
                        <Form.Label>Tag</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Tag"
                          value={tag}
                          onChange={(e) => setTag(e.target.value)}
                          disabled={false}
                        />
                      </div>
                    </Fragment>
                  )}
                </Form.Group> */}
      <div className="d-flex justify-content-end mt-4">
        <Button
          variant="primary"
          className="mx-2 btn-sm"
          onClick={onSaveReport}
        >
                Save
        </Button>
      </div>
    </Form>
  );
};
ReportDetail.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  report: PropTypes.object,
  workspaces: PropTypes.array,
};
export default ReportDetail;
