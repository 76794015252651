import React /*{ useState }*/ from "react";
import styles from "./styles.module.scss";
import "./navbar.css";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
// import { BiX } from "react-icons/bi";
import {
  // setViewReports,
  // setIsAllReports,
  setIsUpdateAllReports,
} from "redux/reportsSlice";
// import {
//   // getListReportFilter,
//   getViewReport,
//   // deleteReportFilter,
// } from "API/reports";
// import { setError } from "redux/workspaceSlice";
import Modal from "components/ModalWindow";
import useModal from "hooks/useModal";
import ReportFilterForm from "./ReportFilterForm";
// import ConfirmModal from "components/ConfirmModal";
// import { selectExistingValues } from "services/helpers";

const ReportsDropdown = ({ toggleMobileNav }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const { isAllReports } = useSelector((state) => state.reports);

  const dispatch = useDispatch();
  const [isShow, openModal, closeModal] = useModal();
  // const [reportFilters, setReportFilters] = useState([]);
  // const [activeReportFilterId, setActiveReporFiltertId] = useState(null);
  // const [showDeleteReportFilter, setShowDeleteReportFilter] = useState(false);
  // const [deleteReportFilterId, setDeleteReportFilterId] = useState("");

  // useEffect(() => {
  //   getReportFilters();
  // }, []);

  // const getReportFilters = async () => {
  //   // formData.append("api_method", "list_report_filters");
  //   try {
  //     const response = await getListReportFilter();
  //     if (response.data.success === 1) {
  //       setReportFilters(response.data.list);
  //       // console.log(response.data.list, "getReportFilters-list");
  //     } else if (response.data.error) {
  //       dispatch(
  //         setError({
  //           isShow: true,
  //           title: "Error",
  //           message: response.data.message,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       setError({
  //         isShow: true,
  //         title: "Error",
  //         message: err.toString(),
  //       })
  //     );
  //   }
  // };

  // const ViewFilters = async () => {
  //   try {
  //     const response = await getViewReport({});

  //     if (response.data.success === 1) {
  //       dispatch(setViewReports({ reports: response.data.list }));
  //       dispatch(setIsAllReports(true));
  //       // navigate("/reports");
  //     } else if (response.data.error) {
  //       dispatch(
  //         setError({
  //           isShow: true,
  //           title: "Error",
  //           message: response.data.error,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       setError({
  //         isShow: true,
  //         title: "Error",
  //         message: err.toString(),
  //       })
  //     );
  //   }
  // };

  // const onChangeReportFilter = async (id) => {
  //   // formData.append("api_method", "view_report");
  //   const formData = selectExistingValues(
  //     reportFilters.find((item) => item._id === id)
  //   );
  //   delete formData.allocatedYN;
  //   delete formData.closedYN;
  //   delete formData.openYN;
  //   delete formData.overdueYN;
  //   delete formData.unallocatedYN;

  //   console.log(formData, "formData for filter-Report Dropdown");
  //   try {
  //     // const response = await getViewReport({
  //     //   report_filterISbb_t925_report_filtersID: id,
  //     // });
  //     const response = await getViewReport(formData);
  //     if (response.data.success === 1) {
  //       dispatch(setViewReports({ reports: response.data.list }));
  //       dispatch(setIsAllReports(false));
  //       setActiveReporFiltertId(id);
  //       navigate("/reports");
  //     } else if (response.data.error) {
  //       dispatch(
  //         setError({
  //           isShow: true,
  //           title: "Error",
  //           message: response.data.message,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       setError({
  //         isShow: true,
  //         title: "Error",
  //         message: err.toString(),
  //       })
  //     );
  //   }
  // };

  // const onDeleteReportFilter = async (isRemove) => {
  //   console.log(isRemove, "isRemove");
  //   setShowDeleteReportFilter(false);
  //   if (isRemove && deleteReportFilterId) {
  //     await removeReportFilter(deleteReportFilterId);
  //   }
  // };

  // const onRemoveReportFilter = async (id) => {
  //   setShowDeleteReportFilter(true);
  //   setDeleteReportFilterId(id);
  // };
  // const removeReportFilter = async (id) => {
  //   // formData.append("api_method", "delete_report_filter");
  //   // formData.append("report_filter_id", id);

  //   try {
  //     const response = await deleteReportFilter({ report_filter_id: id });
  //     if (response.data.success === 1) {
  //       getReportFilters();
  //       ViewFilters();
  //       setDeleteReportFilterId("");
  //     } else if (response.data.error) {
  //       dispatch(
  //         setError({
  //           isShow: true,
  //           title: "Error",
  //           message: response.data.message,
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     dispatch(
  //       setError({
  //         isShow: true,
  //         title: "Error",
  //         message: err.toString(),
  //       })
  //     );
  //   }
  // };
  // console.log(reportFilters,"reportFilters-isAllReports");
  return (
    <>
      <Dropdown align={{ lg: "start" }}>
        <Dropdown.Toggle
          className={`${
            location.pathname === "/filters" || location.pathname === "/filtered-tasks"
              ? styles.activeDropdown
              : styles.selectWorkspace
          } ${styles["dropdown-toggle"]}`}
        >
          Reports
        </Dropdown.Toggle>
        <Dropdown.Menu className="report-filters-content">
          <Dropdown.Item
            onClick={() => {
              dispatch(setIsUpdateAllReports(true));
              toggleMobileNav && toggleMobileNav();
              navigate("/filters");
            }}
            className={
              isAllReports && location.pathname === "/reports"
                ? styles.active
                : ""
            }
          >
            View All Reports
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
              toggleMobileNav && toggleMobileNav();
              openModal();
            }}
          >
            <span style={{ paddingRight: 10 }}>Add a report filter</span>
            {/* <BiPlus className={styles.biPlusIcon} /> */}
          </Dropdown.Item>

          {/* {!!reportFilters?.length &&
            reportFilters.map(({ _id: id, name }, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center"
              >
                <Dropdown.Item
                  // as="button"
                  onClick={() => {
                    toggleMobileNav && toggleMobileNav();
                    onChangeReportFilter(id);
                  }}
                  className={`d-flex justify-content-between ${
                    id === activeReportFilterId && !isAllReports
                      ? styles.active
                      : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <span>{name}</span>
                  <BiX
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveReportFilter(id);
                    }}
                    size={20}
                    style={{
                      color:
                        id === activeReportFilterId && !isAllReports
                          ? "#fff"
                          : "#273748",
                    }}
                  />
                </Dropdown.Item>
              </div>
            ))} */}
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        isShow={isShow}
        modalHeader="New Report Filter"
        handleClose={closeModal}
        headerStyles={{ padding: "32px" }}
        bodyStyles={{ height: '500px', padding: "32px 32px 48px 32px", overflow: 'auto' }}
      >
        <ReportFilterForm
          handleClose={closeModal}
        />
      </Modal>
      {/* <ConfirmModal
        isShow={showDeleteReportFilter}
        title={"Delete Report Filter"}
        message={"Are you sure you want to delete this report filter?"}
        onDelete={onDeleteReportFilter}
        onClose={setShowDeleteReportFilter}
      /> */}
    </>
  );
};
ReportsDropdown.propTypes = {
  toggleMobileNav: PropTypes.func,
};
export default ReportsDropdown;
