import React, { useState, useEffect } from "react";
// import styles from './agile.module.scss';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { PlusIconButton } from "../IconButton";
import { v4 as uuidv4 } from "uuid";
import { setError } from "redux/workspaceSlice";
import {
  getAgileSections,
  editAgileSections,
  addAgileSection,
  editAgileSection,
  deleteAgileSection,
} from "API/agiles";
import Agile from "./Agile";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LoadingFields from "components/LoadingFields";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { deepEqualObj } from "services/helpers";

const AgilesSettings = ({
  workspaceId = null,
  projectId = null,
  isProject = false,
  disabled,
}) => {
  const agileSectionTemplates = useSelector(
    (state) => state.appsetting.agile_sections_templates
  );
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("No template");
  const [projectAgileSections, setProjectAgileSections] = useState([
    { id: uuidv4(), name: "", visible_to: "All", editors: "All" },
  ]);
  const [noTemplateSections, setNoTemplateSections] = useState([]);
  const [templateSections, setTemplateSections] = useState([]);
  const [initAgileSectionsData, setInitAgileSectionsData] = useState([]);
  const [afterAddAgileSections, setAfterAddAgileSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reorderLoading, setReorderLoading] = useState(false);

  const schema = yup.object().shape({
    sections: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        visible_to: yup.string().required(),
        editors: yup.string().required(),
      })
    ),
  });

  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    setError: setErrors,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    !disabled && getAgileSectionsData();
  }, [disabled]);

  // Update data if the save was performed by clicking on the PlusIcon - onAddNewAgileClick().
  // In this case, we cannot update the data correctly only with onBlur()
  useEffect(() => {
    if (afterAddAgileSections.length !== 0) {
      console.log(
        afterAddAgileSections,
        projectAgileSections,
        "afterAddAgileSections, projectAgileSections"
      );
      const updatedAgileSections = projectAgileSections.map(
        (section, index) => {
          if (section.currentId) {
            if (index < afterAddAgileSections.length) {
              afterAddAgileSections[index]?._id && delete section.currentId;
              return {
                ...section,
                _id: afterAddAgileSections[index]._id,
              };
            } else {
              return section;
            }
          } else {
            return section;
          }
        }
      );
      setProjectAgileSections(updatedAgileSections);
      setNoTemplateSections(
        updatedAgileSections.filter((item) => "_id" in item && item?._id)
      );
      setAfterAddAgileSections([]);
    }
  }, [afterAddAgileSections]);

  const getAgileSectionsData = async () => {
    setLoading(true);
    try {
      await getAgileSections(workspaceId, projectId)
        .then((res) => {
          // console.log(res, res.data.list, "data-list-getAgilesSections");
          if (res.data.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
            return;
          }
          if (res.data.list.length === 0) {
            setProjectAgileSections([
              {
                currentId: uuidv4(),
                name: "",
                visible_to: "All",
                editors: "All",
              },
            ]);
            setNoTemplateSections([
              {
                cirrentId: uuidv4(),
                name: "",
                visible_to: "All",
                editors: "All",
              },
            ]);
          } else {
            const agileSectionsList = res.data.list;
            setProjectAgileSections(agileSectionsList);
            setNoTemplateSections(agileSectionsList);
            // console.log(agileSectionsList, "res-data - Agile");
            const initEditeData = agileSectionsList.map((item) => {
              return {
                name: item.name,
                visible_to: item.visible_to,
                editors: item.editors,
              };
            });
            setInitAgileSectionsData(initEditeData);
          }
          setSelectedValue("No template");
        })
        .catch((err) => console(err, "Error-catch getAgilesData"))
        .finally(() => setLoading(false));
    } catch (err) {
      console.log(err, "Error getAgilesData");
    }
  };

  const handleTemplateChange = (e) => {
    const sections = [];
    const value = e.target.value;
    setSelectedValue(value);
    if (value !== "No template") {
      const sectionsFields = agileSectionTemplates.find(
        (el) => el.name === value
      )?.agile_sections;
      sectionsFields.forEach((value) => {
        sections.push({
          currentId: uuidv4(),
          name: value,
          visible_to: "",
          editors: "",
        });
        clearErrors();
        setTemplateSections(sections);
        setProjectAgileSections([...noTemplateSections, ...sections]);
        // setValue("sections", softwareDevelopment);
      });
    } else {
      clearErrors();
      setTemplateSections([]);
      setProjectAgileSections(noTemplateSections);
      setValue("sections", noTemplateSections);
    }
  };

  const handleBlur = async (fieldIndex, fieldName, fieldValue) => {
    setValue(`sections[${fieldIndex}].${fieldName}`, fieldValue);
    await trigger(`sections[${fieldIndex}].${fieldName}`);
    const updatedFields = projectAgileSections.map((field, index) => {
      if (index === fieldIndex) {
        const editedField = { ...field, [fieldName]: fieldValue };
        if (editedField.name === "") {
          setErrors(`sections[${fieldIndex}].name`, {
            type: "manual",
            message: "",
          });
        }
        if (editedField.visible_to === "") {
          setErrors(`sections[${fieldIndex}].visible_to`, {
            type: "manual",
            message: "",
          });
        }
        if (editedField.editors === "") {
          setErrors(`sections[${fieldIndex}].editors`, {
            type: "manual",
            message: "",
          });
        }
        const values = Object.values(editedField);
        const isFilled = values.every(
          (value) => value !== undefined && value !== ""
        );
        isFilled && addEditAgileSection(editedField, fieldIndex);
        return editedField;
      }
      return field;
    });
    setProjectAgileSections(updatedFields);
  };

  const onAddNewAgileClick = () => {
    setProjectAgileSections([
      ...projectAgileSections,
      { currentId: uuidv4(), name: "", visible_to: "All", editors: "All" },
    ]);
    setNoTemplateSections([
      ...noTemplateSections,
      { currentId: uuidv4(), name: "", visible_to: "All", editors: "All" },
    ]);
  };

  const addEditAgileSection = async (agileForm, index) => {
    // console.log(agileForm, index, "agileForm");
    const formData = {
      name: agileForm.name,
      visible_to: agileForm.visible_to,
      editors: agileForm.editors,
      workspace_id: isProject ? null : workspaceId,
      project_id: projectId === undefined ? "" : projectId,
    };
    const initAgoleSectionObj = initAgileSectionsData.find(
      (p, i) => i === index
    );
    const editedFormObj = {
      name: agileForm.name,
      visible_to: agileForm.visible_to,
      editors: agileForm.editors,
    };

    const isFormDataChanged = agileForm._id
      ? !deepEqualObj(initAgoleSectionObj, editedFormObj)
      : null;
    try {
      const request = agileForm?.currentId
        ? addAgileSection(formData)
        : isFormDataChanged
        ? editAgileSection({
            ...formData,
            agile_section_id: agileForm._id,
          })
        : null;
      // console.log(response.data, "add-edit-agile")
      request &&
        (await request
          .then((res) => {
            const replaceObjectByCurrentId = (
              arr,
              currentIdToFind,
              replacementObject
            ) => {
              const index = arr.findIndex(
                (obj) =>
                  obj.currentId === currentIdToFind ||
                  obj._id === currentIdToFind
              );
              if (index !== -1) {
                arr[index] = replacementObject;
              }
              return arr;
            };

            if (res?.data?.error) {
              dispatch(
                setError({
                  isShow: true,
                  title: "Error",
                  message: res?.data?.error,
                })
              );
              const currentAgileForm = { ...agileForm };
              currentAgileForm.visible_to = "";
              currentAgileForm.editors = "";
              const updatedArray = agileForm?._id
                ? replaceObjectByCurrentId(
                    projectAgileSections,
                    agileForm._id,
                    agileForm
                  )
                : replaceObjectByCurrentId(
                    projectAgileSections,
                    agileForm.currentId,
                    currentAgileForm
                  );
              setAfterAddAgileSections(updatedArray);
              console.log(updatedArray, "ifError - Add-Edit-Agile");
              const initEditeData = updatedArray.map((item) => {
                return {
                  name: item.name,
                  visible_to: item.visible_to,
                  editors: item.editors,
                };
              });
              setInitAgileSectionsData(initEditeData);
              return;
            }

            const updatedArray = agileForm?._id
              ? replaceObjectByCurrentId(
                  projectAgileSections,
                  agileForm._id,
                  res.data.edited_agile_section
                )
              : replaceObjectByCurrentId(
                  projectAgileSections,
                  agileForm.currentId,
                  res.data.new_agile_section
                );
            setAfterAddAgileSections(updatedArray);
            // console.log(updatedArray, "updatedArray - if Success");
            const initEditeData = updatedArray.map((item) => {
              return {
                name: item.name,
                visible_to: item.visible_to,
                editors: item.editors,
              };
            });
            setInitAgileSectionsData(initEditeData);
          })
          .catch((err) => {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.data.error,
              })
            );
          }));
    } catch (err) {
      console.log(err, "err-try");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.message,
        })
      );
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      projectAgileSections,
      result.source.index,
      result.destination.index
    );

    setProjectAgileSections(items);
    const formData = {
      project_id: projectId,
      section_order: items.map((item) => item._id),
    };
    // console.log(formData, "section form Data after reorder");
    setReorderLoading(true);
    await editAgileSections(formData)
      .then((res) => {
        console.log(res.data, "edit-reorder-section");
        if (res?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res?.data?.error,
            })
          );
        }
        setProjectAgileSections([...res.data.sections, ...templateSections]);
        setNoTemplateSections(res.data.sections);
      })
      .catch((err) => {
        if (err.response.status) {
          // console.log(err, err.response.status, "edit-reorder-section-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error,
            })
          );
        }
      })
      .finally(() => setReorderLoading(false));
  };

  const onDeleteAgileClick = async (section) => {
    // console.log(section, "onDeleteAgileClick");
    try {
      clearErrors();
      const response = section._id
        ? await deleteAgileSection({ agile_section_id: section._id })
        : projectAgileSections.length > 1 &&
          setProjectAgileSections(
            projectAgileSections.filter(
              (field) => field?.currentId !== section?.currentId
            )
          );
      // console.log(response, "response");
      if (response?.data?.success === 1) {
        console.log(response.data, "deleteAgileSection-response.data");
        let newSections = projectAgileSections.filter(
          (field) => field._id !== section._id
        );
        let noTemplatesNewSections = newSections.filter(
          (item) => "_id" in item
        );
        if (newSections.length !== 0) {
          setProjectAgileSections(newSections);
          setNoTemplateSections(noTemplatesNewSections);
          const initEditeData = newSections.map((item) => {
            return {
              name: item.name,
              visible_to: item.visible_to,
              editors: item.editors,
            };
          });
          setInitAgileSectionsData(initEditeData);
        } else {
          setProjectAgileSections([
            {
              currentId: uuidv4(),
              name: "",
              visible_to: "All",
              editors: "All",
            },
          ]);
          setNoTemplateSections([
            {
              currentId: uuidv4(),
              name: "",
              visible_to: "All",
              editors: "All",
            },
          ]);
        }
        // await getAgileSectionsData();
      } else if (response?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
    } catch (err) {
      console.log(err, "err-delete-agile");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: "",
        })
      );
    }
  };
  // console.log(
  //   projectAgileSections,
  //   noTemplateSections,
  //   "projectAgileSections- noTemplateSections"
  // );
  return (
    <>
      <CircleSpinnerOverlay
        loading={reorderLoading}
        message="Saving..."
        color="#5e6e82"
        overlayColor="rgba(0,0,0,0.3)"
      />
      {loading ? (
        <div className="d-flex flex-column">
          <div className="fs-16 w-50">AGILE DOC SECTIONS</div>
          <LoadingFields />
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between w-100 mb-2">
            <div className="fs-16 w-50">AGILE DOC SECTIONS</div>
            <div className="w-50" style={{ marginLeft: "15px" }}>
              <Form.Select
                value={selectedValue}
                onChange={handleTemplateChange}
                required
                disabled={disabled}
                style={{ width: "100%" }}
              >
                {[
                  { name: "No template", agile_sections: [] },
                  ...agileSectionTemplates,
                ].map((template) => (
                  <option key={template.name} value={template.name}>
                    {template.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="d-flex w-100">
            <div style={{ marginTop: "24px", height: "100%" }}>
              <PlusIconButton
                onClick={onAddNewAgileClick}
                disabled={disabled}
              />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="d-flex align-content-between w-100 mb-2 flex-column ms-2"
                  >
                    {projectAgileSections.map((field, index) => (
                      <Draggable
                        key={field?._id || field?.currentId}
                        draggableId={field?._id?.toString() || index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Agile
                              key={field?.currentId || field?._id}
                              field={field}
                              index={index}
                              isProject={isProject}
                              register={register}
                              trigger={trigger}
                              errors={errors}
                              handleBlur={handleBlur}
                              onDelete={() => onDeleteAgileClick(field)}
                              dragHandleProps={provided.dragHandleProps}
                              isDisabled={
                                (isProject &&
                                  field.projectISbb_t925_projectsID === "0") ||
                                disabled
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}
    </>
  );
};

AgilesSettings.propTypes = {
  workspaceId: PropTypes.string,
  projectId: PropTypes.string,
  isProject: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default AgilesSettings;
