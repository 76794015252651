import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const MultiSelect = ({
  disabled,
  options,
  onChange,
  selected = [],
  onBlur,
  className,
  placeholder,
}) => {
  const prepareOptions = (optionsArray) => {
    return optionsArray.map((option) => ({
      value: option,
      label: option,
    }));
  };

  const preparedOptions = useMemo(() => prepareOptions(options), [options]);
  const preparedSelectedOptions = useMemo(
    () => (selected ? prepareOptions(selected) : null),
    [selected]
  );
  // console.log(selected, placeholder, "selected-options-Multiselect");
  return (
    <Select
      isMulti
      options={preparedOptions}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={preparedSelectedOptions}
      isDisabled={disabled}
      classNamePrefix={className}
      classNames={{
        control: () => className,
      }}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 10,
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.isDisabled
            ? "#e9ecef"
            : provided.backgroundColor,
        }),
      }}
    />
  );
};

MultiSelect.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MultiSelect;
