import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import PropTypes from "prop-types";

const PagePagination = ({ totalPages, activePage, onPaginationClick }) => {
  const [pagesCount, setPagesCount] = useState([]);
  useEffect(() => {
    let array = [];
    for (let i = 1; i <= totalPages; i++) {
      array.push(i);
    }
    setPagesCount(array);
  }, []);
  return (
    <Pagination size="sm">
      <Pagination.First
        onClick={() => onPaginationClick(1)}
        disabled={activePage === 1}
      />
      <Pagination.Prev
        onClick={() => onPaginationClick(activePage - 1)}
        disabled={activePage === 1}
      />
      {activePage > 2 && (
        <>
          <Pagination.Item onClick={() => onPaginationClick(1)}>
            1
          </Pagination.Item>
          <Pagination.Ellipsis />
        </>
      )}

      {pagesCount.map((item, i) => {
        const pageNumber = i + 1;
        if (pageNumber < activePage - 1 || pageNumber > activePage + 1) {
          return null;
        }
        return (
          <Pagination.Item
            key={item}
            active={activePage === pageNumber}
            onClick={() => onPaginationClick(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
      })}

      {activePage < totalPages - 1 && (
        <>
          <Pagination.Ellipsis />
          <Pagination.Item onClick={() => onPaginationClick(totalPages)}>
            {totalPages}
          </Pagination.Item>
        </>
      )}
      <Pagination.Next
        onClick={() => onPaginationClick(activePage + 1)}
        disabled={activePage === totalPages}
      />
      <Pagination.Last
        onClick={() => onPaginationClick(totalPages)}
        disabled={activePage === totalPages}
      />
    </Pagination>
  );
};
PagePagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
};
export default PagePagination;
