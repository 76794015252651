import React, { useState } from "react";
import { Form, Button, Container, Row, Image, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postLogin } from "API/session";
import { login } from "redux/authSlice";
import { setError } from "redux/workspaceSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailEmptyError, setEmailEmptyError] = useState(false);
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoginSubmitClick = async () => {
    if (email === "") {
      setEmailEmptyError(true);
      return;
    }

    let formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    try {
      setLoading(true);
      await postLogin(formData)
        .then(async (res) => {
          // console.log(res, res.data, 'login-data-success');
          login(res.data);
          navigate("/dashboard");
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "login-err");
            setErrMsg("Incorrect email and password");
            setLoading(false);
          }
        });
    } catch (err) {
      setLoading(false);
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const onEmailChange = (e) => {
    setEmailEmptyError(false);
    setEmail(e.target.value);
  };

  return (
    <Container>
      <Row className="justify-content-md-center" style={{ marginTop: 70 }}>
        <div style={{ width: "40%" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Image src="/imgs/logo.png" width="100%"></Image>
          </div>
          <Form
            style={{ background: "#e7f1ff" }}
            className="px-5 pb-4 pt-3 rounded-3"
          >
            <p className="text-center fs-3">Login</p>
            {errMsg !== "" && (
              <Alert variant="danger" dismissible onClose={() => setErrMsg("")}>
                {errMsg}
              </Alert>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                required
                placeholder="Enter email"
                value={email}
                onChange={onEmailChange}
              />
              {emailEmptyError && (
                <Form.Text className="text-danger ms-1">
                  Email is required*.
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button
              className="mt-3 w-100"
              variant="primary"
              type="button"
              onClick={onLoginSubmitClick}
              disabled={loading}
            >
              {loading ? "Loading..." : "Login"}
              {loading && (
                <div
                  className="spinner-border spinner-border-sm ms-2 mt-1"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Button>

            <p className="text-center mt-4 mb-0 fs-6">
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
            <p className="text-center fs-6">
              <Link to="/forgotpassword">Forgot your password?</Link>
            </p>
          </Form>
        </div>
      </Row>
    </Container>
  );
};

export default Login;
