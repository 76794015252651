import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const ModalWindow = ({ isShow, handleClose, children, modalHeader, size, headerStyles, bodyStyles, className }) => {

  return (
    <Modal show={isShow} onHide={handleClose} size={size ? size : "lg"} centered className={className}>
      <Modal.Header style={headerStyles} closeButton>
        <Modal.Title>{modalHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={bodyStyles}>
        {children}
      </Modal.Body>
    </Modal>
  );
}
ModalWindow.propTypes = {
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  modalHeader: PropTypes.string.isRequired,
  size: PropTypes.string,
  headerStyles: PropTypes.object,
  bodyStyles: PropTypes.object,
  className: PropTypes.object
}
export default ModalWindow;