import React, { useState, useEffect } from "react";
import "./Reports.css";
import Report from "./Report";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReportDetail from "./ReportDetail";
import { getViewReport } from "API/reports";
import { setError } from "redux/workspaceSlice";
import useModal from "hooks/useModal";
import Modal from "components/ModalWindow";
import LoadingScreen from "layouts/LoadingScreen";
import {
  setViewReports,
  setIsAllReports,
  setIsUpdateAllReports,
} from "redux/reportsSlice";
import { BiArrowBack } from "react-icons/bi";

const Reports = () => {
  const { reports, isUpdateAllReports } = useSelector((state) => state.reports);
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  let navigate = useNavigate();

  const [isShow, openModal, closeModal] = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    isUpdateAllReports && ViewFilters();
  }, [isUpdateAllReports]);

  const ViewFilters = async () => {
    setIsLoading(true);
    try {
      await getViewReport({})
        .then((res) => {
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res?.data?.error,
              })
            );
            return;
          }
          if (res.data.success === 1) {
            dispatch(setViewReports({ reports: res?.data?.list }));
            dispatch(setIsAllReports(true));
            dispatch(setIsUpdateAllReports(false));
          }
        })
        .catch((err) => {
          console.log(err, "Error-catch Get ViewReport");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err?.response?.data?.error,
            })
          );
        })
        .finally(() => setIsLoading(false));
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const onSelectReport = (report) => {
    setSelectedReport(report);
    openModal();
  };

  const onSaveReport = () => {};

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className="container content-wrapper">
        <div
          className="d-flex align-items-center backToFilters"
          onClick={() => navigate("/filters")}
        >
          <BiArrowBack />
          <span className="ms-2">Back to Filters List</span>
        </div>
        {reports.length > 0 ? (
          <div className="reports-wrapper card">
            <div className="d-flex" style={{paddingLeft: '56px'}}>
              <span className="card-title">Filtered tasks</span>
            </div>
            <div className="reports-innerWrapper">
              <div className="report-header">
                <div className="d-flex justify-content-between align-items-center report-item">
                  <div className="reportNameHeader fs-14 fw-600">Task Name</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="reportOwnerHeader py-2 fs-14 fw-600">
                      Team Member
                    </div>
                    <div className="reportDuedateHeader py-2 fs-14 fw-600">
                      Due Date
                    </div>
                    <div className="reportPriorityHeader py-2 fs-14 fw-600">
                      Priority
                    </div>
                  </div>
                </div>
              </div>
              <div className="task-hr" style={{ margin: "10px 0px" }}></div>

              {reports.map((report, index) => (
                <Report
                  key={index}
                  report={report}
                  onSelectReport={onSelectReport}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="reports-wrapper card">
            <div className="reports-innerWrapper">No reports</div>
          </div>
        )}
      </div>
      <Modal
        // size="xl"
        size="md"
        isShow={isShow}
        modalHeader="Edit Report"
        handleClose={closeModal}
        headerStyles={{ padding: "32px" }}
        bodyStyles={{ padding: "32px 32px 48px 32px" }}
      >
        <ReportDetail
          workspaces={workspaces}
          report={selectedReport}
          onClose={closeModal}
          onSave={onSaveReport}
        />
      </Modal>
    </>
  );
};

export default Reports;
