import React, { useState, useEffect, useRef } from "react";
import "features/styles.css";
import PropTypes from "prop-types";
import { Form, Button, Collapse } from "react-bootstrap";
import { useDispatch } from "react-redux";
import TagsSettings from "components/TagsSettings";
import FieldsSettings from "components/FieldsSettings";
import AgilesSettings from "components/AgilesSettings";
import Participants from "components/Participants";
import DocumentsLinks from "components/DocumentsLinks";
import Timeline from "components/ChronoTimeline";
import Textarea from "components/Textarea";
import { setDisplayProject, setError } from "redux/workspaceSlice";
import { getProject, addProject, editProject } from "API/projects";
import LoadingTimeline from "components/LoadingTimeline";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { Person } from "react-bootstrap-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import compareAsc from "date-fns/compareAsc";
import enUS from "date-fns/locale/en-US";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import { formatDate, deepEqualObj } from "services/helpers";
import { setProject } from "redux/projectSlice";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import ButtonSpinner from "components/ButtonSpinner";
import useTabletWindow from "hooks/useTabletWindow";
import useMobileWindow from "hooks/useMobileWindow";
import MobilAccordion from "components/MobileAcordion";

const ProjectDetail = ({ onClose, id, workspaceId }) => {
  registerLocale("en-US", enUS);
  const isTablet = useTabletWindow();
  const isMobile = useMobileWindow();

  const dispatch = useDispatch();
  const { onUpdateSettingData } = useUpdateSettingData();
  const { fetchWorkspaceData } = useGetWorkspaceData();
  const title = id === null ? "New Project" : "Edit Project";

  const today = formatDate(new Date());

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(today);
  const [timelines, setTimelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorName, setErrorName] = useState("");
  const [openCollapse, setOpenCollapse] = useState(false);
  const [permission, setPermission] = useState();
  const textareaDescRef = useRef(null);
  const [initData, setInitData] = useState({});
  const [isSpinnerLoad, setIsSpinnerLoad] = useState(false);

  // state and functions for open/close a selector of the colors in Tags,js
  const [openState, setOpenState] = useState({});
  const handleColorClick = (categoryIndex, tagIndex) => {
    setOpenState((prevState) => {
      const updatedOpenState = { ...prevState };
      const key = `${categoryIndex}-${tagIndex}`;

      Object.keys(updatedOpenState).forEach((stateKey) => {
        if (stateKey !== key) {
          updatedOpenState[stateKey] = false;
        }
      });

      updatedOpenState[key] = !prevState[key];

      return updatedOpenState;
    });
  };
  const handleOutsideClick = () => {
    setOpenState((prevState) => {
      const updatedOpenState = { ...prevState };

      Object.keys(updatedOpenState).forEach((stateKey) => {
        updatedOpenState[stateKey] = false;
      });

      return updatedOpenState;
    });
  };
  // ----------------------------------------------------------------------------
  useEffect(() => {
    if (id) {
      initProjectData(id);
      setCurrentProjectId(id);
    }
  }, [id]);

  const initProjectData = async (projectId) => {
    try {
      setLoading(true);
      const res = await getProject(projectId);
      if (res.data.success === 1) {
        const project = res.data.project;
        // console.log(project, "project in the Project edit");
        dispatch(setProject(project));
        setName(project.name);
        setPermission(project.permission);
        setTimelines(project.timeline);
        setDescription(
          project.detailsISsmallplaintextbox === null
            ? ""
            : project.detailsISsmallplaintextbox
        );
        setDateFrom(
          project.date_from === null ? today : formatDate(project.date_from)
        );
        setDateTo(
          project.date_to === null ? today : formatDate(project.date_to)
        );
        const projectInitData = {
          project_id: id,
          name: project.name,
          detailsISsmallplaintextbox:
            project.detailsISsmallplaintextbox === null
              ? ""
              : project.detailsISsmallplaintextbox,
          date_from: project.date_from
            ? formatDate(project.date_from)
            : project.date_from,
          date_to: project.date_to
            ? formatDate(project.date_to)
            : project.date_to,
        };
        setInitData(projectInitData);
      } else if (res?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: res?.data?.error,
          })
        );
      }
      setLoading(false);
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const onDateFromChanged = (value) => {
    if (dateTo) {
      const compareResult = compareAsc(new Date(value), new Date(dateTo));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "DateFrom field should be before DateTo field",
          })
        );
        return;
      }
    }
    setDateFrom(formatDate(value));
  };

  const onDateToChanged = (value) => {
    if (dateFrom) {
      const compareResult = compareAsc(new Date(dateFrom), value);
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "DateTo field should be after DateFrom field",
          })
        );
        return;
      }
    }
    setDateTo(formatDate(value));
  };

  const addNewProject = async () => {
    if (name === "") {
      setErrorName("The Name field is required");
      return;
    }
    const formData = {
      workspace_id: workspaceId,
      name: name,
      detailsISsmallplaintextbox: description,
      date_from: dateFrom,
      date_to: dateTo,
    };
    if (currentProjectId === null) {
      setLoading(true);
      await addProject(formData)
        .then((res) => {
          console.log(res, res.data, "add-project");
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res?.data?.error,
              })
            );
          }
          let newProjectId = res.data.new_project_id.toString();
          dispatch(setDisplayProject({ display_project: newProjectId }));
          setCurrentProjectId(newProjectId);
          fetchWorkspaceData(workspaceId);
          initProjectData(newProjectId);
          setErrorName("");
          onUpdateSettingData(workspaceId, newProjectId);
        })
        .catch((err) => {
          if (err?.response?.status) {
            console.log(err, err?.response?.status, "add-workspace-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err?.response?.data?.error,
              })
            );
          }
        })
        .finally(() => setLoading(false));
    }
    // if (currentProjectId === null) {
    //   formData.append("api_method", "add_project");
    //   formData.append("workspace_id", workspaceId);
  };
  const onDoneProject = async () => {
    if (loading) {
      onClose();
      document.body.classList.add("overflow-auto");
      document.body.classList.remove("overflow-hidden");
    }
    if (currentProjectId && name === "") {
      setErrorName("The Name field is required");
      return;
    }
    const formData = {
      project_id: currentProjectId,
      name: name,
      detailsISsmallplaintextbox: description,
      date_from: dateFrom,
      date_to: dateTo,
    };

    const isFormDataChanged = !deepEqualObj(initData, formData);
    if (currentProjectId && isFormDataChanged) {
      setIsSpinnerLoad(true);
      await editProject(formData)
        .then((res) => {
          console.log(res, res.data, "edit-project");
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
          } else if (initData.name !== formData.name) {
            fetchWorkspaceData(workspaceId);
            onUpdateSettingData(workspaceId, currentProjectId);
          }
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "edit-project-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.message || err.response.data.error,
              })
            );
          }
        })
        .finally(() => {
          setIsSpinnerLoad(false);
        });
    }
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };

  const isPermission =
    permission === "Owner" ||
    permission === "Admin" ||
    permission === "Project Manager";
  const isOnlyOwnerPermission =
    permission === "Owner" || permission === "Admin";

  return (
    <div className="modal-container" onClick={onDoneProject}>
      <div
        className="modal-content"
        style={{ width: "85%" }}
        onClick={(event) => {
          event.stopPropagation();
          handleOutsideClick();
        }}
      >
        <Form>
          <div className="formWrapper" style={{ minHeight: "100%" }}>
            <div className="px-4 bg-white mainBlock">
              <div className="bg-white py-4 d-flex justify-content-between align-items-center">
                <span className="fs-5 fw-bold">{title}</span>
                <ButtonSpinner onClick={onDoneProject} loading={isSpinnerLoad}>
                  Done
                </ButtonSpinner>
              </div>
              <div className="nameBlock mb-5">
                <Form.Group className="nameInput">
                  <Form.Label>Name</Form.Label>
                  {loading ? (
                    <div className="animated-background mx-2"></div>
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Project name"
                      value={name}
                      onChange={(e) => {
                        if (e.target.value.length !== 0) setErrorName(null);
                        setName(e.target.value);
                      }}
                      onBlur={addNewProject}
                      className={errorName ? "border-danger" : ""}
                    />
                  )}
                  {errorName && (
                    <div className="text-danger" style={{ fontSize: "12px" }}>
                      {errorName}
                    </div>
                  )}
                </Form.Group>
                <div
                  className="btnParticipants ms-2"
                  style={{ marginBottom: errorName ? "18px" : "" }}
                >
                  <Button
                    variant="primary"
                    onClick={() => setOpenCollapse(!openCollapse)}
                    className="collapseBtn"
                  >
                    <Person /> Participants
                  </Button>
                </div>
              </div>
              {(isMobile || isTablet) && (
                <Collapse in={openCollapse} className="mb-5 p-3 collapseBlock">
                  <div className="border rounded w-100">
                    <Participants
                      disabled={currentProjectId === null}
                      projectId={currentProjectId}
                      isProject={true}
                    />
                  </div>
                </Collapse>
              )}

              <Form.Group className="mb-5" controlId="projectDescription">
                <>
                  <Form.Label>Description</Form.Label>
                  {loading ? (
                    <div className="mb-3 mx-2">
                      <div className="animated-background mb-1"></div>
                      <div className="animated-background mb-1"></div>
                      <div className="animated-background w-50 mb-2"></div>
                    </div>
                  ) : (
                    <Textarea
                      textareaRef={textareaDescRef}
                      placeholder="Project description"
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      isDisable={currentProjectId === null}
                      isPermission={
                        permission && isOnlyOwnerPermission === false
                      }
                    />
                  )}
                </>
              </Form.Group>
              <Form.Group className="mb-5 d-flex" controlId="projectDateFrom">
                {loading ? (
                  <>
                    <div className="d-flex flex-column my-2 w-50">
                      <Form.Label>Date From</Form.Label>
                      <div className="animated-background mx-2"></div>
                    </div>
                    <div className="d-flex flex-column my-2 w-50">
                      <Form.Label>Date to</Form.Label>
                      <div className="animated-background mx-2"></div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between flex-wrap w-100">
                    <Form.Group className="formElement mb-2 d-flex flex-column">
                      <Form.Label>Date From</Form.Label>
                      <DatePicker
                        selected={new Date(dateFrom)}
                        value={dateFrom}
                        onChange={onDateFromChanged}
                        disabled={
                          currentProjectId === null || !isOnlyOwnerPermission
                        }
                        formatWeekDay={(day) => day.slice(0, 3)}
                        locale="en-US"
                        placeholderText="Date from"
                        customInput={
                          <DatePickerCustomInput
                            isPermission={
                              permission && isOnlyOwnerPermission === false
                            }
                          />
                        }
                        showYearDropdown
                      />
                    </Form.Group>

                    <Form.Group className="formElement mb-2 d-flex flex-column">
                      <Form.Label>Date to</Form.Label>
                      <DatePicker
                        selected={new Date(dateTo)}
                        value={dateTo}
                        onChange={onDateToChanged}
                        disabled={
                          currentProjectId === null || !isOnlyOwnerPermission
                        }
                        // onCalendarClose={addEditProject}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        locale="en-US"
                        placeholderText="Date to"
                        customInput={
                          <DatePickerCustomInput
                            isPermission={
                              permission && isOnlyOwnerPermission === false
                            }
                          />
                        }
                        showYearDropdown
                      />
                    </Form.Group>
                  </div>
                )}
              </Form.Group>

              {isMobile ? (
                <MobilAccordion
                  projectId={currentProjectId}
                  openState={openState}
                  handleColorClick={handleColorClick}
                  disabled={currentProjectId === null}
                />
              ) : (
                <>
                  <TagsSettings
                    projectId={currentProjectId}
                    openState={openState}
                    handleColorClick={handleColorClick}
                    disabled={currentProjectId === null}
                  />

                  <div className="task-hr my-4"></div>

                  <Form.Group>
                    <Form.Label>Fields</Form.Label>
                    <FieldsSettings
                      disabled={currentProjectId === null}
                      projectId={currentProjectId}
                    />
                  </Form.Group>

                  <div className="task-hr my-4"></div>

                  <Form.Group controlId="projectAgiles">
                    <AgilesSettings
                      workspaceId={workspaceId}
                      projectId={currentProjectId}
                      isProject={true}
                      disabled={currentProjectId === null}
                    />
                  </Form.Group>

                  <div className="task-hr mb-4"></div>
                </>
              )}
              <Form.Group className="mb-4" controlId="projectDocuments">
                <Form.Label>Documents & Links</Form.Label>
                <DocumentsLinks
                  disabled={currentProjectId === null}
                  projectId={currentProjectId}
                  isPermission={isPermission}
                />
              </Form.Group>

              {!!timelines?.length && (
                <div className="task-hr timelineHorizontal mb-4"></div>
              )}

              {!!timelines?.length && (
                <Form.Group className="mb-5 w-100 timelineHorizontal">
                  <Form.Label className="mb-3">Timeline</Form.Label>
                  {loading ? (
                    <LoadingTimeline />
                  ) : (
                    <Timeline values={timelines} mode="HORIZONTAL" />
                  )}
                </Form.Group>
              )}
            </div>
            <div
              className="participants"
              style={{ width: "10px", backgroundColor: "gray", opacity: 0.7 }}
            ></div>
            <div
              className="px-4 pt-5 bg-white rounded participants"
              style={{ width: "31%" }}
            >
              {!isTablet && (
                <Form.Group className="mb-5">
                  <Participants
                    disabled={currentProjectId === null}
                    projectId={currentProjectId}
                    isProject={true}
                  />
                </Form.Group>
              )}

              {!!timelines?.length && (
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-3">Timeline</Form.Label>
                  {loading ? (
                    <LoadingTimeline />
                  ) : (
                    <Timeline values={timelines} mode="VERTICAL" />
                  )}
                </Form.Group>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

ProjectDetail.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  workspaceId: PropTypes.string,
};
export default ProjectDetail;
