import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAppSettingData } from "API/settings";
import {
  setError,
  fetchWorkspaces,
  setDisplayProject,
  setDisplayWorkspace,
} from "redux/workspaceSlice";
import {
  init,
  // setIsAlerts,
  // setIsMessages,
  setAppSettingLoad,
} from "redux/appsettingSlice";
import { setProjectsList } from "redux/projectSlice";

const useUpdateSettingData = () => {
  const dispatch = useDispatch();
  const [settingsUploaded, setSettingsUploaded] = useState(false);
  const onUpdateSettingData = async (workspaceId, projectId) => {
    dispatch(setAppSettingLoad({ app_setting_load: true }));
    setSettingsUploaded(false);
    getAppSettingData(workspaceId, projectId)
      .then((res) => {
        // console.log(res.data, "useUpdateSettingData");
        if (res?.data?.error) {
          res?.data?.error === "No active session found for that account_id."
            ? (window.location.href =
                "https://account2.task925.com/?killsession")
            : dispatch(
                setError({
                  isShow: true,
                  title: "Error",
                  message: res?.data?.error,
                })
              );
          return;
        }
        const settingsData = res?.data?.app_settings;
        dispatch(init(settingsData));
        dispatch(fetchWorkspaces(settingsData.workspaces ? settingsData.workspaces : []));
        dispatch(setProjectsList(settingsData.projects ? settingsData.projects : []));
        dispatch(
          setDisplayWorkspace({
            display_workspace: workspaceId
              ? workspaceId
              : settingsData.display_workspace,
          })
        );
        dispatch(
          setDisplayProject({
            display_project: projectId
              ? projectId
              : settingsData.display_project,
          })
        );
        // dispatch(setIsMessages({ isMessages: settingsData.messages }));
        // dispatch(setIsAlerts({ isAlerts: settingsData.alerts }));
      })
      .catch((err) => {
        console.log(err, "settings-err-workspace-detail");
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err?.toString(),
          })
        );
      })
      .finally(() => {
        dispatch(setAppSettingLoad({ app_setting_load: false }));
        setSettingsUploaded(true);
      });
  };

  return { onUpdateSettingData, settingsUploaded };
};

export default useUpdateSettingData;
