import React from "react";
import PropTypes from "prop-types";
import { Chrono } from "react-chrono";

const ChronoTimeline = ({ values = [], mode }) => {
  const items = values.map((item) => {
    return {
      // title: item.timeline_date,
      // date: item.timeline_date,
      // cardTitle: item.timeline_text,
      cardSubtitle: item.timeline_date,
      // cardDetailedText: item.timeline_text
    };
  });
  const customContent = [];
  values.forEach((item, index) =>
    customContent.push(
      <div key={index} style={{ width: "100%" }}>
        <p style={{ fontSize: "14px", color: "var(--text-color)" }}>
          {item.timeline_text}
        </p>
      </div>
    )
  );

  // console.log(values, items, 'timeline -values-items')
  return (
    <div className="d-flex justify-content-center w-100">
      <div
        style={{
          width: "100%",
          height: mode === "HORIZONTAL" ? "fit-content" : "800px",
        }}
        onClick={(e) => e.preventDefault()}
      >
        {customContent.length > 0 && (
          <Chrono
            items={items}
            mode={mode}
            theme={{
              primary: "grey",
              secondary: "white",
              // cardBgColor: 'yellow',
              titleColor: "black",
              titleColorActive: "black",
            }}
            activeItemIndex={0}
            fontSize={14}
            itemWidth={150}
            cardHeight={120}
            // cardWidth={300}
            // scrollable={{ scrollbar: true }}
            useReadMore={false}
            // disableAutoScrollOnClick={true}
            fontSizes={{
              cardSubtitle: "12px",
              cardText: "12px",
              cardTitle: "14px",
              title: "14px",
            }}
            showAllCardsHorizontal={true}
            // borderLessCards={true}
          >
            {customContent}
          </Chrono>
        )}
      </div>
    </div>
  );
};
ChronoTimeline.propTypes = {
  mode: PropTypes.string,
  values: PropTypes.array,
};
export default ChronoTimeline;
