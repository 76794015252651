import React from "react";
import PropTypes from "prop-types";
import { Accordion, Form } from "react-bootstrap";
import TagsSettingsMobile from "components/TagsSettingsMobile";
// import FieldsSettings from "components/FieldsSettings";
// import AgilesSettings from "components/AgilesSettings";

const MobileAcordion = ({
  workspaceId,
  projectId,
  openState,
  handleColorClick,
  disabled,
}) => {
  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {" "}
          <Form.Label>TAGS</Form.Label>
        </Accordion.Header>
        <Accordion.Body>
          {" "}
          <TagsSettingsMobile
            workspaceId={workspaceId}
            projectId={projectId}
            openState={openState}
            handleColorClick={handleColorClick}
            disabled={disabled}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Form.Label>Fields</Form.Label>
        </Accordion.Header>
        <Accordion.Body>Fields is currently under development</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Form.Label>Agile sections</Form.Label>
        </Accordion.Header>
        <Accordion.Body>
          Agile sections is currently under development
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
MobileAcordion.propTypes = {
  workspaceId: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openState: PropTypes.object,
  handleColorClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export default MobileAcordion;
