import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import ProjectSingle from "./ProjectSingle";

const ProjectList = ({ workspaceId }) => {
  const [activeIndex, setActiveIndex] = useState("");
  const display_project = useSelector(
    (state) => state.workspaces.display_project
  );
  const projects = useSelector((state) => state.workspaces.projects);
// console.log(projects,'projects-ProjectSingle')
  useEffect(() => {
    let index = projects
      .findIndex((project) => {
        return project._id === display_project;
      })
      .toString();
    setActiveIndex(index);
  }, [display_project, projects]);
  
  return (
    <Accordion activeKey={activeIndex} className="my-3">
      {projects.map((project, i) => (
        <ProjectSingle
          key={project._id}
          project={project}
          index={i}
          workspaceId={workspaceId}
          accordionActiveIndex={activeIndex}
        />
      ))}
    </Accordion>
  );
};
ProjectList.propTypes = {
  workspaceId: PropTypes.string,
};
export default ProjectList;
