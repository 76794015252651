import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import FileUpload from "components/FileUpload";

const FileUploadModal = ({
  attachmentFile,
  handleClose,
  control,
  onFileNameChanged,
  setEncodedFile1,
  setEncodedFile2,
  setEncodedFile3,
  setEncodedFile4,
}) => {
  const [fileName, setFileName] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [isEncoded, setIsEncoded] = useState(true);

  const encodeFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onSubmit = () => {
    if (fileName === "") {
      setTitleError(true);
      return;
    } else if (isEncoded) {
      setFileError(true);
      return;
    } else {
      onFileNameChanged(attachmentFile.inputName, fileName);
      handleClose();
    }
  };
  // console.log(attachmentFile, "attachmentFile.inputName")
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Document title"
          value={fileName}
          onChange={(e) => {
            const name = e.target.value;
            name && setTitleError(false);
            setFileName(name);
          }}
        />
        {titleError && (
          <Form.Text className="ms-2 text-danger">
            File name is required.
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Controller
          name={attachmentFile?.inputName}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <FileUpload
              isClose={false}
              onChange={async (e) => {
                const file = e.target.files[0];
                // console.log(file, "file uploaded")
                field.onChange(file);
                const encoded = await encodeFile(file);
                setIsEncoded(file ? false : true);
                setFileError(file ? false : true);
                // console.log(encoded, "encoded-FileUploaded")
                attachmentFile.inputName === "attachment01ISfile" &&
                  setEncodedFile1(encoded);
                attachmentFile.inputName === "attachment02ISfile" &&
                  setEncodedFile2(encoded);
                attachmentFile.inputName === "attachment03ISfile" &&
                  setEncodedFile3(encoded);
                attachmentFile.inputName === "attachment04ISfile" &&
                  setEncodedFile4(encoded);
              }}
            />
          )}
        />
        {fileError && (
          <Form.Text className="ms-2 text-danger">File is required.</Form.Text>
        )}
      </Form.Group>

      <div className="d-flex justify-content-center">
        <Button variant="primary" onClick={onSubmit} size="sm">
          Done
        </Button>
      </div>
    </>
  );
};

FileUploadModal.propTypes = {
  attachmentFile: PropTypes.object,
  handleClose: PropTypes.func,
  control: PropTypes.object,
  onFileNameChanged: PropTypes.func,
  setEncodedFile1: PropTypes.func,
  setEncodedFile2: PropTypes.func,
  setEncodedFile3: PropTypes.func,
  setEncodedFile4: PropTypes.func,
};
export default FileUploadModal;
