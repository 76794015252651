import axios from './axios';

export const getFields = (workspaceId, projectId) => {

  const params = new URLSearchParams();
  if (workspaceId) params.append("workspace_id", workspaceId);
  if (projectId) params.append("project_id", projectId);

  return axios.get('app/get_fields', {
    params
  })
};

export const addField = payload => axios.post('app/add_field', payload);

export const editField = payload => axios.patch('app/edit_field', payload);

export const deleteField = payload => axios.delete('app/delete_field', { data: payload });

export const addTaskFieldLink = payload => axios.post('app/add_task_field_link', payload);

export const editTaskFieldLink = payload => axios.patch('app/edit_task_field_link', payload);