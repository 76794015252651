import axios from './axios';

export const getCalendar = (workspaceId) => {

    const params = new URLSearchParams();
    if (workspaceId) params.append("workspace_id", workspaceId);
  
    return axios.get('app/get_calendar', {
      params
    })
  };

export const addLeaveRequest = payload => axios.post('app/add_leave_request', payload);

export const editLeaveRequest = payload => axios.patch('app/edit_leave_request', payload);

export const addSpecaialDays = payload => axios.post('app/add_special_days', payload);

export const editSpecialDay = payload => axios.patch('app/edit_special_day', payload);

