import React, { useState, useEffect, useRef } from "react";
import sharedStyles from "components/shared.module.scss";
import styles from "features/members/styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "redux/workspaceSlice";
import { getViewReport } from "API/reports";
import { Spinner } from "react-bootstrap";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { setIsUpdateProject } from "redux/projectSlice";
import {
  dispatchIsShowTaskDetail,
  dispatchOpenTaskId,
  dispatchIsShowSubTaskDetail,
  dispatchOpenSubTaskId,
  dispatcOpenTaskLoading,
} from "redux/taskSlice";
import useMobileWindow from "hooks/useMobileWindow";

const SearchInput = () => {
  const isMobile = useMobileWindow();

  const error = useSelector((state) => state.workspaces.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onUpdateSettingData, settingsUploaded } = useUpdateSettingData();
  const { fetchWorkspaceData } = useGetWorkspaceData();

  const [searchValue, setSearchValue] = useState("");
  const [taskList, setTaskList] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [taskId, setTaskId] = useState(null);
  const [subTaskId, setSubTaskId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const inputGroupTextRef = useRef(null);

  useEffect(() => {
    if (settingsUploaded) {
      //this error is received from app/settings
      if (error?.message !== "No project found for that project_id.") {
        navigate("/");
        dispatch(setIsUpdateProject(true));
        if (taskId) {
          dispatch(dispatchOpenTaskId(taskId));
          dispatch(dispatchOpenSubTaskId(subTaskId));
          subTaskId
            ? dispatch(dispatchIsShowSubTaskDetail(true))
            : dispatch(dispatchIsShowTaskDetail(true));
            setTaskId(null);
        }
      } else {
        dispatch(dispatcOpenTaskLoading(false));
      }
    }
  }, [settingsUploaded, taskId, subTaskId]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    const inputElement = e.target.nodeName.toLowerCase();
    const clickedOnSearchIcon = e.target.closest(".searchIcon");
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      inputElement !== "input" &&
      !clickedOnSearchIcon
    ) {
      setSearchValue("");
      setIsDropdownOpen(false);
    }
  };

  const ViewFilters = async () => {
    // console.log("ViewFilters Searching for:", searchValue);
    setIsLoading(true);
    try {
      await getViewReport({
        text_search: searchValue,
      })
        .then((res) => {
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res?.data?.error,
              })
            );
            return;
          }
          if (res.data.success === 1) {
            // console.log(res.data.list, "res.data-Seacrh Input ViewReport");
            setTaskList(res.data.list);
            setIsDropdownOpen(true);
          }
        })
        .catch((err) => {
          console.log(err, "Error-catch Get ViewReport");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err?.response?.data?.error,
            })
          );
        })
        .finally(() => setIsLoading(false));
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  return (
    <>
      <Form.Group
        style={{ position: "relative", zIndex: 1, marginRight: isMobile ? "" : "8px", width: '100%' }}
      >
        <InputGroup style={{ minWidth: "210px" }}>
          <Form.Control
            placeholder="Task number or name..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                ViewFilters();
              }
            }}
          />
          <InputGroup.Text
            onClick={ViewFilters}
            style={{ cursor: "pointer" }}
            ref={inputGroupTextRef}
            className="searchIcon"
          >
            {isLoading ? (
              <Spinner
                animation="border"
                variant="secondary"
                style={{ borderWidth: "3px", width: "17px", height: "17px" }}
              />
            ) : (
              <Search />
            )}
          </InputGroup.Text>
        </InputGroup>
        {isDropdownOpen && taskList.length > 0 ? (
          <div className={sharedStyles.searchDropdown} ref={dropdownRef}>
            <ul style={{ marginBottom: "0px" }}>
              {taskList.map((task, index) => (
                <li
                  key={index}
                  title={task.name}
                  className={styles.memberTaskName}
                  onClick={async () => {
                    try {
                      setTaskId(
                        task.parent_task_id ? task.parent_task_id : task._id
                      );
                      setSubTaskId(task.parent_task_id ? task._id : null);
                      dispatch(dispatcOpenTaskLoading(true));
                      await onUpdateSettingData(
                        task.workspaceISbb_t925_workspacesID,
                        task.projectISbb_t925_projectsID
                      );
                      await fetchWorkspaceData(
                        task.workspaceISbb_t925_workspacesID
                      );
                      setSearchValue("");
                      setIsDropdownOpen(false);
                    } catch (error) {
                      console.error("Error fetching project data:", error);
                    }
                  }}
                >
                  TK({task._id}) - {task.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          isDropdownOpen && (
            <div
              className={`${sharedStyles.searchDropdown} d-flex justify-content-center`}
              ref={dropdownRef}
            >
              <span> No tasks to search</span>
            </div>
          )
        )}
      </Form.Group>
    </>
  );
};

export default SearchInput;
