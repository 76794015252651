import React, { useEffect, useState } from "react";
import "./Task.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import { PaperclipIconButton } from "components/IconButton";
import { FaUser } from "react-icons/fa";
import AttachmentLink from "components/AttachmentLink";
import { XIconButton, SendIconButton } from "components/IconButton";
import { setError } from "redux/workspaceSlice";
import { getTaskMessages, addTaskMessage, deleteTaskMessage } from "API/tasks";
import DotsSpinner from "components/DotsSpinner";
import { isJSONRegExp } from "services/helpers";
import { BsChatDots } from "react-icons/bs";
import { format } from "date-fns";
import MultiSelect from "components/MultiSelect";
// import LoadingParticipants from "components/LoadingParticipants";
import DraftEditor from "components/DraftEditor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { MdOutlineDelete } from "react-icons/md";
import ConfirmModal from "components/ConfirmModal";
import PagePagination from "components/Pagination";

const Message = ({ taskId }) => {
  const visibleTo = useSelector((state) => state.appsetting.message_visible_to);
  const recipients = useSelector(
    (state) => state.appsetting.message_recipients
  );
  const disabled= taskId === null

  const dispatch = useDispatch();

  const [content, setContent] = useState(EditorState.createEmpty());
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [visible, setVisible] = useState([]);
  const [recipient, setRecipient] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isAddMessageLoading, setIsAddMessageLoading] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [messageApiKey, setMessageApiKey] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [isSpinner, setIsSpinner] = useState(false);

  useEffect(() => {
    if (taskId) getMessagesData(1);
  }, [taskId]);

  const getMessagesData = async (page) => {
    // formData.append("api_method", "get_task_messages");
    // formData.append("task_id", taskId);
    setIsSpinner(true);
    await getTaskMessages(taskId, page, 5)
      .then((res) => {
        // console.log(res, res.data, "get-task-messages");
        setMessages(res.data.list);
        setTotalPages(res.data.pages);
        setActivePage(+res.data.page);
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, "task-message-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message,
            })
          );
        }
      })
      .finally(() => setIsSpinner(false));
  };

  const onNewFileClicked = () => {
    if (attachedFiles.length < 3) setAttachedFiles([...attachedFiles, ""]);
  };

  const onRecipientChanged = (data) => {
    const valuesArray = data.map((el) => el.value);

    setRecipient(valuesArray);
  };
  const onVisibleChanged = (data) => {
    const valuesArray = data.map((el) => el.value);

    setVisible(valuesArray);
  };

  const onDeleteAttachFileClick = (index) => {
    setAttachedFiles(attachedFiles.filter((attach, i) => i !== index));
  };

  const onAttachFileChanged = (e) => {
    const inputName = e.target.name;
    const attachIndex = parseInt(inputName);

    const file = e.target.files[0];
    if (file === undefined) {
      setAttachedFiles(
        attachedFiles.map((attach, i) => {
          if (i === attachIndex) return "";
          return attach;
        })
      );
      return;
    }

    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // logs data:<type>;base64,wL2dvYWwgbW9yZ...
      setAttachedFiles(
        attachedFiles.map((attach, i) => {
          if (i === attachIndex) return reader.result;
          return attach;
        })
      );
    };
    reader.readAsDataURL(file);
  };

  const onSendMessageClicked = async () => {
    setIsAddMessageLoading(true);
    // formData.append("api_method", "add_task_message");
    const formData = {
      task_id: taskId,
      visible_to: JSON.stringify(visible),
      send_email_to: JSON.stringify(recipient),
      messageISsmallplaintextbox: JSON.stringify(
        convertToRaw(content.getCurrentContent())
      ),
      page: 1,
      limit: 5,
    };

    for (let i = 0; i < attachedFiles.length; i++) {
      if (attachedFiles[i] !== "") {
        const fieldkey = "attachment0" + (i + 1) + "ISfile";
        formData[fieldkey] = attachedFiles[i];
      }
    }
    await addTaskMessage(formData)
      .then((res) => {
        if (res?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res?.data?.error,
            })
          );
          return;
        }
        console.log(res, res.data, "add-task-messages");
        setMessages(res.data.list);
        setTotalPages(res.data.pages);
        setActivePage(+res.data.page);
        setAttachedFiles([]);
        setContent("");
        setVisible("");
        setRecipient("");
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, "add-message-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message,
            })
          );
        }
      })
      .finally(() => setIsAddMessageLoading(false));
  };

  const openImageModal = (src) => {
    setSelectedImageSrc(src);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setSelectedImageSrc("");
    setIsImageModalOpen(false);
  };

  const parseEditorTextToHtml = (htmlContent) => {
    const parsedMessage = stateToHTML(convertFromRaw(JSON.parse(htmlContent)));
    const replaceLinksWithATags = parsedMessage.replace(
      /(?<!<a\s+(?:[^>]*?\s+)?href=(['"]))((?:https?|ftp):\/\/[^\s>,]+)(?:&nbsp;|;)?(?=$|(&nbsp;|<\/a>|[\s.,;?!'"()]))/g,
      '<a href="$2">$2</a>'
    );
    const finalContent = replaceLinksWithATags
      .replace(/&nbsp/g, "")
      .replace(/<\/a>;/g, "</a> ")
      .replace(/<\/a>,/g, "</a> ")
      .trim();
    return finalContent;
  };

  const onDeleteMessage = async () => {
    await deleteTaskMessage({ _apikey: messageApiKey, page: 1, limit: 5 })
      .then((res) => {
        const data = res?.data;
        console.log(data, "res.data-onDeleteMessage");
        if (data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: data?.error,
            })
          );
          return;
        }
        setMessages(data.list);
        setTotalPages(data.pages);
        setActivePage(+data.page);
      })
      .catch((err) => console.log(err, "err-delete-message"))
      .finally(() => setIsDeleteConfirm(false));
  };
  // console.log(messages, "messages");
  return (
    <>
      {/* {isSpinner
      ? (
        <div className="">
          <LoadingParticipants />
        </div>
      ) : ( */}
        <>
          <DraftEditor
            value={content}
            onDescriptionChange={(data) => setContent(data)}
            isDisable={disabled || isAddMessageLoading}
            defaultSize={{ width: "auto", height: "auto" }}
          />
          <div className="messageWrapper">
            {attachedFiles.length < 3 && (
              <PaperclipIconButton
                onClick={onNewFileClicked}
                disabled={disabled}
                className="mt-2 paperIcon"
                text={
                  <span className="newAttachment ms-2">New Attachment</span>
                }
              />
            )}
            <div className="d-flex flex-column w-100">
              <div className="attachmentFields">
                {attachedFiles.map((attached, i) => (
                  <div className="d-flex mt-2 attachedFile" key={i}>
                    <Form.Control
                      type="file"
                      name={i}
                      className="me-2"
                      onChange={onAttachFileChanged}
                    />
                    <XIconButton onClick={() => onDeleteAttachFileClick(i)} />
                  </div>
                ))}
              </div>
              <div className="selectBlock">
                <div className="mt-2 selectSend">
                  <MultiSelect
                    onChange={(data) => onRecipientChanged(data)}
                    options={recipients}
                    selected={recipient}
                    placeholder="Send to..."
                    disabled={disabled}
                  />
                </div>
                <div className="mt-2 selectVisible">
                  <MultiSelect
                    multiple
                    onChange={(data) => onVisibleChanged(data)}
                    options={visibleTo}
                    selected={visible}
                    placeholder="Visible to..."
                    disabled={disabled}
                  />
                </div>
                <SendIconButton
                  disabled={
                    content === EditorState.createEmpty() ||
                    visible.length === 0 ||
                    recipient.length === 0
                  }
                  onClick={onSendMessageClicked}
                  className="mt-2 sendIcon"
                  text={<span className="newAttachment me-2">Send</span>}
                />
              </div>
            </div>
          </div>

          <div className="mt-3">
            {isAddMessageLoading && <DotsSpinner />}
            {isSpinner ? (
              <div
                className="d-flex justify-content-center align-items-center w-100 mt-3"
                style={{ minHeight: "250px" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              messages.map((message, i) => (
                <div
                  key={i}
                  className="mt-3"
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div className="d-flex align-items-start fs-12">
                    <FaUser className="me-2 mt-1" />
                    <span>
                      {format(new Date(message._dateadded), "dd MMM yyyy")} -
                      via {message.name} ({message.from_email})
                    </span>
                  </div>
                  <div className="my-2 d-flex justify-content-between">
                    <div className="fs-14 mb-0 d-flex align-items-start">
                      <div style={{ width: "14px", height: "14px" }}>
                        <BsChatDots />
                      </div>
                      <span
                        className="ms-2 zoom-in editor-html"
                        dangerouslySetInnerHTML={{
                          __html: isJSONRegExp(
                            message.messageISsmallplaintextbox
                          )
                            ? parseEditorTextToHtml(
                                message.messageISsmallplaintextbox
                              )
                            : message.messageISsmallplaintextbox,
                        }}
                        onClick={(e) => {
                          if (e.target.tagName === "IMG") {
                            openImageModal(e.target.src);
                          }
                        }}
                      ></span>
                    </div>
                    {hoveredIndex === i && message.delete_access === "1" ? (
                      <div
                        style={{ alignSelf: "flex-end" }}
                        className="d-flex align-items-end"
                        onClick={() => {
                          setMessageApiKey(message._apikey);
                          setIsDeleteConfirm(true);
                        }}
                      >
                        <MdOutlineDelete
                          style={{
                            color: "#dc3545",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ minWidth: "18px", height: "100%" }}></div>
                    )}
                  </div>
                  <div className="d-flex ms-3">
                    {[1, 2, 3].map((number) => {
                      const attachment = message[`attachment0${number}ISfile`];

                      if (attachment) {
                        return (
                          <AttachmentLink
                            key={number}
                            link={attachment}
                            className="ms-2"
                          >
                            File {number}
                          </AttachmentLink>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              ))
            )}
            {!isSpinner && totalPages > 1 && (
              <div className="d-flex justify-content-center w-100">
                <PagePagination
                  totalPages={totalPages}
                  onPaginationClick={getMessagesData}
                  activePage={activePage}
                />
              </div>
            )}
          </div>
        </>
      {isImageModalOpen && (
        <div
          style={{
            position: "fixed",
            zIndex: 999,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={closeImageModal}
        >
          <img
            src={selectedImageSrc}
            alt="your file"
            style={{ maxWidth: "80%", maxHeight: "80%", borderRadius: "5px" }}
          />
        </div>
      )}
      <ConfirmModal
        isShow={isDeleteConfirm}
        title={"Delete Message"}
        message={"Are you sure you want to delete this message?"}
        onDelete={onDeleteMessage}
        onClose={setIsDeleteConfirm}
      />
    </>
  );
};

Message.propTypes = {
  taskId: PropTypes.string,
};
export default Message;
