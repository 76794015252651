import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PersonCircle } from "react-bootstrap-icons";
import { MdDragIndicator } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
// import { editTask } from "API/tasks";
import { setError } from "redux/workspaceSlice";
// import { sortByField } from "services/helpers";
import { Badge } from "react-bootstrap";
import TaskDetail from "features/task/TaskDetail";
import useGetProjectData from "hooks/useGetProjectData";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { setIsUpdateProject } from "redux/projectSlice";
import { editTeamMember } from "API/members";

const MemberCard = ({ member }) => {
  // console.log(member, "member");
  const error = useSelector((state) => state.workspaces.error);
  const { name, tasks, account_id: accountId } = member;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { onUpdateSettingData } = useUpdateSettingData();
  const { fetchWorkspaceData } = useGetWorkspaceData();
  const { fetchProjectData } = useGetProjectData();

  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [projectId, setProjectId] = useState(null);

  const [reOrderedTasks, setReOrderedTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setReOrderedTasks(tasks);
  }, [tasks]);

  useEffect(() => {
    if (error?.message === "No task found for that ID.") {
      setShowTaskDetail(false);
    }
  }, [error?.message]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      reOrderedTasks,
      result.source.index,
      result.destination.index
    );

    const formData = {
      account_id: accountId,
      task_order: items.map((item) => item._id),
    };
    setReOrderedTasks(items);
    setLoading(true);
    await editTeamMember(formData)
      .then((res) => {
        console.log(res?.data, "res-edit-member-reorder");
        if (res?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res?.data?.error,
            })
          );
          setReOrderedTasks(tasks);
        } else {
          setReOrderedTasks(res?.data?.tasks);
        }
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, err?.response?.status, "edit-project-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error,
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };
  console.log(reOrderedTasks, "reOrderedTasks-My team");
  return (
    <>
      <div className={`${styles.memberCard} card`}>
        <div className={styles.memeberCardHeader}>
          <PersonCircle color="#344050" size={20} />
          <span className="ms-2">{name}</span>
        </div>
        <CircleSpinnerOverlay
          loading={loading}
          message="Saving..."
          color="#5e6e82"
          overlayColor="rgba(0,0,0,0.3)"
        />
        {reOrderedTasks.length === 0 ? (
          <div className={styles.memberTasks}>This member has no tasks.</div>
        ) : (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {reOrderedTasks.map((task, index) => (
                      <Draggable
                        key={task._id ? task._id : uuidv4()}
                        draggableId={task._id ? task._id : uuidv4()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            key={task._id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className={styles.memberTasks} key={task._id}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  {(task.permission === "Owner" ||
                                    task.permission === "Admin" ||
                                    task.permission === "Project Manager") && (
                                    <span
                                      style={{
                                        width: "25px",
                                        marginBottom: "4px",
                                        cursor: "grab",
                                      }}
                                      {...provided.dragHandleProps}
                                    >
                                      <MdDragIndicator />
                                    </span>
                                  )}
                                  <span>TK({task._id})</span>
                                </div>
                                <div className="d-flex flex-wrap">
                                  {task.task_tags.slice(0, 2).map(
                                    ({ tag_color, tag_name }) =>
                                      tag_color && (
                                        <Badge
                                          key={tag_color}
                                          pill
                                          bg={tag_color}
                                          style={{
                                            backgroundColor: tag_color,
                                          }}
                                          className={styles.taskBadge}
                                        >
                                          {tag_name}
                                        </Badge>
                                      )
                                  )}
                                </div>
                              </div>
                              <span
                                className="fw-600 my-2 hover-grey"
                                onClick={async () => {
                                  try {
                                    await onUpdateSettingData(
                                      task.workspaceISbb_t925_workspacesID,
                                      task.projectISbb_t925_projectsID
                                    );
                                    await fetchWorkspaceData(
                                      task.workspaceISbb_t925_workspacesID
                                    );
                                    dispatch(setIsUpdateProject(true));
                                    navigate("/");
                                  } catch (error) {
                                    console.error(
                                      "Error fetching project data:",
                                      error
                                    );
                                  }
                                }}
                              >
                                {task.project_name}
                              </span>
                              <span
                                className={styles.memberTaskName}
                                onClick={async () => {
                                  try {
                                    await fetchProjectData(
                                      task.projectISbb_t925_projectsID
                                    );
                                    setTaskId(task._id);
                                    setProjectId(
                                      task.projectISbb_t925_projectsID
                                    );
                                    setShowTaskDetail(true);
                                  } catch (error) {
                                    console.error(
                                      "Error fetching project data:",
                                      error
                                    );
                                  }
                                }}
                              >
                                {task.name}
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </div>
      {showTaskDetail && (
        <TaskDetail
          onClose={() => setShowTaskDetail(false)}
          projectId={projectId}
          id={taskId}
        />
      )}
    </>
  );
};

MemberCard.propTypes = {
  member: PropTypes.object,
};

export default MemberCard;
