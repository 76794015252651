import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import { formatDate} from "services/helpers";

const MultipleDatePicker = ({ formData, setFormData }) => {
  const [selectedDates, setSelectedDates] = useState(formData.dates || []);

  useEffect(() => {
    setSelectedDates(formData.dates || []);
  }, [formData.dates]);

  // const handleDateSelect = (date) => {
  //   const existingIndex = selectedDates.findIndex(
  //     (selectedDate) => selectedDate.date.getTime() === date.getTime()
  //   );
  
  //   if (existingIndex !== -1) {
  //     const updatedDates = [...selectedDates];
  //     updatedDates.splice(existingIndex, 1);
  //     setFormData({
  //       ...formData,
  //       dates: updatedDates,
  //       range_date: [updatedDates[0].date, updatedDates[updatedDates.length - 1].date],
  //     });
  //     setSelectedDates(updatedDates);
  //   } else {
  //     const holidayName = "";
  //     const newDate = [...selectedDates, { date, name: holidayName }];
  //     setSelectedDates(newDate);
  //     setFormData({
  //       ...formData,
  //       dates: newDate,
  //       range_date: [newDate[0].date, newDate[newDate.length - 1].date],
  //     });
  //   }
  // };
  
  // const handleHolidayNameChange = (date, holidayName) => {
  //   const updatedDates = selectedDates.map((selectedDate) =>
  //     selectedDate.date.getTime() === date.getTime()
  //       ? { ...selectedDate, name: holidayName }
  //       : selectedDate
  //   );
  //   setSelectedDates(updatedDates);
  //   setFormData({
  //     ...formData,
  //     dates: updatedDates,
  //     range_date: [updatedDates[0].date, updatedDates[updatedDates.length - 1].date],
  //   });
  // };
  const handleDateSelect = (date) => {
    const existingIndex = selectedDates.findIndex(
      (selectedDate) => selectedDate.date.getTime() === date.getTime()
    );
  
    if (existingIndex !== -1) {
      const updatedDates = [...selectedDates];
      updatedDates.splice(existingIndex, 1);
      setFormData({
        ...formData,
        dates: updatedDates,
        range_date: [
          formatDate(updatedDates[0].date),
          formatDate(updatedDates[updatedDates.length - 1].date)
        ],
      });
      setSelectedDates(updatedDates);
    } else {
      const holidayName = "";
      const newDate = [...selectedDates, { date, name: holidayName }];
      setSelectedDates(newDate);
      setFormData({
        ...formData,
        dates: newDate,
        range_date: [
          formatDate(newDate[0].date),
          formatDate(newDate[newDate.length - 1].date)
        ],
      });
    }
  };
  
  const handleHolidayNameChange = (date, holidayName) => {
    const updatedDates = selectedDates.map((selectedDate) =>
      selectedDate.date.getTime() === date.getTime()
        ? { ...selectedDate, name: holidayName }
        : selectedDate
    );
    setSelectedDates(updatedDates);
    setFormData({
      ...formData,
      dates: updatedDates,
      range_date: [
        formatDate(updatedDates[0].date),
        formatDate(updatedDates[updatedDates.length - 1].date)
      ],
    });
  };
  return (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex flex-column">
        <Form.Label>Selected Dates:</Form.Label>
        <ul>
          {selectedDates.map((item, index) => (
            <li key={index} className="d-flex flex-column w-100">
              <span>{item.date.toDateString()}</span>
              <Form.Group className="">
                <Form.Control
                  className="w-100"
                  style={{ width: "230px" }}
                  placeholder="Holiday name"
                  value={item.name}
                  onChange={(e) =>
                    handleHolidayNameChange(item.date, e.target.value)
                  }
                />
              </Form.Group>
            </li>
          ))}
        </ul>
      </div>

      <DatePicker
        selected={null}
        onChange={handleDateSelect}
        inline
        calendarClassName="date-picker-calendar"
        dateFormat="dd/MM/yyyy"
        placeholderText="Select Date"
        isClearable
        selectsRange={false}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        monthsShown={1}
        selectsMultiple
        customInput={<DatePickerCustomInput />}
      />
    </div>
  );
};

MultipleDatePicker.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default MultipleDatePicker;