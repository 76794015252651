import React from "react";

const LoadingFields = () => {

  return (
    <div className="d-flex">
      <div className="animated-background mx-2" style={{ width: '40%' }}>
      </div>
      <div className="animated-background mx-2" style={{ width: '20%' }}>
      </div>
      <div className="animated-background mx-2" style={{ width: '20%' }}>
      </div>
      <div className="animated-background mx-2" style={{ width: '20%' }}>
      </div>
    </div>
  )
}

export default LoadingFields