import React, { useEffect, useState } from "react";
import "features/styles.css";
import PropTypes from "prop-types";
import { Form, Button, Collapse } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TagsSettings from "components/TagsSettings";
import FieldsSettings from "components/FieldsSettings";
import Participants from "components/Participants";
import { useSelector, useDispatch } from "react-redux";
import { addWorkspace, editWorkspace } from "API/workspaces";
import ButtonSpinner from "components/ButtonSpinner";
import { setError } from "redux/workspaceSlice";
import AgilesSettings from "components/AgilesSettings";
import { Person } from "react-bootstrap-icons";
import useUpdateSettingData from "hooks/useUpdateSettingData";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import useTabletWindow from "hooks/useTabletWindow";
import useMobileWindow from "hooks/useMobileWindow";
import MobilAccordion from "components/MobileAcordion";

const WorkspaceDetail = ({ onClose, id }) => {
  const isTablet = useTabletWindow();
  const isMobile = useMobileWindow();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchWorkspaceData } = useGetWorkspaceData();
  const { onUpdateSettingData } = useUpdateSettingData();

  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const [name, setName] = useState("");
  const [initName, setInitName] = useState("");
  const [openCollapse, setOpenCollapse] = useState(false);
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(null);
  const [errorName, setErrorName] = useState("");
  const [isSpinnerLoad, setIsSpinnerLoad] = useState(false);

  // -------Color selecotr open/close----------------------------------------------------------------------------------------------------
  const [openState, setOpenState] = useState({});
  const handleColorClick = (categoryIndex, tagIndex) => {
    setOpenState((prevState) => {
      const updatedOpenState = { ...prevState };
      const key = `${categoryIndex}-${tagIndex}`;

      Object.keys(updatedOpenState).forEach((stateKey) => {
        if (stateKey !== key) {
          updatedOpenState[stateKey] = false;
        }
      });

      updatedOpenState[key] = !prevState[key];

      return updatedOpenState;
    });
  };
  const handleOutsideClick = () => {
    setOpenState((prevState) => {
      const updatedOpenState = { ...prevState };

      Object.keys(updatedOpenState).forEach((stateKey) => {
        updatedOpenState[stateKey] = false;
      });

      return updatedOpenState;
    });
  };
  // --------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (id !== null) {
      let initWorkspaceName = workspaces.find(
        (workspace) => workspace._id === id
      ).name;
      setName(initWorkspaceName);
      setInitName(initWorkspaceName);
      setCurrentWorkspaceId(id);
    }
  }, [id]);

  const title = id === null ? "New Workspace" : "Edit Workspace";

  const addEditWorkspace = async () => {
    if (name === "") {
      // setErrorName("The Name field is required");
      return;
    }
    if (currentWorkspaceId === null) {
      await addWorkspace({ name: name })
        .then((res) => {
          // console.log(res, res.data, "add-worspace");
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
            return;
          }
          let newWorkspaceId = res?.data?.new_workspace_id.toString();
          setName(name);
          setInitName(name);
          setCurrentWorkspaceId(newWorkspaceId);
          fetchWorkspaceData(newWorkspaceId);
          onUpdateSettingData(newWorkspaceId, null);
          setErrorName("");
          location.pathname !== "/" && navigate("/");
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "add-workspace-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error,
              })
            );
          }
        });
    }
  };

  const onDoneWorkspace = async () => {
    if (currentWorkspaceId && name === "") {
      setErrorName("The Name field is required");
      return;
    }
    if (currentWorkspaceId && initName !== name) {
      setIsSpinnerLoad(true);
      await editWorkspace({
        workspace_id: currentWorkspaceId,
        name: name,
      })
        .then((res) => {
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
            return;
          }
          // console.log(res.data, currentWorkspaceId, "edit-workspace");
          onUpdateSettingData(currentWorkspaceId, "0");
          fetchWorkspaceData(currentWorkspaceId);
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "edit-workspace-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error,
              })
            );
          }
        })
        .finally(() => {
          setIsSpinnerLoad(false);
        });
    }
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };
  return (
    <div className="modal-container" onClick={onDoneWorkspace}>
      <div
        className="modal-content"
        style={{ width: "85%" }}
        onClick={(event) => {
          event.stopPropagation();
          handleOutsideClick();
        }}
      >
        <Form>
          <div className="formWrapper" style={{ minHeight: "100%" }}>
            <div className="px-4 bg-white mainBlock">
              <div className="bg-white py-4 d-flex justify-content-between align-items-center">
                <span className="fs-5 fw-bold">{title}</span>
                <ButtonSpinner
                  onClick={onDoneWorkspace}
                  loading={isSpinnerLoad}
                >
                  Done
                </ButtonSpinner>
              </div>
              <div className="nameBlock mb-5">
                <Form.Group className="nameInput">
                  <Form.Label className="fs-16">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Workspace name"
                    value={name}
                    onChange={(e) => {
                      if (e.target.value.length !== 0) setErrorName(null);
                      setName(e.target.value);
                    }}
                    onBlur={addEditWorkspace}
                    className={errorName ? "border-danger" : ""}
                  />
                  {errorName && (
                    <div className="text-danger" style={{ fontSize: "12px" }}>
                      {errorName}
                    </div>
                  )}
                </Form.Group>
                <div className="btnParticipants ms-2">
                  <Button
                    variant="primary"
                    onClick={() => setOpenCollapse(!openCollapse)}
                    className="collapseBtn"
                  >
                    <Person /> Participants
                  </Button>
                </div>
              </div>
              {(isMobile || isTablet) && (
                <Collapse in={openCollapse} className="mb-5 p-3 collapseBlock">
                  <div className="border rounded w-100">
                    <Participants
                      disabled={currentWorkspaceId === null}
                      workspaceId={currentWorkspaceId}
                    />
                  </div>
                </Collapse>
              )}
              {isMobile ? (
                <MobilAccordion
                  workspaceId={currentWorkspaceId}
                  openState={openState}
                  handleColorClick={handleColorClick}
                  disabled={currentWorkspaceId === null}
                />
              ) : (
                <>
                  <TagsSettings
                    workspaceId={currentWorkspaceId}
                    openState={openState}
                    handleColorClick={handleColorClick}
                    disabled={currentWorkspaceId === null}
                  />

                  <div className="task-hr my-4"></div>

                  <Form.Group controlId="workspaceTags">
                    <Form.Label className="fs-16">Fields</Form.Label>
                    <FieldsSettings
                      disabled={currentWorkspaceId === null}
                      workspaceId={currentWorkspaceId}
                    />
                  </Form.Group>

                  <div className="task-hr my-4"></div>

                  <Form.Group className="mb-5" controlId="projectAgiles">
                    <AgilesSettings
                      workspaceId={currentWorkspaceId}
                      disabled={currentWorkspaceId === null}
                    />
                  </Form.Group>
                </>
              )}
            </div>
            <div
              className="participants"
              style={{ width: "10px", backgroundColor: "gray", opacity: 0.7 }}
            ></div>
            {!isTablet && (
              <div className="px-4 pt-5 mb-3 participants">
                <Participants
                  disabled={currentWorkspaceId === null}
                  workspaceId={currentWorkspaceId}
                />
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

WorkspaceDetail.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
};
export default WorkspaceDetail;
