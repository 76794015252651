import React from "react";
import PropTypes from "prop-types";
import styles from './shared.module.scss';
import { useContext } from "react";
import { useAccordionButton, AccordionContext } from "react-bootstrap";

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      onClick={decoratedOnClick}
      className={!isCurrentEventKey ? styles.passiveNameHover : styles.activeName}
    >
      {children}
    </div>
  );
};

ContextAwareToggle.propTypes = {
  children: PropTypes.node,
  eventKey: PropTypes.string,
  callback: PropTypes.func
};
export default ContextAwareToggle;
