import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./project.module.scss";
import {
  Accordion,
  Button,
  Card,
  // ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayProject } from "redux/workspaceSlice";
import ContextAwareToggle from "components/ContextAwareToggle";
import ProjectView from "./ProjectView";
import HambugerDropdown from "components/HambugerDropdown";
import { PROJECT_MENUITEMS, PM_PROJECT_MENUITEMS } from "services/constants";
import { BsList } from "react-icons/bs";
import { setError } from "redux/workspaceSlice";
import { HambugurMenuContext } from "services/contexts";
import Modal from "components/ModalWindow";
import AgileView from "./AgileDocument/AgileView";
import AddEditAgile from "./AgileDocument/AddEditAgile";
import useModal from "hooks/useModal";
import { getProject } from "API/projects";
import { setProject, setIsUpdateProject } from "redux/projectSlice";
import useMobileWindow from "hooks/useMobileWindow";

const ProjectSingle = ({
  project,
  index,
  workspaceId,
  accordionActiveIndex,
}) => {
  const isMobile = useMobileWindow();

  const { isUpdateProject } = useSelector((state) => state.project);
  const { isProjectChosen, display_project } = useSelector(
    (state) => state.workspaces
  );
  const { _id: projectId, permission } = project;

  const isPermission =
    permission === "Owner" ||
    permission === "Admin" ||
    permission === "Project Manager";
  const dispatch = useDispatch();
  const onItemSelected = useContext(HambugurMenuContext);
  const [isShow, openModal, closeModal] = useModal();
  const [isShowAgileView, openAgileViewModal, closeAgileViewModal] = useModal();

  const [isShowAll, setIsShowAll] = useState(false);
  const [viewStatus, setViewStatus] = useState("list");
  const [projectTasks, setProjectTasks] = useState([]);
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [tasksSelected, setTasksSelected] = useState(0);
  const [tasksTotal, setTasksTotal] = useState(0);

  useEffect(() => {
    const handleUnmount = () => {
      dispatch(setIsUpdateProject(true));
    };

    return handleUnmount;
  }, []);
  // fetch task list by projectId
  useEffect(() => {
    if (isUpdateProject && display_project === projectId) {
      fetchProjectData(display_project);
      setIsShowAll(false);
    }
  }, [display_project, isUpdateProject]);

  const fetchProjectData = async (projectId, completed = null) => {
    setIsProjectLoading(true);
    try {
      await getProject(projectId, completed)
        .then((res) => {
          // console.log(res, res.data?.project.tasks, "res-get project");
          if (res?.data?.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res?.data?.error,
              })
            );
            return;
          }
          if (res?.data?.success === 1) {
            const project = res?.data?.project;
            dispatch(setProject(project));
            setProjectTasks(project.tasks);
            setTasksSelected(+res.data.tasks_selected);
            setTasksTotal(+res.data.tasks_total);
            dispatch(setIsUpdateProject(false));
          }
        })
        .catch((err) => {
          console.log(err, "Error-catch get-project");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err?.message,
            })
          );
        })
        .finally(() => setIsProjectLoading(false));
    } catch (err) {
      console.log(err, err?.message, "err-try-catch-get-project");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err?.message,
        })
      );
    }
  };

  const onShowCompleted = async () => {
    setViewStatus("list");
    setIsShowAll(true);
    await fetchProjectData(display_project, 1);
  };

  const onHideCompleted = async () => {
    setViewStatus("list");
    setIsShowAll(false);
    await fetchProjectData(display_project);
  };

  const onCollapseHeaderClicked = async (eventKey) => {
    if (eventKey === accordionActiveIndex) {
      dispatch(
        setDisplayProject({
          display_project: "-1",
        })
      );
      return;
    }
    dispatch(setDisplayProject({ display_project: projectId }));
    setViewStatus("list");
    setIsShowAll(false);
    await fetchProjectData(projectId);
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header
          className={
            // display_project === projectId ? "active" : "inactive"
            accordionActiveIndex === index.toString() ? "active" : "inactive"
          }
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {isPermission && (
                <HambugerDropdown
                  menuItems={
                    permission === "Project Manager"
                      ? PM_PROJECT_MENUITEMS
                      : PROJECT_MENUITEMS
                  }
                  itemId={project._id}
                  parentId={workspaceId}
                  isExistedElements={!!tasksTotal}
                  onAction={() => onShowCompleted}
                />
              )}
              {isProjectChosen ? (
                <span
                  style={{
                    color: "var(--text-color)",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: 1.2,
                  }}
                >
                  {project.name}
                </span>
              ) : (
                <ContextAwareToggle
                  eventKey={index.toString()}
                  callback={onCollapseHeaderClicked}
                >
                  {project.name}
                </ContextAwareToggle>
              )}
            </div>

            {accordionActiveIndex === index.toString() && (
              <div className="d-flex align-items-center">
                <div className={styles.hamburgerBtn}>
                  <Dropdown align="end" className="account-icon">
                    <Dropdown.Toggle
                      className="px-0"
                      style={{ background: "none", border: "none" }}
                    >
                      <BsList
                        size={30}
                        style={{ cursor: "pointer", color: "black" }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                      {isPermission && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              onItemSelected("New Task", project._id);
                            }}
                          >
                            Add New Task
                          </Dropdown.Item>
                          {!isMobile && (
                            <>
                              <Dropdown.Item
                                className={
                                  viewStatus === "list" &&
                                  styles.activeActionBtn
                                }
                                onClick={() => {
                                  setViewStatus("list");
                                }}
                              >
                                List
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={
                                  viewStatus === "month" &&
                                  styles.activeActionBtn
                                }
                                onClick={() => {
                                  setViewStatus("month");
                                }}
                              >
                                Month
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={
                                  viewStatus === "timeline" &&
                                  styles.activeActionBtn
                                }
                                onClick={() => {
                                  setViewStatus("timeline");
                                }}
                              >
                                Timeline
                              </Dropdown.Item>
                            </>
                          )}
                        </>
                      )}
                      <Dropdown.Item
                        onClick={() => {
                          isShowAll ? onHideCompleted() : onShowCompleted();
                        }}
                      >
                        {isShowAll ? "Hide Completed" : "Show Completed"}
                      </Dropdown.Item>
                      {permission !== "Observer" && (
                        <Dropdown.Item
                          onClick={() => {
                            openAgileViewModal();
                            document.body.classList.remove("overflow-auto");
                            document.body.classList.add("overflow-hidden");
                          }}
                        >
                          View Agile Doc
                        </Dropdown.Item>
                      )}
                      {isPermission && (
                        <Dropdown.Item
                          onClick={() => {
                            openModal();
                            document.body.classList.remove("overflow-auto");
                            document.body.classList.add("overflow-hidden");
                          }}
                        >
                          Edit Agile Doc
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={styles.projectButtons}>
                  {isPermission && (
                    <>
                      <Button
                        size="sm"
                        style={{ width: "98px" }}
                        className="mx-3 action-btn"
                        onClick={() => onItemSelected("New Task", projectId)}
                      >
                        + New Task
                      </Button>

                      <Dropdown>
                        <Dropdown.Toggle className="px-0 docsToggle">
                          View
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          onClick={(e) => e.stopPropagation()}
                          className="docsDropdownMenu"
                        >
                          <Dropdown.Item onClick={() => setViewStatus("list")}>
                            List
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setViewStatus("month")}>
                            Month
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setViewStatus("timeline")}
                          >
                            Timeline
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <ButtonGroup size="sm">
                        <Button
                          className={
                            viewStatus === "list" ? "active-btn" : "action-btn"
                          }
                          style={{ width: "58px", height: "30.6px" }}
                          onClick={() => setViewStatus("list")}
                        >
                          List
                        </Button>
                        <Button
                          className={
                            viewStatus === "month" ? "active-btn" : "action-btn"
                          }
                          style={{
                            width: "60px",
                            height: "30.6px",
                            marginLeft: "1px",
                          }}
                          onClick={() => setViewStatus("month")}
                        >
                          Month
                        </Button>
                        <Button
                          className={
                            viewStatus === "timeline"
                              ? "active-btn"
                              : "action-btn"
                          }
                          style={{
                            width: "75px",
                            height: "30.6px",
                            marginLeft: "1px",
                          }}
                          onClick={() => setViewStatus("timeline")}
                        >
                          Timeline
                        </Button>
                      </ButtonGroup> */}
                    </>
                  )}

                  {isShowAll ? (
                    <Button
                      size="sm"
                      style={{ width: "148px", height: "30.6px" }}
                      className="mx-3 hideShowBtn action-btn"
                      onClick={onHideCompleted}
                    >
                      Hide Completed
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      style={{ width: "148px", height: "30.6px" }}
                      className="mx-3 hideShowBtn action-btn"
                      onClick={onShowCompleted}
                    >
                      Show Completed
                    </Button>
                  )}
                </div>
                {permission !== "Observer" &&
                  (!isPermission ? (
                    <Button
                      size="sm"
                      className={`action-btn ${styles.agileViewBtn}`}
                      onClick={openAgileViewModal}
                    >
                      Documentation
                    </Button>
                  ) : (
                    <Dropdown align="end" className={styles.agileLinkActive}>
                      <Dropdown.Toggle className="px-0 docsToggle">
                        Documentation
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        onClick={(e) => e.stopPropagation()}
                        className="docsDropdownMenu"
                      >
                        <Dropdown.Item
                          onClick={() => {
                            openAgileViewModal();
                            document.body.classList.remove("overflow-auto");
                            document.body.classList.add("overflow-hidden");
                          }}
                        >
                          View Agile Doc
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            openModal();
                            document.body.classList.remove("overflow-auto");
                            document.body.classList.add("overflow-hidden");
                          }}
                        >
                          Edit Agile Doc
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ))}
              </div>
            )}
          </div>
        </Card.Header>
        {isProjectChosen ? (
          <Card.Body 
          className="projectCardBody"
          // style={{ padding: "30px 16px 60px 16px" }}
          >
            <ProjectView
              viewStatus={viewStatus}
              tasks={projectTasks}
              projectId={projectId}
              isProjectLoading={isProjectLoading}
              tasksTotal={tasksTotal}
              tasksSelected={tasksSelected}
            />
          </Card.Body>
        ) : (
          <Accordion.Collapse eventKey={index.toString()}>
            <Card.Body
          className="projectCardBody"
            // style={{ padding: "30px 16px 60px 16px" }}
            >
              <ProjectView
                viewStatus={viewStatus}
                tasks={projectTasks}
                projectId={projectId}
                isProjectLoading={isProjectLoading}
                tasksTotal={tasksTotal}
                tasksSelected={tasksSelected}
              />
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
      <Modal
        isShow={isShowAgileView}
        modalHeader="View Agile Document"
        handleClose={() => {
          closeAgileViewModal();
          document.body.classList.remove("overflow-hidden");
          document.body.classList.add("overflow-auto");
        }}
        size="xl"
        headerStyles={{ padding: "32px" }}
        bodyStyles={{ padding: "32px 32px 48px 32px", height: "500px" }}
        // className="agile-doc-modal"
      >
        <AgileView projectId={projectId} handleClose={closeAgileViewModal} />
      </Modal>
      <Modal
        isShow={isShow}
        modalHeader="Edit Agile Document"
        handleClose={() => {
          closeModal();
          document.body.classList.remove("overflow-hidden");
          document.body.classList.add("overflow-auto");
        }}
        headerStyles={{ padding: "32px" }}
        bodyStyles={{ padding: "32px 32px 48px 32px", height: "500px" }}
        size="xl"
        // className="agile-doc-modal"
      >
        <AddEditAgile projectId={projectId} handleClose={closeModal} />
      </Modal>
    </>
  );
};
ProjectSingle.propTypes = {
  project: PropTypes.object,
  index: PropTypes.number,
  workspaceId: PropTypes.string,
  accordionActiveIndex: PropTypes.string,
};
export default ProjectSingle;
