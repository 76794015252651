import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// import { ListGroup, Row, Col, Container } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/daygrid/main.css";
import interactionPlugin/*, { Draggable }*/ from "@fullcalendar/interaction";
import { format, addDays } from "date-fns";
import { setError } from "redux/workspaceSlice";
import { editProject } from "API/projects";
import { HambugurMenuContext } from "services/contexts";
import useGetWorkspaceData from 'hooks/useGetWorkspaceData';

const ProjectMonth = ({ projects, workspaceId }) => {
// console.log(projects, "projects in ProjectMOnth")
  const dispatch = useDispatch();
  const { fetchWorkspaceData } = useGetWorkspaceData();

  const onItemSelected = useContext(HambugurMenuContext);

  const [graphProjects, setGraphProjects] = useState([]);
  const [pendingProjects, setPendingProjects] = useState([]);

  const isEditable = (project) => {
    return (
      project.role === "Admin" ||
      project.role === "Owner"
    );
  };

  useEffect(() => {
    // let draggableEl = document.getElementById("external-projects");
    // new Draggable(draggableEl, {
    //   itemSelector: ".fc-project",
    //   eventData: function (eventEl) {
    //     let title = eventEl.getAttribute("title");
    //     let id = eventEl.getAttribute("data");
    //     return {
    //       title: title,
    //       id: id,
    //     };
    //   },
    // });

    const tempProjects = [];
    const tempPendingProjects = [];
    for (let project of projects) {
      if (!project.date_from || !project.date_to) {
        const projectObj = {
          id: project._id,
          title: project.name,
          backgroundColor: "#aeb8c2",
          borderColor: "#aeb8c2",
          editable: isEditable(project),
        };
        tempPendingProjects.push(projectObj);
      } else {
        const projectObj = {
          id: project._id,
          title: project.name,
          start: project.date_from,
          end: addDays(Date.parse(project.date_to), 1),
          allDay: true,
          backgroundColor: "#aeb8c2",
          borderColor: "#aeb8c2",
          editable: isEditable(project),
        };
        tempProjects.push(projectObj);
      }
    }

    setGraphProjects(tempProjects);
    setPendingProjects(tempPendingProjects);
  }, [projects]);

  //   handling to drop the external project on calendar
  const handleReceive = (info) => {
    //   removing the moved project from pending project list
    const newPendingProjects = pendingProjects.filter(
      (project) => project.id !== info.event.id
    );
    setPendingProjects(newPendingProjects);

    // synchronize the updated project with backend using api and update app setting data
    const endDay = addDays(info.event.start, 1);
    const newInfo = {
      ...info,
      event: {
        ...info.event,
        id: info.event.id,
        title: info.event.title,
        start: info.event.start,
        end: endDay,
      },
    };
    updateProject(newInfo);

    // updating the list of projects on calendar
    const projectObj = {
      id: info.event.id,
      title: info.event.title,
      start: info.event.start,
      end: endDay,
      allDay: true,
      backgroundColor: "#aeb8c2",
      borderColor: "#aeb8c2",
    };
    const newGraphTasks = [...graphProjects, ...[projectObj]];
    setGraphProjects(newGraphTasks);

    // remove the task in blur color
    info.event.remove();
  };

  const updateProject = async (info) => {
    const fromDate = format(info.event.start, "yyyy-MM-dd");
    const toDate = format(addDays(info.event.end, -1), "yyyy-MM-dd");
    const formData = {
      "project_id": info.event.id,
      "name": info.event.title,
      "date_from": fromDate,
      "date_to": toDate,
    }
    try {
      const response = await editProject(formData);
      if (response.data.success === 1) {
        fetchWorkspaceData();
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const handleClick = (info) => {
    const project_id = info.event.id;
    onItemSelected("Edit Project", project_id, workspaceId);
  };

  // const onPendingProjectClicked = (pendingProject) => {
  //   const project_id = pendingProject.id;
  //   onItemSelected("Edit Project", project_id, workspaceId);
  // };

  // const unplanningWidth = pendingProjects.length === 0 ? 2 : 3;

  return (
    <FullCalendar
    initialView="dayGridMonth"
    plugins={[dayGridPlugin, interactionPlugin]}
    headerToolbar={{
      start: "prev",
      center: "title",
      end: "next",
    }}
    events={graphProjects}
    droppable={true}
    editable={true}
    eventResize={(info) => updateProject(info)} // resizing the date range of calendar
    eventDrop={(info) => updateProject(info)} // dropping task inside calendar
    eventClick={handleClick}
    eventReceive={handleReceive} // dropping external task into calendar
    fixedWeekCount={false}
  />
    // <Container
    //   style={{
    //     backgroundColor: "#fff",
    //     padding: 20,
    //   }}
    // >
    //   <Row>
    //      <Col sm={unplanningWidth}>
    //       <div
    //         id="external-projects"
    //         style={{
    //           marginTop: 20,
    //           padding: "10px",
    //           width: "80%",
    //           height: "auto",
    //           maxHeight: "-webkit-fill-available",
    //         }}
    //       >
    //         {pendingProjects.length !== 0 && (
    //           <p>
    //             <strong>Unplanned Projects</strong>
    //           </p>
    //         )}
    //         <ListGroup as="ol" numbered>
    //           {pendingProjects.map((project) => (
    //             <ListGroup.Item
    //               as="li"
    //               className="fc-project"
    //               title={project.title}
    //               data={project.id}
    //               key={project.id}
    //               style={{ cursor: "pointer" }}
    //               onClick={() => onPendingProjectClicked(project)}
    //             >
    //               {project.title}
    //             </ListGroup.Item>
    //           ))}
    //         </ListGroup>
    //       </div>
    //     </Col> 
    //      <Col sm={10}> 
    //       <FullCalendar
    //         initialView="dayGridMonth"
    //         plugins={[dayGridPlugin, interactionPlugin]}
    //         headerToolbar={{
    //           start: "prev",
    //           center: "title",
    //           end: "next",
    //         }}
    //         events={graphProjects}
    //         droppable={true}
    //         editable={true}
    //         eventResize={(info) => updateProject(info)} // resizing the date range of calendar
    //         eventDrop={(info) => updateProject(info)} // dropping task inside calendar
    //         eventClick={handleClick}
    //         eventReceive={handleReceive} // dropping external task into calendar
    //         fixedWeekCount={false}
    //       />
    //      </Col>
    //   </Row>
    // </Container> 
  );
};
ProjectMonth.propTypes = {
  projects: PropTypes.array,
  workspaceId: PropTypes.string
};
export default ProjectMonth;
