import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const Status = ({ loggerName, addedDate, closedDate, permission, isSubTask }) => {
  const workspace = useSelector((state) => state.workspaces.workspace);
  const project = useSelector((state) => state.project.project);

  return (
    <>
      <div className="statusBlockTop mb-3">
        <Form.Group className="workspaceItem">
          <Form.Label className="fs-16">Workspace</Form.Label>
          <Form.Control
            type="text"
            value={workspace?.name}
            placeholder="Workspace"
            disabled
            style={{backgroundColor: "transparent"}}
          />
        </Form.Group>
        {!isSubTask &&<Form.Group className="fieldItem">
          <Form.Label className="fs-16">Logger/Author</Form.Label>
          <Form.Control
            type="text"
            value={loggerName}
            disabled
            style={{backgroundColor: "transparent"}}
            placeholder="Logger/Author"
          />
        </Form.Group>}
        {isSubTask && <Form.Group className="fieldItem">
          <Form.Label className="fs-16">Project</Form.Label>
          <Form.Control
            type="text"
            value={project?.name}
            disabled
            style={{backgroundColor: "transparent"}}
            placeholder="Project"
          />
        </Form.Group>}
      </div>
      {isSubTask && permission !== "Observer" && <div className="d-flex mb-3">
        <Form.Group className="w-100">
          <Form.Label className="fs-16">Logger/Author</Form.Label>
          <Form.Control
            type="text"
            value={loggerName}
            disabled
            style={{backgroundColor: "transparent"}}
            placeholder="Logger/Author"
          />
        </Form.Group>
      </div>}
      <div className="statusBlockTop mb-3">
        {isSubTask && permission === "Observer" &&<Form.Group className="fieldItem me-3">
          <Form.Label className="fs-16">Logger/Author</Form.Label>
          <Form.Control
            type="text"
            value={loggerName}
            disabled
            style={{backgroundColor: "transparent"}}
            placeholder="Logger/Author"
          />
        </Form.Group>}
        <Form.Group className={permission === "Observer" ? "fieldItem" : "dateAdded"}>
          <Form.Label className="fs-16">Date added</Form.Label>
          <Form.Control
            type="text"
            value={addedDate}
            disabled
            style={{backgroundColor: "transparent"}}
            placeholder="Date added"
          />
        </Form.Group>
        {permission !== "Observer" && <Form.Group className="fieldItem">
          <Form.Label className="fs-16">Date closed</Form.Label>
          <Form.Control
            type="text"
            value={closedDate}
            disabled
            style={{backgroundColor: "transparent"}}
            placeholder="Date closed"
          />
        </Form.Group>}
      </div>
    </>
  );
};

Status.propTypes = {
  loggerName: PropTypes.string,
  addedDate: PropTypes.string,
  closedDate: PropTypes.string,
  permission: PropTypes.string,
  isSubTask: PropTypes.bool
};
export default Status;
