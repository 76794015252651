import axios from './axios';

export const getListReportFilter = payload => axios.get('app/list_report_filters', payload);

export const getViewReport = payload => axios.post('app/view_report', payload);

export const addReportFilter = payload => axios.post('app/add_report_filter', payload);

export const editReportFilter = payload => axios.patch('app/edit_report_filter', payload);

export const deleteReportFilter = payload => axios.delete('app/delete_report_filter', { data: payload });