import { format } from "date-fns";

export const formatDate = (date) => {
  return format(new Date(date), "yyyy-MM-dd");
};

export const formatDateTime = (date) => {
  return format(new Date(date), "yyyy-MM-dd HH:mm");
};

export const sortByField = (input, field, desc = false) => {
  const data = [...input];
  if (field.includes("date")) {
    return data.sort((a, b) => {
      if (desc) {
        return new Date(b[field]) - new Date(a[field]);
      } else {
        return new Date(a[field]) - new Date(b[field]);
      }
    });
  }

  return data.sort((a, b) => {
    if (desc) {
      return Number(b[field]) - Number(a[field]);
    } else {
      return Number(a[field]) - Number(b[field]);
    }
  });
};

// export const isJSONRegExp = item => {
//   return /^[\],:{}\s]*$/.test(item.replace(/\\["\\/bfnrtu]/g, '@')
//     .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
//     .replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
// }
export const isJSONRegExp = item => {
  try {
    const obj = JSON.parse(item);
    return typeof obj === "object" && obj !== null;
  } catch (e) {
    return false;
  }
}

export const selectExistingValues = data => {
  const filteredData = {};
  for (const key in data) {
    if (data[key] && key[0] !== "_") {
      filteredData[key] = data[key];
    }
  }
  return filteredData;
}

export function deepEqualObj(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && val1 !== null && typeof val2 === "object" && val2 !== null) {
      if (!deepEqualObj(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

export const sortArrayAlphabetically = (array)=> {
  // console.log(array,'array in alphabetically function')
  const newArray = [...array];

  return newArray.sort((a, b) => {
    const nameA = a?.name?.toLowerCase() || a?.project_name?.toLowerCase();
    const nameB = b?.name?.toLowerCase() || b?.project_name?.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

// format for sending to the server dd-dd-yyyy
export function formatDateForServer(inputDate) {
  const dateObject = new Date(inputDate);
  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const year = dateObject.getFullYear();
  return `${year}-${month}-${day}`;
}