import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import "./mobile.css";
import { useDispatch } from "react-redux";
import { FaBars, FaTimes } from "react-icons/fa";
import { Navbar, Image } from "react-bootstrap";
// import ReportsDropdown from "layouts/Navbar/ReportsDropdown";
import WorkspaceDropdown from "layouts/Navbar/WorkspaceDropdown";
import ProjectsDropdown from "layouts/Navbar/ProjectsDropdown";
import LogoutDropdown from "layouts/Navbar//LogoutDropdown";
import { /*Link,*/ useNavigate } from "react-router-dom";
import { setIsProjectChosen } from "redux/workspaceSlice";
import SearchInput from "components/SearchInput";

const MobileNavbar = ({ setShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isNav, setIsNav] = useState(false);
  const navRef = useRef();

  const handleClick = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setIsNav(false);
    }
  };

  const handleToggle = () => {
    setIsNav(!isNav);
  };
  return (
    <Navbar
      expand="lg"
      className="d-flex justify-content-between align-items-center"
    >
      <div className="nav-container" ref={navRef}>
        {isNav ? null : (
          <div className="d-flex flex-column w-100">
            <div className="d-flex justify-content-between align-items-center p-0">
              <div className="d-flex justify-content-between align-items-center">
                <div onClick={handleToggle}>
                  <FaBars style={{ width: "40px", height: "30px" }} />
                </div>
                <Navbar.Brand style={{ cursor: "pointer" }} className="p-0">
                  <Image src="/imgs/logo.png" style={{ maxHeight: 40 }}></Image>
                </Navbar.Brand>
              </div>
              <LogoutDropdown />
            </div>
            <SearchInput />
          </div>
        )}
        <div className={isNav ? "nav-links active" : "nav-links"}>
          <div className="w-100 d-flex justify-content-between align-items-center mt-3 px-3">
            <Navbar.Brand>
              <Image src="/imgs/logo.png" style={{ maxHeight: 40 }}></Image>
            </Navbar.Brand>
            <span className="nav-close" onClick={handleToggle}>
              <FaTimes />
            </span>
          </div>
          <ul className="d-flex w-100 px-3">
            {/* <li onClick={handleToggle}>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            {/* <li>
              <ReportsDropdown toggleMobileNav={handleToggle}/>
            </li> */}
            <li>
              <WorkspaceDropdown
                toggleMobileNav={handleToggle}
                isMobileNav={isNav}
              />
            </li>
            <li>
              <ProjectsDropdown
                toggleMobileNav={handleToggle}
                isMobileNav={isNav}
              />
            </li>
            <li
              onClick={() => {
                handleToggle();
                setShow(true);
                document.body.classList.remove("overflow-auto");
                document.body.classList.add("overflow-hidden");
              }}
              className="mt-3 ms-3"
            >
              New Workspace
            </li>
            <li
              onClick={() => {
                navigate("/members");
                dispatch(setIsProjectChosen(false));
                handleToggle();
              }}
              className="mt-3 ms-3"
            >
              My Team
            </li>
            {/* {isNav && (
              <li className="nav-close" onClick={handleToggle}>
                <FaTimes />
              </li>
            )} */}
          </ul>
        </div>
      </div>
      {isNav && <div className="nav-overlay" onClick={handleClick}></div>}
    </Navbar>
  );
};

MobileNavbar.propTypes = {
  setShow: PropTypes.func,
};
export default MobileNavbar;
