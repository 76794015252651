import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "redux/workspaceSlice";
import { setViewReports /*, setIsAllReports*/ } from "redux/reportsSlice";
import { getParticipants } from "API/participants";
import { addReportFilter, editReportFilter } from "API/reports";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { formatDate/*, selectExistingValues*/ } from "services/helpers";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import TagMultiSelect from "components/TagMultiSelect";
import { getFields } from "API/fields";
import FieldsListView from "components/FieldListView";

const ReportFilterForm = ({ handleClose, reportFilter = null }) => {
  registerLocale("en-US", enUS);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchWorkspaceData } = useGetWorkspaceData();
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const { projects } = useSelector((state) => state.workspaces);
  const schema = yup.object().shape({
    name: yup.string().required("The Filter name is required"),
    workspaceISbb_t925_workspacesID: yup
      .string()
      .required("The Workspace is required"),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    // getValues
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [workspaceId, setWorkspaceId] = useState(null);
  const [workspaceForTagId, setWorkspaceForTagId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [filterReportTags, setFilterReportTags] = useState([]);
  const inDateFilter = useSelector((state) => state.appsetting.date_filter);
  const inPeriods = useSelector((state) => state.appsetting.periods);
  const [observers, setObservers] = useState([]);
  const [loggers, setLoggers] = useState([]);
  const [actioners, setActioners] = useState([]);
  const [fields, setFields] = useState([]);

  let dateFilterOptions = [];
  if (inDateFilter && Object.keys(inDateFilter).length > 0) {
    Object.keys(inDateFilter).forEach((key) => {
      const filter = {
        _id: key,
        name: inDateFilter[key],
      };
      dateFilterOptions = [...dateFilterOptions, filter];
    });
  }

  let periodOptions = [];
  if (inPeriods && Object.keys(inPeriods).length > 0) {
    Object.keys(inPeriods).forEach((key) => {
      const period = {
        _id: key,
        name: inPeriods[key],
      };
      periodOptions = [...periodOptions, period];
    });
  }

  useEffect(() => {
    console.log(reportFilter, "reportFilter-FORM");
    if (reportFilter) {
      setValue("name", reportFilter?.name ? reportFilter?.name : "");
      setValue(
        "workspaceISbb_t925_workspacesID",
        reportFilter?.workspaceISbb_t925_workspacesID
          ? reportFilter?.workspaceISbb_t925_workspacesID
          : ""
      );

      setWorkspaceForTagId(reportFilter?.workspaceISbb_t925_workspacesID);
      setValue(
        "projectISbb_t925_projectsID",
        reportFilter.projectISbb_t925_projectsID
          ? reportFilter?.projectISbb_t925_projectsID
          : ""
      );
      setProjectId(reportFilter?.projectISbb_t925_projectsID);
      setValue(
        "date_filterISLIST_From_To_FutureDL_PastDL_Updated_Closed",
        reportFilter?.date_filterISLIST_From_To_FutureDL_PastDL_Updated_Closed
      );
      reportFilter?.date_from && setValue("date_from", formatDate(reportFilter?.date_from));
      reportFilter?.date_to && setValue("date_to", formatDate(reportFilter?.date_to));

      setValue(
        "periodISLIST_WTD_MTD_YTD_24h_48h_72h_7d_14d_30d_60d_90d_120d",
        reportFilter?.periodISLIST_WTD_MTD_YTD_24h_48h_72h_7d_14d_30d_60d_90d_120d
      );
      setValue("loggerISbb_accountsID", reportFilter?.loggerISbb_accountsID);
      setValue(
        "task_ownerISbb_accountsID",
        reportFilter?.task_ownerISbb_accountsID
      );
      setValue(
        "actionerISbb_accountsID",
        reportFilter?.actionerISbb_accountsID
      );
      setValue("text_search", reportFilter?.text_search);
      reportFilter?.tag_value && setFilterReportTags(
        reportFilter?.tag_value.map((item) => {
          return { tag_id: item._id, tag_name: item.name };
        })
      );
      reportFilter?.field_value && setFields(reportFilter?.field_value);
      setValue("priorityNUM", reportFilter?.priorityNUM);
      setValue("openYN", reportFilter?.openYN);
      setValue("overdueYN", reportFilter?.overdueYN);
      setValue("unallocatedYN", reportFilter?.unallocatedYN);
      setValue("allocatedYN", reportFilter?.allocatedYN);
      setValue("closedYN", reportFilter?.closedYN);
      setValue("task_actionedYN", reportFilter?.task_actionedYN);
    } else {
      setValue("name", "");
      setValue("workspaceISbb_t925_workspacesID", "");

      // setWorkspaceForTagId(reportFilter?.workspaceISbb_t925_workspacesID);
      setValue("projectISbb_t925_projectsID", "");
      // setProjectId(reportFilter.projectISbb_t925_projectsID);
      setValue("date_filterISLIST_From_To_FutureDL_PastDL_Updated_Closed", "");
      setValue("date_from", null);
      setValue("date_to", null);

      setValue(
        "periodISLIST_WTD_MTD_YTD_24h_48h_72h_7d_14d_30d_60d_90d_120d",
        ""
      );
      setValue("loggerISbb_accountsID", "");
      setValue("task_ownerISbb_accountsID", "");
      setValue("actionerISbb_accountsID", "");
      setValue("text_search", "");
      setValue("priorityNUM", false);
    }
    // }, []);
  }, [reportFilter, reportFilter?.projectISbb_t925_projectsID]);

  useEffect(() => {
    let watchWorkspace = watch("workspaceISbb_t925_workspacesID");
    setWorkspaceId(watchWorkspace);
    setWorkspaceForTagId(watchWorkspace);

    if (watchWorkspace) {
      fetchWorkspaceData(watchWorkspace);
      setProjectId(null);
      getParticipantsData(watchWorkspace, null);
      getFieldsData(watchWorkspace, null);
    }
  }, [watch("workspaceISbb_t925_workspacesID")]);

  useEffect(() => {
    let watchProject = watch("projectISbb_t925_projectsID");
    setProjectId(watchProject);
    setWorkspaceForTagId(null);
    if (watchProject) {
      getParticipantsData(null, watchProject);
      getFieldsData(null, watchProject);
    }
  }, [watch("projectISbb_t925_projectsID")]);

  const getParticipantsData = async (workspaceId, projectId) => {
    try {
      await getParticipants(workspaceId, projectId)
        .then((res) => {
          if (res.data.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error,
              })
            );
          }
          const participantsData = res?.data?.list;
          setObservers(
            participantsData.filter(
              (item) => item.type === "Observer" && item.account_id !== null
            )
          );
          setLoggers(
            participantsData.filter(
              (item) =>
                (item.type === "Project Manager" || item.type === "Admin") &&
                item.account_id !== null
            )
          );
          setActioners(
            participantsData.filter(
              (item) =>
                (item.type === "Project Manager" || item.type === "Actioner") &&
                item.account_id !== null
            )
          );
        })
        .catch((err) => {
          console.log(err, "participants-report-filter-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error,
            })
          );
        });
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const getFieldsData = async (workspaceId, projectId) => {
    try {
      const response = await getFields(workspaceId, projectId);
      // console.log(response, 'response-getFields');
      if (response.data.success === 1) {
        const fieldsList = response.data.list.reverse();
        // console.log(fieldsList,'fieldsList')
        setFields(fieldsList);
      } else if (response?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const onSaveFilter = async (formData) => {
    if (!formData.name) {
      return;
    }

    console.log(formData, "saveReportFilter-Data");
    // formData.append("api_method", "add_report_filter");
    // formData.append("name", name);
    try {
      const response = reportFilter
        ? await editReportFilter({ _id: reportFilter._id, ...formData })
        : await addReportFilter(formData);

      if (response.data.success === 1) {
        console.log(response.data, "response-data-addReportFilter");
        dispatch(setViewReports({ reports: response.data.list }));
        handleClose();
        reset();
        navigate("/filtered-tasks");
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.message || response.data.error,
          })
        );
      } else {
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString(),
        })
      );
    }
  };

  const onSubmit = async (data) => {

    // formData.append("api_method", "view_report");
    let formData = {
      ...data,
      date_from:
        formatDate(data.date_from) === "1970-01-01"
          ? null
          : formatDate(data.date_from),
      date_to:
        formatDate(data.date_to) === "1970-01-01"
          ? null
          : formatDate(data.date_to),
      tag_value:
        filterReportTags.length !== 0
          ? filterReportTags.map((item) => {
              return {
                _id: item?._id || item.tag_id,
                tag_name: item?.tag || item?.tag_name,
              };
            })
          : null,
      field_value: fields.some((item) => "value" in item)
        ? fields.filter((item) => item.value)
        : null,
    };

    onSaveFilter(formData);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-2">
          <Form.Label>Filter Name</Form.Label>
          <Form.Control
            {...register("name")}
            placeholder="Filter Name"
            disabled={false}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-flex justify-content-between flex-wrap">
          <Form.Group className="formElement mb-2">
            <Form.Label>WorkSpace</Form.Label>
            <Form.Select
              {...register("workspaceISbb_t925_workspacesID")}
              id={
                watch("workspaceISbb_t925_workspacesID")
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              isInvalid={!!errors.workspaceISbb_t925_workspacesID}
            >
              <option value="" hidden>
                Select WorkSpace
              </option>
              {workspaces.map((workspace) => (
                <option value={workspace._id} key={workspace._id}>
                  {workspace.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors?.workspaceISbb_t925_workspacesID?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="formElement mb-2">
            <Form.Label>Project</Form.Label>
            <Form.Select
              {...register("projectISbb_t925_projectsID")}
              value={watch("projectISbb_t925_projectsID")}
              id={
                watch("projectISbb_t925_projectsID")
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              // isInvalid={workspaceId && !!errors.projectISbb_t925_projectsID}
              disabled={!workspaceId}
            >
              <option value="" hidden style={{ color: "lightgray" }}>
                Select project
              </option>
              {watch("workspaceISbb_t925_workspacesID") &&
                projects.map((project) => (
                  <option value={project._id} key={project._id}>
                    {project.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <Form.Group className="formElement mb-2">
            <Form.Label>Date Filter</Form.Label>
            <Form.Select
              {...register(
                "date_filterISLIST_From_To_FutureDL_PastDL_Updated_Closed"
              )}
              id={
                watch(
                  "date_filterISLIST_From_To_FutureDL_PastDL_Updated_Closed"
                )
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              disabled={!workspaceId}
            >
              <option value="" hidden>
                Select Date Filter
              </option>
              {dateFilterOptions.map((filter, index) => (
                <option value={filter._id} key={index}>
                  {filter.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="formElement mb-2">
            <Form.Label>Tags</Form.Label>
            <TagMultiSelect
              isFilterReport={true}
              setFilterReportTags={setFilterReportTags}
              workspaceId={workspaceForTagId}
              projectId={projectId}
              savedTags={filterReportTags}
              disabled={!workspaceId}
            />
          </Form.Group>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <Form.Group className="formElement mb-2 d-flex flex-column">
            <Form.Label>Date From</Form.Label>
            <Controller
              name="date_from"
              control={control}
              defaultValue={null}
              placeholderText="Date from"
              render={({ field }) => (
                <>
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    value={field.value ? field.value : null}
                    onChange={(date) => field.onChange(date)}
                    formatWeekDay={(day) => day.slice(0, 3)}
                    locale="en-US"
                    placeholderText="Date from"
                    isInvalid={!!errors.date_from}
                    dateFormat="dd/MM/yyyy"
                    disabled={!workspaceId}
                    customInput={<DatePickerCustomInput />}
                    showYearDropdown
                    {...(field.value ? { startDate: field.value } : {})}
                  />
                </>
              )}
            />
          </Form.Group>

          <Form.Group className="formElement mb-2 d-flex flex-column">
            <Form.Label>Date to</Form.Label>
            <Controller
              name="date_to"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <>
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    value={field.value ? field.value : null}
                    onChange={(date) => field.onChange(date)}
                    formatWeekDay={(day) => day.slice(0, 3)}
                    locale="en-US"
                    placeholderText="Date to"
                    dateFormat="dd/MM/yyyy"
                    disabled={!workspaceId}
                    customInput={<DatePickerCustomInput />}
                    showYearDropdown
                    {...(field.value ? { startDate: field.value } : {})}
                  />
                </>
              )}
            />
          </Form.Group>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <Form.Group className="formElement mb-2">
            <Form.Label>Periods</Form.Label>
            <Form.Select
              {...register(
                "periodISLIST_WTD_MTD_YTD_24h_48h_72h_7d_14d_30d_60d_90d_120d"
              )}
              id={
                watch(
                  "periodISLIST_WTD_MTD_YTD_24h_48h_72h_7d_14d_30d_60d_90d_120d"
                )
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              disabled={!workspaceId}
            >
              <option value="" hidden>
                Select period
              </option>
              {periodOptions.map((p, index) => (
                <option value={p._id} key={index}>
                  {p.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="formElement mb-2">
            <Form.Label>Loggers</Form.Label>
            <Form.Select
              {...register("loggerISbb_accountsID")}
              value={watch("loggerISbb_accountsID")}
              id={
                watch("loggerISbb_accountsID")
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              disabled={!workspaceId}
            >
              <option value="" hidden>
                Select logger
              </option>
              {loggers.map((p, index) => (
                <option value={p.account_id} key={index}>
                  {p.account_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <Form.Group className="formElement mb-2">
            <Form.Label>OWNER/CUSTOMER</Form.Label>
            <Form.Select
              {...register("task_ownerISbb_accountsID")}
              value={watch("task_ownerISbb_accountsID")}
              id={
                watch("task_ownerISbb_accountsID")
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              disabled={!workspaceId}
            >
              <option value="" hidden>
                Select task owner
              </option>
              {observers.map((p, index) => (
                <option value={p.account_id} key={index}>
                  {p.account_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="formElement mb-2">
            <Form.Label>Actioners</Form.Label>
            <Form.Select
              {...register("actionerISbb_accountsID")}
              value={watch("actionerISbb_accountsID")}
              id={
                watch("actionerISbb_accountsID")
                  ? "selectId"
                  : "readOnlyPlaceholderId"
              }
              disabled={!workspaceId}
            >
              <option value="" hidden>
                Select actioner
              </option>
              {actioners.map((p, index) => (
                <option value={p.account_id} key={index}>
                  {p.account_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <Form.Group className="formElement mb-2">
            <Form.Label>Search by text</Form.Label>
            <Form.Control
              {...register("text_search")}
              placeholder="Search"
              disabled={!workspaceId}
            />
          </Form.Group>
          <Form.Group className="formElement prioritySwitch">
            <Form.Label className="fs-6">Priority</Form.Label>
            <label className="switch ms-2">
              <input
                type="checkbox"
                {...register("priorityNUM")}
                disabled={!workspaceId}
              />
              <span className="slider round"></span>
            </label>
          </Form.Group>
        </div>
        <div className="formSwitch w-100 mb-2">
          <Form.Group className="switchGroup">
            <Form.Label className="fs-6">UNALLOCATED</Form.Label>
            <label className="switch ms-2">
              <input type="checkbox" {...register("unallocatedYN")} />
              <span className="slider round"></span>
            </label>
          </Form.Group>
          <Form.Group className="switchGroup">
            <Form.Label className="fs-6">ALLOCATED</Form.Label>
            <label className="switch ms-2">
              <input type="checkbox" {...register("allocatedYN")} />
              <span className="slider round"></span>
            </label>
          </Form.Group>
        </div>

        <div className="formSwitch w-100 mb-2">
          <Form.Group className="switchGroup">
            <Form.Label className="fs-6">OPEN</Form.Label>
            <label className="switch ms-2">
              <input type="checkbox" {...register("openYN")} />
              <span className="slider round"></span>
            </label>
          </Form.Group>
          <Form.Group className="switchGroup">
            <Form.Label className="fs-6">CLOSED</Form.Label>
            <label className="switch ms-2">
              <input type="checkbox" {...register("closedYN")} />
              <span className="slider round"></span>
            </label>
          </Form.Group>
        </div>
        <div className="formSwitch w-100 mb-2">
          <Form.Group className="switchGroup">
            <Form.Label className="fs-6">overdue</Form.Label>
            <label className="switch ms-2">
              <input type="checkbox" {...register("overdueYN")} />
              <span className="slider round"></span>
            </label>
          </Form.Group>
          <Form.Group className="switchGroup">
            <Form.Label className="fs-6">actioned</Form.Label>
            <label className="switch ms-2">
              <input type="checkbox" {...register("task_actionedYN")} />
              <span className="slider round"></span>
            </label>
          </Form.Group>
        </div>
        {!!fields.length && <div className="task-hr my-4"></div>}

        <div className="d-flex justify-content-between flex-wrap mt-2">
          {!!fields.length && <Form.Label>Fields</Form.Label>}
          <FieldsListView
            isFilterReport={true}
            savedFields={fields}
            setReportFields={setFields}
          />
        </div>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="primary" className="mx-2" type="submit">
            Save Report Filter
          </Button>
        </div>
      </Form>
    </>
  );
};

ReportFilterForm.propTypes = {
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
  reportFilter: PropTypes.object,
};

export default ReportFilterForm;
