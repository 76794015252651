import React from 'react';
import PropTypes from "prop-types";
import { Button, Form, Modal } from "react-bootstrap";

const DocumentsLinksModal = ({
  isShow,
  closeModal,
  title,
  onTitleChanged,
  titleError,
  onFileChanged,
  url,
  onUrlChanged,
  linkError,
  linkAlert,
  handleSubmit,
  formSubmitting,
}) => {
  return (
    <Modal show={isShow} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>New Document or Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Document/Link title"
            value={title}
            onChange={onTitleChanged}
          />
          {titleError && (
            <Form.Text className="ms-2 text-danger">
              Document title is required.
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Control type="file" onChange={onFileChanged} />
        </Form.Group>
        <p className="text-center"> OR </p>
        <Form.Group className="my-3">
          <Form.Control
            type="text"
            placeholder="URL"
            value={url}
            onChange={onUrlChanged}
          />
          {linkError && (
            <Form.Text className="ms-2 text-danger">
              File or URL is required.
            </Form.Text>
          )}
          {linkAlert && (
            <Form.Text className="ms-2 text-secondary">
              File and URL are set together. Your file will be ignored.
            </Form.Text>
          )}
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={handleSubmit}
            size="sm"
            disabled={formSubmitting ? true : false}
          >
            {formSubmitting ? "Loading..." : "Done"}
            {formSubmitting && (
              <div
                className="spinner-border spinner-border-sm ms-2 mt-1"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DocumentsLinksModal.propTypes = {
  isShow: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  onTitleChanged: PropTypes.func,
  titleError: PropTypes.bool,
  onFileChanged: PropTypes.func,
  url: PropTypes.string,
  onUrlChanged: PropTypes.func,
  linkError: PropTypes.bool,
  linkAlert: PropTypes.bool,
  handleSubmit: PropTypes.func,
  formSubmitting: PropTypes.bool
};
export default DocumentsLinksModal;
